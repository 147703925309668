import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaShare, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
} from '@mui/material';
import { useAuth } from '../../../auth/userProvider/AuthProvider';

const EventCard = ({ eventData }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleBuyClick = (eventId) => {
    if (currentUser) {
      navigate(`/user-dashboard/eventDetails/${eventId}`);
    } else {
      navigate(`/events/eventDetails/${eventId}`);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.seconds) {
      const date = new Date(timestamp.seconds * 1000);
      return date.toLocaleString();
    } else {
      return 'Invalid Date';
    }
  };

  return (
    <div className="bg-gray-800 shadow-lg text-white rounded-lg overflow-hidden transform transition-transform hover:scale-105">
      <div className="relative h-48">
        <img
          src={eventData.eventImage || 'NA'}
          alt="Event"
          className="w-full h-full object-cover"
        />
        <div className="absolute top-0 right-0 m-2">
          <IconButton
            onClick={handleDialogOpen}
            className="text-white bg-gray-800 bg-opacity-75 hover:bg-opacity-100 rounded-full p-2"
          >
            <FaShare />
          </IconButton>
        </div>
      </div>
      <div className="p-4">
        <h2 className="text-lg font-semibold text-white truncate">{eventData.eventTitle}</h2>
        <p className="text-sm text-white mt-1">{eventData.eventLocation}</p>
        <p className="text-sm text-white mt-1">
          📅 {eventData.startDate} at {eventData.startTime} - {eventData.endDate} at {eventData.endTime}
        </p>
        <p className="text-sm text-gray-500 mt-2">{eventData.eventCategory}</p>

        <div className="mt-4 flex justify-between items-center">
          <button
            onClick={() => handleBuyClick(eventData.eventId)}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
          >
            Buy Ticket
          </button>
        </div>
      </div>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Share Event</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} className="flex justify-center">
              <IconButton style={{ color: 'rgb(79, 153, 213)' }}>
                <FaTwitter />
              </IconButton>
              <IconButton style={{ color: 'rgb(34, 173, 34)' }}>
                <FaWhatsapp />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EventCard;
