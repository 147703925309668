import React, { useEffect, useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import NewNav from "../sub-components/NewNav";
import { useNavigate } from 'react-router-dom';
import insight from '../../../assets/img/insight.png';
import enhance from '../../../assets/img/enhance.png';
import LottieAnimation from "../sub-components/LottieAnimation";
import animationData from "../../../assets/lotties/study.json";
import amp from '../../../assets/img/amp.png';
// import accomadation from '../assets/img/accomadation.png';
import Footer from '../sub-components/Footer';
import AboutUs from '../sub-components/About';
import accom from '../../../assets/img/accom.jpeg';


const Home = () => {

    const navigate = useNavigate();
    const typewriterRef = useRef(null);
    const handleJoinClick = () => {
        navigate('/join-waiting-list');
    };

    const handleBrandFormClick = () => {
        navigate('/write-to-us');
    };

    useEffect(() => {
        const phrases = ["students", "brands", "entrepreneurs"];
        let index = 0;
        let typeInterval, deleteInterval;

        const typePhrase = () => {
            const phrase = phrases[index];
            const length = phrase.length;
            let i = 0;

            typeInterval = setInterval(() => {
                if (typewriterRef.current) {
                    if (i <= length) {
                        typewriterRef.current.textContent = phrase.slice(0, i);
                        i++;
                    } else {
                        clearInterval(typeInterval);
                        setTimeout(deletePhrase, 1000);
                    }
                }
            }, 100);
        };

        const deletePhrase = () => {
            let length = typewriterRef.current ? typewriterRef.current.textContent.length : 0;

            deleteInterval = setInterval(() => {
                if (typewriterRef.current) {
                    if (length >= 0) {
                        typewriterRef.current.textContent = typewriterRef.current.textContent.slice(0, length);
                        length--;
                    } else {
                        clearInterval(deleteInterval);
                        index = (index + 1) % phrases.length;
                        setTimeout(typePhrase, 1000);
                    }
                }
            }, 100);
        };

        typePhrase();

        return () => {
            clearInterval(typeInterval);
            clearInterval(deleteInterval);
        };
    }, []);
    return (
        <div className='bg-gray-900 text-white min-h-screen'>
            <NewNav />
            <section className='mt-32 h-screen bg-gray-900'>
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-4">
                    <div className="md:w-1/2">
                        <h3 className="text-3xl md:text-4xl font-bold">
                            The ultimate ecosystem for{' '}
                            <span className="custom-text-color" ref={typewriterRef}></span> <br />in the UK
                        </h3>
                        <p className="mt-4 text-base md:text-lg">
                            WorldLynk is your one-stop solution for a seamless experience in the UK.
                        </p>
                        <div className="flex flex-wrap mt-6">
                            <button onClick={handleJoinClick} className="custom-bg-color hover:bg-orange-600 text-black py-2 px-4 rounded mr-4 mb-2 md:mb-0">
                                Join Now
                            </button>
                            <button onClick={handleJoinClick} className="custom-text-color border border-orange-500 hover:bg-white hover:text-black py-2 px-4 rounded">
                                Sign in
                            </button>
                        </div>
                    </div>

                    <div className="md:w-1/2 mt-6 md:mt-0 flex justify-center">
                        <LottieAnimation
                            animationData={animationData}
                            className="max-w-full h-auto"
                            style={{ width: '80%', height: 'auto' }}
                        />
                    </div>
                </div>
            </section>
            <TextParallaxContent
                imgUrl="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                subheading="Mentorship"
                heading="Helping Students Find Their Way"
            >
                <ExampleContent textContent={"Connect with experienced mentors who can guide you through your academic and career challenges. Our mentorship program offers personalized advice and support to help you succeed in your educational journey and beyond."}
                    heading={"Guidance You Can Trust, Support You Can Count On."} />
            </TextParallaxContent>
            <TextParallaxContent
                imgUrl="https://images.unsplash.com/photo-1530893609608-32a9af3aa95c?q=80&w=2564&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                subheading="Job Opportunities"
                heading={"Connecting You with the Right Opportunities."}
            >
                <ExampleContent
                    heading={'Helping You Take the Next Career Step.'}
                    textContent={"Your gateway to global job opportunities. Break geographical boundaries and access international opportunities. Empower your career with WorldLynk."}
                />
            </TextParallaxContent>
            <TextParallaxContent
                imgUrl={accom}
                subheading="Accommodation"
                heading={"Reliable Housing for International Students."}
            >
                <ExampleContent
                    heading={"Connecting You with the Right Home."}
                    textContent={"Find your ideal stay seamlessly. Explore diverse accommodation options tailored to your preferences. Break barriers, discover international stays, and elevate your living arrangements with WorldLynk."} />
            </TextParallaxContent>
        </div>
    );
};
export default Home

const IMG_PADDING = 0;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
    return (
        <div
            style={{
                paddingLeft: IMG_PADDING,
                paddingRight: IMG_PADDING,
            }}
        >
            <div className="relative h-[150vh]">
                <StickyImage imgUrl={imgUrl} />
                <OverlayCopy heading={heading} subheading={subheading} />
            </div>
            {children}
        </div>
    );
};

const StickyImage = ({ imgUrl }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["end end", "end start"],
    });

    const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
    const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

    return (
        <motion.div
            style={{
                backgroundImage: `url(${imgUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: `calc(100vh - ${IMG_PADDING * 2}px)`,
                top: IMG_PADDING,
                scale,
            }}
            ref={targetRef}
            className="sticky z-0 overflow-hidden "
        >
            <motion.div
                className="absolute inset-0 bg-neutral-950/70"
                style={{
                    opacity,
                }}
            />
        </motion.div>
    );
};

const OverlayCopy = ({ subheading, heading }) => {
    const targetRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
        offset: ["start end", "end start"],
    });

    const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
    const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

    return (
        <motion.div
            style={{
                y,
                opacity,
            }}
            ref={targetRef}
            className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
        >
            <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl text-white">
                {subheading}
            </p>
            <p className="text-center text-3xl font-bold md:text-6xl text-white">{heading}</p>
        </motion.div>
    );
};

const ExampleContent = ({ heading, textContent }) => (
    <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
        {/* <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
            Additional content explaining the above card here
        </h2> */}
        {heading && <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
            {heading}
        </h2>}
        <div className="col-span-1 md:col-span-8">
            {/* <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
             
            </p> */}
            {textContent && <p className="mb-4 text-xl text-white md:text-2xl">
                {textContent}
            </p>}
            {/* <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
                reiciendis blanditiis aliquam aut fugit sint.
            </p> */}
            <button className="w-full rounded bg-neutral-900 px-9 py-4 text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit">
                Learn more <FiArrowUpRight className="inline" />
            </button>
        </div>
    </div>
);