import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';
import finalLogo from '../../../../assets/img/log.png';
import { useAuth } from '../../../../auth/userProvider/AuthProvider';

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, userData } = useAuth();

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const handleClick = () => {
    setClick(!click);
    setDropdownOpen(false);  // Close user dropdown
  };

  const closeMobileMenu = () => {
    setClick(false);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  const handleTabClick = (path) => {
    setActiveTab(path);
    navigate(path);
    closeMobileMenu();
  };

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout function from your AuthContext
      navigate('/');
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  const renderOrganizationTypeLink = () => {
    if (userData?.accountType === 'business') {
      switch (userData.organizationType) {
        case 'Events':
          return (
            <li className="nav-item">
              <div
                onClick={() => handleTabClick('/business/events')}
                className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events' ? 'border-b-2 border-blue-500' : ''}`}
              >
                Events
              </div>
            </li>
          );
        case 'Restaurants':
          return (
            <li className="nav-item">
              <div
                onClick={() => handleTabClick('/business/restaurants')}
                className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/restaurants' ? 'border-b-2 border-blue-500' : ''}`}
              >
                Restaurants
              </div>
            </li>
          );
        case 'Accommodation':
          return (
            <li className="nav-item">
              <div
                onClick={() => handleTabClick('/business/accommodation')}
                className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/accommodation' ? 'border-b-2 border-blue-500' : ''}`}
              >
                Accommodation
              </div>
            </li>
          );
        case 'Jobs':
          return (
            <li className="nav-item">
              <div
                onClick={() => handleTabClick('/business/jobs')}
                className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/jobs' ? 'border-b-2 border-blue-500' : ''}`}
              >
                Jobs
              </div>
            </li>
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <nav className="bg-gray-950 shadow-sm relative z-50">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center cursor-pointer" onClick={() => handleTabClick('/business')}>
          <img src={finalLogo} alt="Logo" className="h-8 md:h-10" />
        </div>

        {/* Navigation Links */}
        <ul className={`hidden lg:flex lg:items-center lg:space-x-6`}>
          {/* <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/overview')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/overview' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Dashboard
            </div>
          </li> */}
          {renderOrganizationTypeLink()}
          <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/company-profile')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/editpagesetting' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Pages
            </div>
          </li>
          <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/orders')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/orders' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Orders
            </div>
          </li>
          <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/requests')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/requests' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Refunds
            </div>
          </li>
          <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/verify')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/verify' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Scan
            </div>
          </li>
        </ul>

        {/* Right-side Icons and Dropdown */}
        <div className="flex items-center space-x-4">
          {/* User Dropdown */}
          <div className="relative">
            <div
              onClick={toggleDropdown}
              className="text-gray-300 cursor-pointer flex items-center border border-gray-700 px-3 py-1 rounded-lg bg-gray-800 hover:bg-gray-700"
            >
              {userData?.firstName || 'User'} <FaChevronDown className="ml-1" />
            </div>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 text-gray-300 border border-gray-700 rounded-lg shadow-lg z-10">
                <div className="py-2 px-4 hover:bg-gray-700 cursor-pointer" onClick={() => { navigate('/profile'); closeDropdown(); }}>
                  Profile
                </div>
                <div className="py-2 px-4 hover:bg-gray-700 cursor-pointer" onClick={handleLogout}>
                  Sign Out
                </div>
              </div>
            )}
          </div>

          {/* Mobile Menu Icon */}
          <div className="lg:hidden" onClick={handleClick}>
            {click ? <FaTimes size={28} className="text-white" /> : <FaBars size={28} className="text-white" />}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {click && (
        <ul className="lg:hidden bg-gray-900 p-4 absolute top-full left-0 w-full z-50">
          {/* <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/overview')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/overview' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Dashboard
            </div>
          </li> */}
          {renderOrganizationTypeLink()}
          <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/orders')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/orders' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Orders
            </div>
          </li>
          <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/requests')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/requests' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Refunds
            </div>
          </li>
          <li className="nav-item">
            <div
              onClick={() => handleTabClick('/business/events/verify')}
              className={`text-white font-medium cursor-pointer py-2 ${activeTab === '/business/events/verify' ? 'border-b-2 border-blue-500' : ''}`}
            >
              Scan
            </div>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
