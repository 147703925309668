import React, { useState, useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "../../../assets/lotties/loader1.json";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../../auth/userProvider/AuthProvider";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Navbar from "./layout-components/Navbar";
import Footer from "../../Home/sub-components/Footer";

const AdminDashboard = () => {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (auth.currentUser) {
        try {
          const db = getFirestore();
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            setUserData(userDoc.data());
          } else {
            console.log("User document does not exist");
          }
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulated delay
      setLoading(false);
    };

    fetchData();
  }, [auth]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-800">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
          }}
          height={200}
          width={200}
        />
      </div>
    );
  }

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <Navbar />
      <div className="">
        {userData ? <Outlet context={{ user: userData }} /> : <div>Loading...</div>}
      </div>
      <Footer />
    </div>
  );
};

export default AdminDashboard;
