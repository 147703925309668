import React, { useEffect } from 'react';

import './BusinessHome.css'
import BusinessNav from '../sub-components/BusinessNav';

const BusinessHome = () => {


  return (
 <div className='business-home'>
<BusinessNav/>
<section class="hero-section bg-gradient-to-b from-gray-900 to-gray-600 ">
  <div class="container">
    <h1 class="hero-section_title">
      Why should you try <q><span class="quoted-text">WorldLynk</span></q> to level up your Business?
    </h1>
    <div class="hero-section_cards">
      <div class="hero-section_card card-1">
        <div class="hero-section_card_content">
          <h2 class="hero-section_card_title">Event Management Expertise</h2>
          <p class="hero-section_card_description">
            Leverage our platform to seamlessly manage your events, attract attendees, and maximize engagement.
          </p>
        </div>
      </div>
      <div class="hero-section_card card-2">
        <div class="hero-section_card_content">
          <h2 class="hero-section_card_title">Streamlined Accommodation Booking</h2>
          <p class="hero-section_card_description">
            Simplify your accommodation management with personalized booking solutions and enhanced visibility.
          </p>
        </div>
      </div>
      <div class="hero-section_card card-3">
        <div class="hero-section_card_content">
          <h2 class="hero-section_card_title">Efficient Job/Internship Recruitment</h2>
          <p class="hero-section_card_description">
            Connect with top talent through our platform, offering streamlined job postings and applicant tracking.
          </p>
        </div>
      </div>
      <div class="hero-section_card card-4">
        <div class="hero-section_card_content">
          <h2 class="hero-section_card_title">Restaurant Promotion & Management</h2>
          <p class="hero-section_card_description">
            Enhance your restaurant's reach with targeted promotions, easy reservations, and customer engagement tools.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

</div>
  );
};

export default BusinessHome;
