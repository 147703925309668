import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../firebase";
import Slider1 from './Slider';
import CardSlider from './CardSlider';
import eventbgimg from '../../../assets/img/events_cover.jpg';

const categories = [
  { name: "Academic Events", imageUrl: '/img/academic-events.jpg' },
  { name: "Cultural Events", imageUrl: '/img/cultural-events.jpg' },
  { name: "Social Events", imageUrl: '/img/social-events.jpg' },
  { name: "Sports and Recreation", imageUrl: '/img/sports-events.jpg' },
  { name: "Professional Development", imageUrl: '/img/professional-development.jpg' },
  { name: "Health and Wellness", imageUrl: '/img/health-and-wellness.jpg' },
  { name: "Orientation and Information Sessions", imageUrl: '/img/orientation.png' },
  { name: "Arts and Entertainment", imageUrl: '/img/art.jpg' },
  { name: "Technology and Innovation", imageUrl: '/img/tech.jpg' }
];

const EventsPage = () => {
  const navigate = useNavigate();
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchEventData = async () => {
    try {
      const eventsCollectionRef = collection(firestore, "events");
      const eventsQuerySnapshot = await getDocs(eventsCollectionRef);
      if (!eventsQuerySnapshot.empty) {
        const data = eventsQuerySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEventData(data);
      } else {
        console.log("No events found");
      }
    } catch (error) {
      console.error("Error fetching events data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eventData.length === 0) {
      fetchEventData();
    }
  }, [eventData]);

  const handleCategoryClick = (categoryName) => {
    navigate(`all-events/${categoryName}`);
  };

  const handleBannerClick = () => {
    navigate('all-events');
  };

  return (
    <div className="bg-gray-900 text-white">
      {/* Banner Section */}
      <div 
        className="relative bg-cover bg-center text-white py-20" 
        style={{ backgroundImage: `url(${eventbgimg})` }}
      >
        <div className="container mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Discover Your Next Adventure</h1>
          <button 
            onClick={handleBannerClick} 
            className="bg-black text-white px-8 py-4 rounded hover:bg-gray-800 transition"
          >
            Find an Event
          </button>
        </div>
      </div>

      {/* Popular Categories Section */}
      <div className="container mx-auto py-10">
        <h3 className="text-3xl font-semibold mb-6 text-center">Popular Categories</h3>
        <CardSlider categories={categories} onCategoryClick={handleCategoryClick} />
      </div>

      {/* Upcoming Events Section */}
      <div className="container mx-auto py-10">
        <h3 className="text-3xl font-semibold mb-6 text-center">Upcoming Events</h3>
        {loading ? (
          <div className="flex justify-center">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
          </div>
        ) : (
          <Slider1 events={eventData} />
        )}
      </div>
    </div>
  );
};

export default EventsPage;
