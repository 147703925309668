import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const MapWithGeocoding = () => {
  const [locationData, setLocationData] = useState({
    name: 'Chaiiology Soho Rd',
    address: '123 Main St, London',
    latitude: 0,
    longitude: 0,
  });

  const getCoordinates = async (address) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

    try {
      const response = await fetch(geocodeUrl);
      const data = await response.json();
      console.log(data,"data")

      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        setLocationData((prevData) => ({
          ...prevData,
          latitude: lat,
          longitude: lng,
        }));
      } else {
        console.error('Geocode error:', data.status);
      }
    } catch (error) {
      console.error('Error fetching geocode data:', error);
    }
  };

  useEffect(() => {
    getCoordinates(`${locationData.name}`);
  }, [locationData.address]);

  return (
    <div>
      <h3>{locationData.name}</h3>
      <p>Address: {locationData.address}</p>
      <p>Latitude: {locationData.latitude}</p>
      <p>Longitude: {locationData.longitude}</p>

      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={{ lat: locationData.latitude, lng: locationData.longitude }}
          zoom={15}
        >
          {/* Add markers or other map features here */}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapWithGeocoding;
