import React, { useEffect, useState } from 'react';
import { firestore } from '../../../firebase';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { ClipLoader } from 'react-spinners';

const approval_endpoint = process.env.REACT_APP_REFUND_APPROVAL_ENPOINT;

const RefundRequests = () => {
  const { currentUser } = useAuth();
  const [refundRequests, setRefundRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [activeTab, setActiveTab] = useState('pending'); // Default to 'pending' tab

  useEffect(() => {
    const fetchRefundRequests = async () => {
      try {
        if (!currentUser) {
          throw new Error('User not authenticated');
        }

        const checkoutsQuery = query(
          collection(firestore, 'payments'),
          where('organizerId', '==', currentUser.uid)
        );
        const checkoutsSnapshot = await getDocs(checkoutsQuery);
        const checkoutIds = checkoutsSnapshot.docs.map(doc => doc.id);

        if (checkoutIds.length === 0) {
          setRefundRequests([]);
          setLoading(false);
          return;
        }

        const refundRequestsQuery = query(
          collection(firestore, 'refundRequests'),
          where('orderId', 'in', checkoutIds)
        );
        const refundRequestsSnapshot = await getDocs(refundRequestsQuery);

        const refundRequestsData = await Promise.all(
          refundRequestsSnapshot.docs.map(async (refundDoc) => {
            try {
              const requestData = refundDoc.data();
              const userRef = doc(firestore, 'users', requestData.userId);
              const userSnapshot = await getDoc(userRef);
              const userData = userSnapshot.data();
              return {
                ...requestData,
                userFirstName: userData?.firstName || userData?.name || 'Unknown',
                userEmail: userData?.email || 'Unknown'
              };
            } catch (userError) {
              console.error('Error fetching user data:', userError.message);
              return null;
            }
          })
        );

        setRefundRequests(refundRequestsData.filter(data => data !== null));
        setLoading(false);
      } catch (err) {
        console.error('Error fetching refund requests:', err.message);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchRefundRequests();
  }, [currentUser]);

  const handleApprove = async () => {
    try {
      // Find the selected request
      const request = refundRequests.find(req => req.requestId === selectedRequest);

      if (!request) {
        toast.error('Selected request not found.');
        return;
      }

      const response = await fetch(approval_endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ requestId: request.requestId }), // Send requestId from the document
      });

      if (response.ok) {
        toast.success('Refund approved successfully!');
        setRefundRequests(refundRequests.filter(req => req.requestId !== selectedRequest));
        setShowModal(false);
        setSelectedRequest(null); // Reset the selected request
      } else {
        toast.error('Failed to approve refund.');
      }
    } catch (error) {
      toast.error('An error occurred.');
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setSelectedRequest(null); // Reset the selected request when canceled
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => toast.success('ID copied to clipboard'))
      .catch(err => toast.error('Could not copy text'));
  };

  // Filter requests based on the active tab
  const filteredRequests = refundRequests.filter(request => request.status === activeTab);

  return (
<div className="container mx-auto px-4 py-4 text-zinc-200">
  <h2 className="text-2xl font-semibold mb-4">Refund Requests</h2>

  <div className="flex mb-4">
    <button
      className={`px-4 py-2 mr-2 ${
        activeTab === 'pending' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'
      } rounded transition-colors duration-300`}
      onClick={() => setActiveTab('pending')}
    >
      Pending
    </button>
    <button
      className={`px-4 py-2 ${
        activeTab === 'approved' ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300'
      } rounded transition-colors duration-300`}
      onClick={() => setActiveTab('approved')}
    >
      Approved
    </button>
  </div>

  <div className="overflow-x-auto">
    <div className="bg-gray-800 rounded-lg shadow-lg">
      <table className="min-w-full">
        <thead className="bg-gray-900">
          <tr>
            <th className="py-3 px-4 text-left text-gray-400 font-medium">Request ID</th>
            <th className="py-3 px-4 text-left text-gray-400 font-medium">Checkout ID</th>
            <th className="py-3 px-4 text-left text-gray-400 font-medium">User Name</th>
            <th className="py-3 px-4 text-left text-gray-400 font-medium">User Email</th>
            <th className="py-3 px-4 text-left text-gray-400 font-medium">Reason</th>
            <th className="py-3 px-4 text-left text-gray-400 font-medium">Requested At</th>
            <th className="py-3 px-4 text-left text-gray-400 font-medium">Status</th>
            {activeTab === 'pending' && (
              <th className="py-3 px-4 text-left text-gray-400 font-medium">Approve</th>
            )}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="8" className="py-3 px-4 text-center">
                <div className="flex justify-center items-center h-64">
                  <ClipLoader color="#fff" loading={loading} size={50} />
                </div>
              </td>
            </tr>
          ) : filteredRequests.length === 0 ? (
            <tr>
              <td colSpan="8" className="py-3 px-4 text-center">
                <p>No data available</p>
              </td>
            </tr>
          ) : (
            filteredRequests.map((request, index) => (
              <tr key={index} className="bg-gray-700 hover:bg-gray-600 transition-colors duration-300">
                <td className="py-3 px-4 text-white">
                  <div className="flex items-center">
                    <span>{request.requestId}</span>
                    <button
                      onClick={() => copyToClipboard(request.requestId)}
                      className="ml-2 text-blue-500 hover:underline"
                    >
                      Copy
                    </button>
                  </div>
                </td>
                <td className="py-3 px-4 text-white">
                  <div className="flex items-center">
                    <span>{request.orderId.substring(0, 10)}...</span>
                    <button
                      onClick={() => copyToClipboard(request.orderId)}
                      className="ml-2 text-blue-500 hover:underline"
                    >
                      Copy
                    </button>
                  </div>
                </td>
                <td className="py-3 px-4 text-white">{request.userFirstName}</td>
                <td className="py-3 px-4 text-white">{request.userEmail}</td>
                <td className="py-3 px-4 text-white">{request.reason}</td>
                <td className="py-3 px-4 text-white">
                  {new Date(request.requestedAt.seconds * 1000).toLocaleString()}
                </td>
                <td className="py-3 px-4 text-white">{request.status}</td>
                {activeTab === 'pending' && (
                  <td className="py-3 px-4 text-white">
                    <input
                      type="checkbox"
                      checked={selectedRequest === request.requestId}
                      onChange={() => {
                        setSelectedRequest(request.requestId);
                        setShowModal(true);
                      }}
                      className="form-checkbox text-blue-600"
                    />
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  </div>

  {showModal && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-900 text-white rounded-lg p-6 w-96 shadow-lg">
        <h2 className="text-lg font-semibold mb-4">Confirm Approval</h2>
        <p>Are you sure you want to approve this refund request?</p>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-700 text-gray-300 rounded hover:bg-gray-600 transition-colors duration-300 mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleApprove}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-300"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  )}
</div>

  );
};

export default RefundRequests;
