import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, getDoc, query, where } from "firebase/firestore";
import { firestore } from '../../../firebase'; // Adjust the path as necessary
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { ClipLoader } from 'react-spinners';

const CheckoutDetails = () => {
    const { currentUser } = useAuth();
    const [checkouts, setCheckouts] = useState([]);
    const [selectedCheckout, setSelectedCheckout] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [noData, setNoData] = useState(false);
    
    useEffect(() => {
        if (currentUser) {
            fetchCheckouts();
        }
    }, [currentUser]);

    const fetchCheckouts = async () => {
        setLoading(true);
        setNoData(false);
        try {
            const eventsQuery = query(
                collection(firestore, 'events'),
                where('organizerId', '==', currentUser.uid)
            );
            const eventsQuerySnapshot = await getDocs(eventsQuery);
            const eventsData = eventsQuerySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    
            const eventIds = eventsData.map(event => event.id);
    
            const querySnapshot = await getDocs(collection(firestore, 'checkouts'));
            const checkoutsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    
            const filteredCheckouts = checkoutsData.filter(checkout => {
                const userEventId = checkout.user?.eventId;
                return eventIds.includes(userEventId);
            });
    
            setCheckouts(filteredCheckouts);
            if (filteredCheckouts.length === 0) {
                setNoData(true);
            }
        } catch (error) {
            console.error('Error fetching checkouts: ', error);
        } finally {
            setLoading(false);
        }
    };
    

    const openModal = async (checkout) => {
        try {
            const eventDoc = await getDoc(doc(firestore, 'events', checkout.user.eventId));
            const eventData = eventDoc.exists() ? eventDoc.data() : {};

            setSelectedCheckout({
                ...checkout,
                eventDetails: eventData
            });
            setModalIsOpen(true);
        } catch (error) {
            console.error('Error fetching event details: ', error);
        }
    };

    const closeModal = () => {
        setSelectedCheckout(null);
        setModalIsOpen(false);
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        let date;
        if (timestamp instanceof Date) {
            date = timestamp;
        } else if (timestamp.seconds && timestamp.nanoseconds) {
            date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
        } else if (timestamp.toDate) {
            date = timestamp.toDate();
        } else {
            return '';
        }
        return date.toLocaleString();
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('ID copied to clipboard');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    return (
        <div className="container mx-auto px-4 py-4 text-zinc-200">
        <h1 className="text-2xl font-bold mb-4">Orders</h1>
      
        <div className="overflow-x-auto">
          <div className="bg-gray-800 rounded-lg shadow-lg">
            <table className="min-w-full">
              <thead className="bg-gray-900">
                <tr>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Checkout ID</th>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Event ID</th>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Customer</th>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Email</th>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Total Amount</th>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Status</th>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Booking Time</th>
                  <th className="py-3 px-4 text-left text-gray-400 font-medium">Details</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="8" className="py-3 px-4 text-center">
                      <div className="flex justify-center items-center h-64">
                        <ClipLoader color="#fff" loading={loading} size={50} />
                      </div>
                    </td>
                  </tr>
                ) : noData ? (
                  <tr>
                    <td colSpan="8" className="py-3 px-4 text-center">
                      <p>No data found</p>
                    </td>
                  </tr>
                ) : (
                  checkouts.map((checkout) => (
                    <tr key={checkout.id} className="bg-gray-700 hover:bg-gray-600 transition-colors duration-300">
                      <td className="py-3 px-4 text-white">
                        <div className="flex items-center">
                          <span>{checkout.id.substring(0, 10)}...</span>
                          <button
                            onClick={() => copyToClipboard(checkout.id)}
                            className="ml-2 text-blue-500 hover:underline"
                          >
                            Copy
                          </button>
                        </div>
                      </td>
                      <td className="py-3 px-4 text-white">
                        <div className="flex items-center">
                          <span>{checkout.user.eventId.substring(0, 8)}...</span>
                          <button
                            onClick={() => copyToClipboard(checkout.user.eventId)}
                            className="ml-2 text-blue-500 hover:underline"
                          >
                            Copy
                          </button>
                        </div>
                      </td>
                      <td className="py-3 px-4 text-white">{checkout.customer_name}</td>
                      <td className="py-3 px-4 text-white">{checkout.customer_email}</td>
                      <td className="py-3 px-4 text-white">{checkout.amount_total} {checkout.currency}</td>
                      <td className="py-3 px-4 text-white">{checkout.status}</td>
                      <td className="py-3 px-4 text-white">{formatDate(checkout.created)}</td>
                      <td className="py-3 px-4">
                        <button
                          onClick={() => openModal(checkout)}
                          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors duration-300"
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      
        {modalIsOpen && selectedCheckout && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-gray-800 rounded-lg p-4 w-full max-w-2xl shadow-lg">
              <h2 className="text-xl font-bold mb-4 text-white">
                Booking Details for {selectedCheckout.customer_name}
              </h2>
              {selectedCheckout.eventDetails && (
                <div className="mb-4">
                  <p className="text-white"><strong>Event Title:</strong> {selectedCheckout.eventDetails.eventTitle}</p>
                  <p className="text-white"><strong>Event ID:</strong> {selectedCheckout.eventDetails.eventId}</p>
                  <p className="text-white"><strong>Category:</strong> {selectedCheckout.eventDetails.category}</p>
                  <p className="text-white"><strong>Start Date:</strong> {selectedCheckout.eventDetails.startDate}</p>
                </div>
              )}
              <div className="bg-gray-700 rounded-lg shadow-lg">
                <table className="min-w-full">
                  <thead>
                    <tr>
                      <th className="py-3 px-4 text-left text-gray-400 font-medium">Ticket Title</th>
                      <th className="py-3 px-4 text-left text-gray-400 font-medium">Price</th>
                      <th className="py-3 px-4 text-left text-gray-400 font-medium">Booking Fee</th>
                      <th className="py-3 px-4 text-left text-gray-400 font-medium">Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedCheckout.tickets.map((ticket, index) => (
                      <tr key={index} className="bg-gray-600 hover:bg-gray-500 transition-colors duration-300">
                        <td className="py-3 px-4 text-white">{ticket.title}</td>
                        <td className="py-3 px-4 text-white">{ticket.price}</td>
                        <td className="py-3 px-4 text-white">{ticket.bookingFee}</td>
                        <td className="py-3 px-4 text-white">{ticket.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={closeModal}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md transition-colors duration-300"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      
    );
};

export default CheckoutDetails;
