import Icon from "../small-comp/Icon"
function AddComponent() {
    return (
      <div>
        <div className="w-full h-20 add-component-head" />
        <div
          className="flex flex-col items-center"
          style={{
            transform: 'translate(0, -40px)',
          }}
        >
          <div
            className=""
            style={{
              background: '#414455',
              width: '80px',
              height: '80px',
              borderRadius: '999px',
            }}
          >
            <img
              src="https://assets.codepen.io/3685267/res-react-dash-rocket.svg"
              alt=""
              className="w-full h-full"
            />
          </div>
          <div className="text-white font-bold mt-3">
            No Components Created Yet
          </div>
          <div className="mt-2">Simply create your first component</div>
          <div className="mt-1">Just click on the button</div>
          <div
            className="flex items-center p-3 mt-3"
            style={{
              background: '#2f49d1',
              borderRadius: '15px',
              padding: '8px 16px',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            <Icon path="res-react-dash-add-component" className="w-5 h-5" />
            <div className="ml-2">Add Component</div>
            <div
              className="ml-2"
              style={{
                background: '#4964ed',
                borderRadius: '15px',
                padding: '4px 8px 4px 8px',
              }}
            >
              129
            </div>
          </div>
        </div>
      </div>
    );
  }
export default AddComponent;  