import React, { useEffect } from 'react';
import { Outlet, useLocation } from "react-router-dom";
import NewNav from '../sub-components/NewNav';
import Footer from '../sub-components/Footer';

const Layout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the path changes
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <NewNav />
      <div className='bg-gradient-to-b from-gray-900 to-gray-600  text-white'>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
