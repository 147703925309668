import { useNavigate } from 'react-router-dom';
import MapComponent from '../Maps/GoogleMaps';
import Maps from '../Maps/Maps';

// `/user-dashboard/explore/${city}`


// List of cities component
const CityList = () => {
    const navigate = useNavigate();
    const cities = ['London',
        'Manchester',
        'Birmingham',
        'Leeds',
        'Liverpool'];

    return (
        <div className="bg-slate-800 p-3">
            <h2 className="text-2xl font-bold mb-4 text-white  ">Find us in this cities</h2>
            <ul>
                {cities.map((city, index) => (
                    <li key={index} className="mb-2 text-white text-xl hover:bg-slate-500 p-4" onClick={()=>navigate(`/user-dashboard/explore/${city}`)}>
                        {/* <a href={`/user-dashboard/explore/${city}`}> */}
                        {city}
                        {/* </a> */}
                    </li>
                ))}
            </ul>
        </div>
    );
};

// Main explore component
const ExploreMain = () => {
    return (
        <div className="w-full flex flex-col md:flex-row ">
            <div className="w-full md:w-1/4">
                <CityList />
            </div>
            <div className="w-full ">
                {/* <Maps /> */}
                <MapComponent />
            </div>
        </div>
    );
};

export default ExploreMain;