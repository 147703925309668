const HotelData = {
"Export":[
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-001-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-1",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-001-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-2",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-001-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-3",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-001-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-5",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-003-2",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-003-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-003-3",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-003-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-003-4",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-003-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-003-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-004-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-004-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-004-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-004-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-004-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-005-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-005-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-006",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-007",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-008-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-009-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-009-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-010-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-010-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-010-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-010-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-010-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-011-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-011-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-011-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-011-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-011-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-012-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-012-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-013",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-014",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-015-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-015-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-015-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-015-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-016-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-018-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-018-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-018-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-019-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-019-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-020-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-020-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-022",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-023-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-023-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-023-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-023-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-024-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-024-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-025",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 384,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-026-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-2",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-026-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-3",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-026-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-4",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-026-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-5",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-027",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-028",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-029",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-031-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-2",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-031-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-3",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-031-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-4",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-031-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-5",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-032-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-032-3",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-034-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-034-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-034-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-034-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-035",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-036-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-036-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-036-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-036-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-038",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-039-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-039-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-039-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-039-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-041",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-042",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-043-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-043-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-043-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-044-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-044-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-044-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-047-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-047-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-047-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-047-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-051-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-051-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-052",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-053",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 413,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-054-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-054-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-054-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-056-1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-056-4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 350,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-057-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-057-3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-057-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-057-5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-063-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-063-3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-063-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-064-1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 285,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-065-1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-065-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-065-3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-065-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-066",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 405,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-068-1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-068-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-068-3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-068-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-068-5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-073-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-073-5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-074-1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-074-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-074-3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-074-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-077-1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-077-3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-077-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-077-5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-081-1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-081-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-081-3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-081-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-081-5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-084",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 405,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-086-1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-087",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-088",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-090-3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-090-4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-092-1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-092-5",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 322,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-093-2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-093-3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-093-4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-093-5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-094-1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-094-2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-094-3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-094-4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-094-5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-095-4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-095-5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-096-1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-096-2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Abacus House",
  "Name": "Abac-096-5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1003",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1012",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1013",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1014",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1018",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1020",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1021",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1103",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1105",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1111",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1113",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1118",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1119",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1120",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 1-114",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 549,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1203",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1204",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1205",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1211",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1212",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1213",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1216",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1217",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1218",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1219",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1220",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1302",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1303",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1304",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1305",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1311",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1312",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1316",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1317",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1318",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1319",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1320",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1405",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1411",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1417",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1418",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1420",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 15-1504",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 15-1505",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 15-1519",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 16-1610",
  "Room Type": "Platinum Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 518,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 16-1618",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 461,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 4-415",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 549,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 9-916",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 451,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 9-919",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 451,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1001C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1001D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1006A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1006B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1006C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1006D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1006E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1007A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1007B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1007C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1007D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101A",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1101A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 261,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101B",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1101B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101C",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1101C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101D",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1101D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101E",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1101E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101F",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1101F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105A",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1105A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105B",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1105B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105C",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1105C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105D",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1105D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 260,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105E",
  "Term Session": "2024-25 15wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 15,
  "Rate": 257,
  "Start Date": "07/09/24",
  "End Date": "21/12/24",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1105E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 257,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1201A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1201B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1201C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1201E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1201F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1202",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1301C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1301F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1305E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1306A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1306B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1306C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1306D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1306E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1307A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1307B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1307C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1401B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1401C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1401E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1401F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1406A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1406B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1406C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1406D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1406E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1407A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1407B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1407C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1501C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1501F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1505E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1506A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1506B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1506C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1506D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1506E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1507A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1507B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1507C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1604",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1606A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1606B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1606C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1606D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1606E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1607A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1607B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1607C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1706A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1706B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1706C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1706D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1706E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1707A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1806A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1806B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1806C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1806D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1806E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1807A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1807C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1901F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1906A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1906B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1906C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1906D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-1906E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2002",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2006A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2006B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2006C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2006D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2006E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2007B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-201C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-201D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-201E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-201F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-202A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-202B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-203B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-203C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-203D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-203E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-204A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-204B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 231,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2106A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2106B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2106C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2106D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2106E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2107A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2107B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2107C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2205A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2206A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2206B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2206C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2206D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2206E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2403A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2403D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2403E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2501F",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2505A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2506A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2506B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2506C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2506D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2507A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2507B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2507C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2507D",
  "Room Type": "Gold En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 252,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2601A",
  "Room Type": "Gold En Suite Access",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2601F",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2606D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2607A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2607B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2607C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2707A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2907A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2907B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-2907C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3006A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3006B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3006D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3103",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 345,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3105D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3106A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3106B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3106C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3106D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3107A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3107B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3107C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3107D",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3201B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3201C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3201D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3201E",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3201F",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3206D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3207A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3207B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3207C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3207D",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3301B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3301C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3301D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3301E",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3301F",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3307C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3401B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3401C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3401D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3401E",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3401F",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3402",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 345,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3406A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3406B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3406C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3406D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3407A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3407B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3407C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3501B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3501C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3501D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3501E",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3501F",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3601C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 265,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3601D",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 265,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3601E",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 265,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3601F",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 265,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3605A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 265,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-3605B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 265,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-404",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 328,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-406B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-406D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-406E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-501C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-501D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-501E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-501F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-502",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-502",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-503",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-503",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-504",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-504",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-505A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-505B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-505D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-505E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-506A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-506B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-506C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-506D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-506E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-507A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-507B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-507C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-507D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-601B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-601C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-601D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-601E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-601F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-605A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-605E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-606A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-606B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-606C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-606D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-606E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-607A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-607B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-607C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-607D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-701A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-701B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-701C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-701D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-701E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-701F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-705A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-705B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-705E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 234,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-706A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-706B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-706C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-706D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-706E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-707A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-707B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-707C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-707D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-801A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-801E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-801F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-805A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-805B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-805C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-805D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-805E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 234,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-806A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-806B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-806C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-806D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-806E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-807A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-807B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-807C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-807D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 261,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-901F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-905A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-905E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-906A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-906B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-906C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-906D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-906E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-907A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-907B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-907C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Altus House",
  "Name": "AltH-907D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A01C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A04C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A04D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A09A",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A09A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 109,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A09D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A10A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A11B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A11C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A11D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A14A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A15C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A17B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A18A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A18B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A18C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A19A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A19B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A19D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A20A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A24C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A26A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A27D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A30A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A32A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-A32D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B01A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B01B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B01C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B01D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B01E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B02B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B02C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B02E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B05A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B05B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B05D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B05E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B06C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B06D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B09E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10A",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 105,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B10A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 105,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10B",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 96,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B10B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 96,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10C",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 96,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B10C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 96,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10D",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 96,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B10D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 96,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10E",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 105,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B10E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 105,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B11B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B11C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B13A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B16B",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B16D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B17B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B17C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B17D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B17E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B18A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B21B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B21C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B21D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B23A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B23B",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B24C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-B24D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C03A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C03B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C03D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04A",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C04A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04B",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C04B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04C",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C04C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04D",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C04D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C04E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C04E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C06C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C07B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C07C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C07D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C08C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C08E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C09A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C09D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11A",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C11A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 120,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11B",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C11B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C11C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C11C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11D",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C11D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C11E",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C11E",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 120,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C12A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C12B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C12C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C12D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C13B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C13C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C13D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C15E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16A",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C16A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16B",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C16B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16C",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C16C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16D",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C16D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16E",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 113,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C16E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 27wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 27,
  "Rate": 113,
  "Start Date": "04/01/25",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C20A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C20B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C20C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C20D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C20E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C22A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C22B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C22C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C22D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C23A",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C23B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C23C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C23D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C23E",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 117,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C24D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C24E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C25B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C25C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C25D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C26C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C26D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C28A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C28B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C28C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C28D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-C28E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D01B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D02B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D02C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D02D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D02E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D02F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D03A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D03B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D03C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D03D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D03E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D04B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D05A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D05B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D05C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D05D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D05F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D06A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D07B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D07C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D07E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D10A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D10D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D11A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D11C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D11D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D11E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D12A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D12B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D12C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D12F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D13D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D13E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D14B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D14C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D14D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D14E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D14F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D16A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-D16D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E03C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E04B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E04D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E04E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E07E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E09B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E09C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E11C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E13B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E14D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-E16E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 110,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F03B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 92,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F03D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F04A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F04B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F06A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F06B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F06C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-F08C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G02A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G02C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G02D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G02E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G02F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G03A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 95,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 95,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G03C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G03D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G03E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G05A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G05B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G05C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G05D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G05E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G05F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G06A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 95,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G06A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 95,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G06E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G07A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G07C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G07D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G07E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G07F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08A",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G08A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08B",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G08B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08C",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G08C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08D",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G08D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08E",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G08E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08F",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G08F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G09A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G09B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G09C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G09D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G09E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G10C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G10F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G11B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G11C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G11E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G13B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G13B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G13E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G13E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G13F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G13F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G15B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G15C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G15D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G15E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G16B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G16C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G16D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G16E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G16F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G17B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G17B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G17D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G17D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G18B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G18B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G18C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G18C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-G20D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G20D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 100,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H04A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H04B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H04D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H05A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 95,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 95,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H05B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H05C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H05D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 85,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H06A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H06B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H06D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H11A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H11D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H12A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H12C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H13B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H13C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bradford",
  "Asset": "Arkwright House",
  "Name": "Arkw-H13D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A01D",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A03D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A03D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A05B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A05B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A11B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A11B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A12D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A12D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13A",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A14A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A14A",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A14B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A14B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-A15B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A15B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03A",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B05B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B05B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B06D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B06D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-C06C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-C06C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-C07B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-C07B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-D07B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-D12C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-D12C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-G13D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-G14A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-H01A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-H02A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 135,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-H03",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-H05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 135,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-H10A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-H12E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 106,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Aspley House",
  "Name": "Aspl-A-H14A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 135,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-101",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-102",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-103",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-104",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-105",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-106",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-107",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-109",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-110",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-112",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 602,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-113",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-116",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-117",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-201",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-202",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-203",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-204",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-207",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-208",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-209",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-210",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-211",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 602,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-213",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-214",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-215",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-216",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-217",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-220",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-301",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-302",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-303",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-304",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-307",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-308",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-310",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-312",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 602,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-313",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-314",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-315",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-316",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-317",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-319",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 695,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-401",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-402",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-403",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-404",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-405",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-408",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-409",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-410",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-411",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 602,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-412",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 602,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-413",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-414",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-415",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-419",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 695,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 5-504",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 5-507",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 536,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 5-512",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 602,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-601",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-603",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-604",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-605",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-610",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-613",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-614",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-615",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-616",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-701",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-702",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-705",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-706",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-707",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-708",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-710",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-715",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-716",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-718",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-720",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 564,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G01",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G02",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G03",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G04",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G05",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G06",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G07",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G08",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bankside",
  "Name": "Bank-Ground-G10",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 528,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-002",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 717,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-003",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 717,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-004",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 802,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-006",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 717,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-010",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-012",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-013A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 614,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-013B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 614,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-016A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 614,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-016B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 614,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-017",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-019",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-020",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-118B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-118D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-118E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-118F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-119",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-120",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-218D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-218E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-218F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-218G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 653,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-414G",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 616,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-502",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 734,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-511",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG01",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 802,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG02",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 717,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG04",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 802,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG05",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG07",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG08",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG09",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG11",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG12",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 746,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1004",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1005",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1006",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1007",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1008",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1009",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1108",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1109",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1113",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1114",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1116",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1201.2",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 299,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1201.3",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 299,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1205",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1206",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1209",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1210",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1307",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1309",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1311",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1404.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1404.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1406",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 446,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1407.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-1407.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-209",
  "Room Type": "Gold Studio (Dda)",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 367,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-215",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-302",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-304",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-305",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-306",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-307",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-312",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-313",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-314",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-315",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-409",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 398,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-413",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-515",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-615",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-702",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-704",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-705",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-706",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-707",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-710",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-711",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-712",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-713",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-715",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-716",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-804",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-805",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-806",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-811",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-813",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-815",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-816",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-902",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-904",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-905",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-906",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-907",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-912",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-22-913",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1006.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1006.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1009.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1009.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-104",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-105",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-106",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-107",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-108",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-109.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-109.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-110",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 331,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1102",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1109",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-111.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-111.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1110.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1110.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-112",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-113",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-114",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-116",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1204",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1205.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1205.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1207",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1208",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1209",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1210.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-1210.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-204",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-205",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-206",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-207",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-208",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-209.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-210",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 331,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-211.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-211.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-212",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-214",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-217",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 331,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-305",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-306",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-307",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 323,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-308",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-309.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-309.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-312",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-315",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-316",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-404",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-405",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-406",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-407",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-408",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-409.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-409.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-410",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 331,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-412",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-413",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-414",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-502.5",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-505",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-508",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-509.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-509.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-511.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-511.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-513",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-609.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-609.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-611.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-611.2",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 303,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-613",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 320,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-703",
  "Room Type": "Gold Studio Plus (Dda)",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 383,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-704",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-707",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-708.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-708.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-710.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-710.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-711",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-712",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-713",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-803",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 404,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-804",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-805",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-807",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-808.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-808.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-906.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-906.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Brighton",
  "Name": "Brgt-7-909.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 50wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 50,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-018A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-018B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-018C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-018D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-018E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-018F",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-020A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-020B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-020D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 367,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-040B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-040D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-041A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-042A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-042C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 367,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-043B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 367,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-043D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 367,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-051",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 455,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-065B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-066B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-066C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-066D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-067A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-067B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-078",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-092A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-092B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-092C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 367,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-092D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 367,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-095",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 411,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-164",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-191A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-213",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-217D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 367,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-229",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-232",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-233",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Bristol",
  "Name": "Bris-239D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 377,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A01B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A01C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A01D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A01E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A03B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A03B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A03C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A03C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A03E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A03E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A05D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 115,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 115,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 115,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 115,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 115,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A06E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 115,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A07A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A07B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A08E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A10E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A11A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A11B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A11C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A11D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A12aA",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12aA",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A12aD",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12aD",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A12aE",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12aE",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A12B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A12C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A12D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A14A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A14B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A14C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A14D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A15A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A15A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A15C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A15C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A15D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A15E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A16B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A16C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A16D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A16E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A17B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A18D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A18E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A22A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A23D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A24E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A25E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A26B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A26C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A26D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A26E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A27A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A28C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A28D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A29A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A29B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A29D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A29E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A31B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A31C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A31D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-A31E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B01C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B03C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B03D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B06D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B06E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B09D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B09E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B12E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Brocco",
  "Name": "Brocco-B19D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 123,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-014",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-015",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-024-",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-025-",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-025-",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-026A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-053E",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-098",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-110A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-123A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-162B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-162B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-162D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-162D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-162E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-162E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-170A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-170A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-171C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Broderick House",
  "Name": "Brod-171D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-013-6",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-017-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-017-1",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-017-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-017-5",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-018-6",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-018-6",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-020-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-020-1",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-020-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-020-3",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-021-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-021-1",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-022-6",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-022-6",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-026-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-026-5",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-027-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-027-1",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-027-6",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-027-6",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-030-6",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-030-6",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-032-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-032-5",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-032-6",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-032-6",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-041-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-041-2",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-041-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-041-3",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-041-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-041-5",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-042-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-042-5",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-044-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-044-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-044-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-045-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-045-1",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-045-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-045-2",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-045-3",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-045-3",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-052-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Castings",
  "Name": "Cast-058-6",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-058-6",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 111,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-001A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-001B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-001C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-001D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-001E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-002B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-002C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-003A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-003B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-003C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-003D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-101A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-101B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-101C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-101D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-101E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-102A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-102B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-102C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-102D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-102E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-104",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-107",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-108",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-109",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-110",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-111",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-112",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-113",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-201",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-202",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-203",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-204",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-205",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-206",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-207A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-207B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-207C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-207D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-207E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-211",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 189,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-212",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 189,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-216",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 189,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-217",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 189,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-218",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 189,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-219A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-219B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-219C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-221",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-222",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-224",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-225",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-301",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-302",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-303",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-304",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-305",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-307A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-307B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-307C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-307D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-307E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-308",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-309",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-310",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-311",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-319A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-319B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-319C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-321",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-322",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-325",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-328E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-329",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-401",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-402",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-403",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-404",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-405",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 29wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 29,
  "Rate": 180,
  "Start Date": "04/01/25",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407C",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 29wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 29,
  "Rate": 174,
  "Start Date": "04/01/25",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407C",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 29wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 29,
  "Rate": 174,
  "Start Date": "04/01/25",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407E",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-407E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 29wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 29,
  "Rate": 174,
  "Start Date": "04/01/25",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407E",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-408",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-409",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-410",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-414",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-418",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-421",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-422",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-423",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-424",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-425",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-429A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-429C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-429E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-429F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-501",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-502",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-503",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-504",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-505",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-507A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-507B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-507C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-507D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-507E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-508",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-509",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-510",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-521",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-525",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-526A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-526C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-526D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-528A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 206,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-528B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 206,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-529B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-529C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-529D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-529F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-530",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-601",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-602",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-603",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-604",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-605",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-607A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-607B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-607C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-607D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-607E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-608",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-609",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-610",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-614",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-615",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-616",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-617",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-618",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-621",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-622",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-623",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-624",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-625",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-629A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-629B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-629C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-629E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-629F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-630",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-701",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-702",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-704",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-705",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-707",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-708",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-711",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-712",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-713",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-715",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-716A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-716D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-717A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 206,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-717B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 206,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-719B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-719D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-719E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-719F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-801",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-803A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-804A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-804B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-804C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-804D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-805A",
  "Room Type": "2 Bed Apartment Upper Flr",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-805B",
  "Room Type": "2 Bed Apartment Upper Flr",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-901B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-902",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-903",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-904",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Century Square",
  "Name": "Cent-905",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1001",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1003",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1004",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1005",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1006",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1008",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1010",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1011",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1011",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1012",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1012",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1013",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1013",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1014",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1014",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1015",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1015",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 10-1016",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1016",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-102",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-104",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-110",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-111",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 513,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1101",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1101",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1102",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1102",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1104",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1105",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1105",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1106",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1106",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1107",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1107",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1108",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1108",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1109",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1109",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1110",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1110",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1111",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1111",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1112",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1112",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1113",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1113",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1114",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1114",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1115",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1115",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 11-1116",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1116",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-112",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-129A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-129B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-130A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-130B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-131",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 578,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-132A",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 465,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-132B",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 465,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-133",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-135A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-135B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-136",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-137",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-140",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-144",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-147",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-149",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-150",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-151",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-152",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-153",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-154",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-158",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-159",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-160",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-161",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-162",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-164",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-166",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-167",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-168",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-169",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-174",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-176",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-178",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-180",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-182",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-184",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 578,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-185",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-186",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-187",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-188",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 1-189",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-209",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-211",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-213",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-215",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-217",
  "Room Type": "Bronze 1 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 714,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-220",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-220",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-221",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-221",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-222",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-222",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-224",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-224",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-225",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-225",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-226",
  "Room Type": "Bronze 1 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 714,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-227",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-227",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-228",
  "Room Type": "Bronze 1 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 714,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-230A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-230B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-231A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-231B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-232",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 578,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-233A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-233B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-236A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-236B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-239",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-244A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-244B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 481,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-245",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-250",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-251",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-252",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-260",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-268",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-271",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-272",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-274",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-278",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-278",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-279",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-279",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-280",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-281",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-281",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-282",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-283",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-283",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-285",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-285",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-287",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-287",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-289",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-289",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-290",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 578,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-291",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-291",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-293",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 2-294",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 3-317",
  "Room Type": "Silver 1 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 729,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 3-358",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 433,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 3-389",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 578,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-402",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-402",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-403",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-403",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-404",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-404",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-405",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-405",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-406",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-407",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-408",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-409",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-410",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-412",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-413",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-414",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-415",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-432",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-433",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-434A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-435",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-436",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-437",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-439",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-440",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-441",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-443",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-445",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-459",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-468",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-470",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-471",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-472",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-475",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-476",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-477",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-481",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 4-483",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-502",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-504",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-505",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-508",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-510",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-511",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-512",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-513",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-514",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-516",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-529",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-530",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-532",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-534",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-535",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-536A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-537A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-539",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-545",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-549",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-550",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-551",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-552",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 5-572",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-601",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-602",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-604",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-606",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-610",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-612",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-613",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-614",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-617",
  "Room Type": "Gold 1 Bed Apt Plus",
  "Term Session": "2024-25 42wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 745,
  "Start Date": "07/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-618A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-618B",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-619A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-619B",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-621A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-621B",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-622",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-623",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-624A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-624B",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-626",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-627",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-629",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-630",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-631A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-631B",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 454,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-634",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-635",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-636",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-637",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-638",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-639",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-640",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-643",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-644",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-645",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-646",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-647",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 6-648",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-701",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-714",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-722",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-728",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-729",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 497,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-734",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-735",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 444,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-736",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-737",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-740",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 7-741",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-801",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-803",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-804",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-805",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-806",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-808",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-810",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-813",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-816",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-822",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-823",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-825",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-826",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-827",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-828",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 8-829",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 9-901",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 9-902",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Floor 9-912",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 525,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G08",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G11",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G12",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G13",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G15",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G16A",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G16B",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G18A",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G18B",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G19",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G24",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G61",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G63",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G65",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Ground-G69",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 526,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L01",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L02",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L04",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L05",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L06",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L07",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L08",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 526,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L09A",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L09B",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L10",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 526,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L11A",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L11B",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 417,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L12",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L13",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L15",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L16",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L17",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L20",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L36",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 459,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L52",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L53",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L54",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L55",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L56",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "City",
  "Name": "City-Lower Ground-L57",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-006C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-006C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-202D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-207B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-306D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-306D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-306F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-306F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-308B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-308C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-308D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-308E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Collingwood",
  "Name": "Coll-308F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 163,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E002",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E003",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E004",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E005",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E006",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E008",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E009",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E010",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E011",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E012",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E013",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E014",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E015",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E016",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E017",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E018",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E019",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E020",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E021",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E103",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E104",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E105",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E108",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E111",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E112",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E114",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E116",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E118",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E119",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E202",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E203",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E204",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E205",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E206",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E208",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E209",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E210",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E211",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E212",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E213",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E213",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E215",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E215",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E216",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E216",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E217",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E217",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E218",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E218",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E219",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E219",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E220",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E220",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E221",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E221",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E301",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E301",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E302",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E302",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E303",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E303",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E304",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E304",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E305",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E305",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E306",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E306",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E308",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E308",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E309",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E309",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E311",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E311",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E401",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E401",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E402",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "East Court",
  "Name": "East-E402",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E404",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-E411",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N102",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N105",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N106",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 508,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N107",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N108",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N109",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N110",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N111",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N113",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 508,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N114",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N115",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N116",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N117",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N119",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N120",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N121",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N122",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N123",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N124",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N125",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N126",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N127",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N202",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N203",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N204",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N205",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N208",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N209",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N210",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N213",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N214",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N215",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N217",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N218",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N219",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N221",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 508,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N222",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N223",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N224",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N225",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N226",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N228",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N231",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N232",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N233",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N234",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N235",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N236",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N237",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N238",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N239",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N240",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N241",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N242",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N243",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N309",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N318",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N322",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N324",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N325",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N326",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N329",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N330",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N332",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N334",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N337",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 436,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N402",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N403",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N404",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N405",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N408",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N409",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N410",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N411",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N413",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N414",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N415",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N416",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N417",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N419",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N422",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N423",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N425",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N426",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N428",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N429",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N430",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N431",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N433",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N434",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N436",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N437",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N438",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N504",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N505",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N506",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N507",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N508",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N509",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N512",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N514",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N516",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N518",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N522",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N526",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N528",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N529",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N530",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N602",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N604",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N608",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N614",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N622",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N628",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N629",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-N630",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 440,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W005",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W006",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W007",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W008",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W009",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W013",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W015",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W101",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W102",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W103",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W104",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W105A",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W105B",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W105C",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W105D",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W113",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W114",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W118",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W120",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W126",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 422,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W202",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W203",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W205",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W206",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W207",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W208",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W209",
  "Room Type": "Diamond Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 664,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W210",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W211",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W212",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W213",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W214",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W216",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W217",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W220",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W221",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W222",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W223",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W224",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W225",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W226",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W227",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W228",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W229",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W230",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W232",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W233",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W234",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W235",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W236",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W237",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W238",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W302",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W303",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W305",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W306",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W307",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W310",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W311",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W312",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W313",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W315",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W316",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W317",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W318",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 475,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W320",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W321",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W323",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W324",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W325",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W327",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W328",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W329",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W330",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W332",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W333",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W334",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W335",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W336",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W337",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W339",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W401",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W402",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W403",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W404",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W405",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W406",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W407",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W411",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W412",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W413",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W415",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W416",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W417",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W421",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W423",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W424",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W425",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W426",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W427",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W428",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W429",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W430",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W432",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W433",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W434",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W435",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W436",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W437",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W438",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W439",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W502",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W503",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W504",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W505",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W506",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W507",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W508",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W511",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W513",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W514",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W516",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W517",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W521",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W522",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W523",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W525",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W526",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W528",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W529",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W530",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W532",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W533",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W535",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W536",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W537",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W539",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W604",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W606",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W608",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W609",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 656,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W611",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W613",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W627",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W629",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W633",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W634",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W635",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W637",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W639",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W704",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W706",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W707",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W708",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W710",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W711",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W714",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W715",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W721",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W723",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W724",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W725",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W727",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W728",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W729",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W739",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W803",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W804",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W805",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W809",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 656,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W811",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W815",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 416,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W816",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "East Court",
  "Name": "East-W818",
  "Room Type": "Silver Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 428,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0102F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 262,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0216",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0317",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0414",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0416",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0617",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0716",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-0905",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 299,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Glasgow",
  "Asset": "Elgin Place",
  "Name": "Elgin-1019",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 299,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-35",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-38",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-40",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 324,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-42",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-43",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-53",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-56",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-57",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-64",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-71",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-75",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 3-79",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 3-81",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Floor 3-88",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-138A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 343,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-21",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-23",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-24",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-25",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-26",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-28",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-29",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 324,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-31",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-32",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-33",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-34-A",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-34-B",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Ground-34-C",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-1",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-10",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-11",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 324,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-13",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-137B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 343,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-14",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-15",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-16",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-17",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-18",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-19",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-2",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-3",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-4",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-5",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-7",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 324,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-9",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-13R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-13R3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-13R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-13R5",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-24R6",
  "Room Type": "Silver Duplex Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 252,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-31R4",
  "Room Type": "Silver Duplex Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 271,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-32R1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-32R2",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-32R4",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-32R6",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 199,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-33R3",
  "Room Type": "Silver Duplex Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 271,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-33R5",
  "Room Type": "Silver Duplex Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 271,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-33R5",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 271,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-33R7",
  "Room Type": "Silver Duplex Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 271,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-33R7",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 271,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-34R1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-34R3",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-34R4",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-34R5",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-34R6",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-41R1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-41R2",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-41R3",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-41R4",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-42R1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-42R3",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-42R4",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-42R5",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-42R6",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-44R1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-44R2",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-44R3",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-44R5",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-44R6",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 195,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-51R3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-51R4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-51R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-52R1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-52R2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-52R3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-52R4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-52R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-53R1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-53R2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-53R3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-53R4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-53R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-54R2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-54R3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-54R4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-54R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-61R1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-61R2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-61R3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-61R4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-61R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-62R1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-62R2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-62R3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-62R4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-62R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-63R1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-63R2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-63R4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-63R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-64R1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-64R2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-64R3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-64R5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-65R1",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-65R3",
  "Room Type": "Penthouse Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-71R1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-71R2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-71R3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-71R4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-71R5",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-72R1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-72R2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-72R3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-72R4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-73R1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-73R3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-73R3",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-73R4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-73R4",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-73R5",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-73R5",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-74R1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-74R2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R2",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-74R3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R3",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-74R4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R4",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-74R5",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R5",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-75R1",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-75R1",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-75R2",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-75R2",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-75R3",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-75R3",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 193,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A01R5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A01R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A01R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A02R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A02R3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 162,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A02R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A02R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A02R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-A02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B01R2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 162,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B01R5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B01R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B01R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-B02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-C01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-C02R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-C02R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-C02R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-C02R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-C02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D01R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 162,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D01R2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 162,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D01R3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D01R5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D01R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 174,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D02R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D02R3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D02R5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-D02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E01R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E01R3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E01R5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E01R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E01R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-E02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F01R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F01R2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F01R3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F01R5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F01R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F01R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-F02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G01R3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G01R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G02R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G02R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G02R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G02R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G02R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-G02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H01R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R1",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H01R2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R2",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H01R3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R3",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H01R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R4",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H01R5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R5",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H01R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R6",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H01R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R7",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R1",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R2",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R3",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R4",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R5",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 154,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R6",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R7",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Exchange",
  "Name": "Exch-H02R8",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R8",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 166,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A01C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A03C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A07A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A09D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A09D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A10C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A10C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A13A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A13B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A13C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A13D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A15B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A15B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A15C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A15C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A15D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A15D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A16A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A16B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A16C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A16D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A18A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A18B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A18C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A18D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A19A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A19A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A22A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-A22C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A22C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B01B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B01B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B02C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B03C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B03C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B05A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B05A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B05D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 125,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B06A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B06A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B06B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B06B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B07A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B07B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B08A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B08B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B08C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B09A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B09A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B09B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B09B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B09C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B09C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B10B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B10B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B10C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B10C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B12A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B12B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B12C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B13B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B17A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B19B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-B20B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-C08C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-C11B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-C13B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-D02A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 125,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-D03A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-D07A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-D08C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-D08D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-D11A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-D14D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-E03B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-E03E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-E04B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F01",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F01",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F02",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F02",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F03",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F05",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F06",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F07",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F08",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F09",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F11",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-F16",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-G09A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-G09B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-G11A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-H03D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 125,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-H08A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-H12A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-H12B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-I04A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-I04B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-I04C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-I10D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-I13D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 122,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-I17B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 121,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J01",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J02",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J03",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J04",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J05",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J06",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J07",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J08",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J09",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J10",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J11",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J12",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J14",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J16",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J19",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J20",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J21",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J22",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J23",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J24",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J25",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J26",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J27",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 200,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J30",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J31",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J32",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J33",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J34",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Fenton House",
  "Name": "Fent-J35",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 222,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-A02C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 102,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-A06A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-A06A",
  "Term Session": "2024-25 43wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-B07A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B07A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-B08A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B08A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-B08B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B08B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-B08D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B08D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 98,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-C01A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-C01C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-C01D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-C08B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 93,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-D03B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-D05C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-D07B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-D15A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 153,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-D15B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 153,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-F01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-F03A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-F06A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-F07C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-F08B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G01A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 108,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G02D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G03A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G03D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G04C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G05C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G05D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Wolverhampton",
  "Asset": "Fiveways House",
  "Name": "Five-G06D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L01-A",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L01-C",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L01-D1",
  "Room Type": "Platinum Duplex 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 347,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-D1",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 347,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L01-D2",
  "Room Type": "Platinum Duplex 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 347,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-D2",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 347,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L01-F1",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-F1",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L01-F2",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-F2",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L01-G",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-G",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L02-A",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L02-C",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L02-D2",
  "Room Type": "Platinum Duplex 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 347,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L02-F1",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-F1",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L02-F2",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-F2",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L02-G",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 426,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L03-D1",
  "Room Type": "Platinum Duplex 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 347,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L03-F1",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L03-F2",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Fletcher House",
  "Name": "Flet-L0G-A2",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-002",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 430,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-023",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-031",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-035",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-043",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-045",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-050",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-051",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-053",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-054",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-061",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-062",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-063",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-064",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-065",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-070",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-071",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-072",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-078",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 435,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-080",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-081",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-083",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-084",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-092",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-099",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-100",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-102",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-103",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-108",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-109",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-110",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-118",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-119",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-120",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-126",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-127",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-128",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-131",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-136",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-138",
  "Room Type": "Medium Studio Rave M",
  "Term Session": "*2024-25 NOMS FLIN RUL 51wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 294.14,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-162",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 394,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-195",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 399,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-196",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 399,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-198",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 399,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-212",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 399,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Flinders House",
  "Name": "Flin-238",
  "Room Type": "Bronze Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 399,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-1-001A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-1-001C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-1-001E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-001",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 361,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-002",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-003",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 361,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-004",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 361,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-005",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 376,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-007",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-008",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 361,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-010",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-013C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-014",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-015",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-016",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-017",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-019",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-022",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-023",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-031B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-035",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-035",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-040",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-042",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-042",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-043",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-043",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-044",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-044",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-054",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 361,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-066B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-066E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-067D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-068",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-069",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-070",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-071",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-072",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 361,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-081",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 376,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-085D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-086",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-089",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-091",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-097",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 283,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-101A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-101D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-101E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-104",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-107",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 323,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-109",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-125C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-LFA",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 376,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-002C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-002D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-004A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-004B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-004D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-007A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-007B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-007C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-009B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-009C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-009E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-010B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-012D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-012E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-015B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-015C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-015E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-016A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-019A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-021C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-021D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 276,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-A04B",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 214,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-A05C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-A10A",
  "Room Type": "Gold 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-A10B",
  "Room Type": "Gold 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-A17A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-A17E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B19A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B19B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B19D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B19E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B20A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B20B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B20C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B20D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B20E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B22B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 160,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B22C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B22D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B22E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B23A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B23B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B23E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B24E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B25B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B26A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B26C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B26D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B26E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 160,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B27A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B27B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B27C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B27D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B27E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B31D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B31E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-B32E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C37A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C37C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C37D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C37E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C38A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C38B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C38C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 150,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C38D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C38E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C43A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C43C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C43D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C43E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C44E",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C45A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C45B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C45D",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C45E",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C46B",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 152,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C4848",
  "Room Type": "Gold 1 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 217,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C49A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C49B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C49C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C49D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C49E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C50A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C51A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C51B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C51C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C51D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-C51E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D53B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 160,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D54A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D54B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D54C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D54D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D54E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D55A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D55B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D55C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D55D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D55E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D65A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D65B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D65C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D65D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D65E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D65E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 151,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Liverpool",
  "Asset": "Great Newton House",
  "Name": "Grea-D66E",
  "Room Type": "Silver En Suite Deluxe",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-C01",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-C05",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E02B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E02C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E02E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E02F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E06B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E06D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E06F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E07A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E07B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E07C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E07E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E07F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E08C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E08D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E09A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E09B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E09C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E09D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E09E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E12A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E12B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E12F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E14A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E14B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E14C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E14D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E14E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E16C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E18A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E18B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E18C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E18D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Grosvenor House",
  "Name": "Gros-E18F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 132,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-69-201",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 320,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-69-407",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 314,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-71-001A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-71-001D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-71-001E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-71-002B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-71-002C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-71-002D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-71-002E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-001A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-001B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-001C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-001D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-001E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-001F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-002A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-002B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-002C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-002D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-002E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-003A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-003B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-003C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-003D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-003E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-003F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-004D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-005A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-005B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-005D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-005E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-005F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-007A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-75-007B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-001A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-001B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-001C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-001D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-004A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-004B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-004C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-004D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-004E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-101B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-305A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh",
  "Asset": "Grove",
  "Name": "Grove-77-405E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 273,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-B403",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 503,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C128",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 43wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 695,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C203",
  "Room Type": "Bronze Studio Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 579,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C204",
  "Room Type": "Bronze Studio Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 579,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C212",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C213",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C214",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C215",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C216",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C217",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C305",
  "Room Type": "Bronze Studio Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 579,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C334",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C336",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C338",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C403",
  "Room Type": "Bronze Studio Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 579,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C404",
  "Room Type": "Bronze Studio Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 579,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C405",
  "Room Type": "Bronze Studio Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 579,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C435",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C520",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C602",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C606",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C607",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C608",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C609",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C611",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C615",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C616",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C620",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C621",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C622",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C623",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C624",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C625",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C627",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C628",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C629",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C631",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C633",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C634",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C635",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C636",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C639",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C640",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C702",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C706",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C719",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C722",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C724",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C725",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C726",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C727",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C728",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C729",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C730",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C732",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C733",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C734",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C736",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C737",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C738",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C739",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C817",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C823",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C825",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C826",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C827",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C828",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C833",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C834",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C835",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C837",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C839",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 483,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C901",
  "Room Type": "Gold Cityview Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 529,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C902",
  "Room Type": "Gold Cityview Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 529,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C904",
  "Room Type": "Gold Studio Plus Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 614,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C905",
  "Room Type": "Gold Studio Plus Large",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 614,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C908",
  "Room Type": "Gold Cityview Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 529,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C910",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C911",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C913",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C915",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C916",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C917",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C918",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C919",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C920",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C921",
  "Room Type": "Gold Cityview Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 529,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C922",
  "Room Type": "Gold Cityview Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 529,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C923",
  "Room Type": "Gold Cityview Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 529,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C924",
  "Room Type": "Gold Cityview Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 529,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C927",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hammersmith",
  "Name": "Hamm-C928",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 494,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-A11-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-A11-D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-A18-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 136,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-A18-D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-B02-E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C03-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C04-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C05-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C06-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-C07-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-D08-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 139,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-D08-E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 139,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E03-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E03-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E05-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E05-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E06-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E06-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E06-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E07-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 124,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Hayes Wharf",
  "Name": "Haye-E08-E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 48wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 144,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Haywood House",
  "Name": "Hayw-310",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Haywood House",
  "Name": "Hayw-318",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Haywood House",
  "Name": "Hayw-417",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Haywood House",
  "Name": "Hayw-605C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 376,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Haywood House",
  "Name": "Hayw-606A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 376,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Haywood House",
  "Name": "Hayw-608A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 376,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Haywood House",
  "Name": "Hayw-703",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 539,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-111",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 43wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 357.82,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-115",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 416,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-117",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 416,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-119",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 416,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-156",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 416,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-157",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 416,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-160",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 43wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 357.82,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-161",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 43wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 357.82,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-162",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 327.69,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-163",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 312.66,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-164",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 312.66,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-165",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 312.66,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-168",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 327.69,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-169",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 327.69,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-170",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 327.69,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-171",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-171",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-173",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-173",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-174",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-174",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-175",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-175",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-176",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-176",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-178",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-178",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-179",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-179",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 1-180",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-180",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-201",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-201",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-202",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-202",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-204",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-204",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-205",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-205",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-206",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-206",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-207",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-207",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-209",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 327.69,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-212",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-213",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 HIGH UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 327.69,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-215",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-217",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-219",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-222",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-231",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-231",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-232",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-232",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-234",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-234",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-256",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-257",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-258",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 2-264",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 324,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 3-315",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 3-317",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 3-319",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 3-332",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 3-342",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 368,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 3-357",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 416,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-411",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-413",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-422",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-432",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-435",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-440",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-443",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-455",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-469",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-470",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-472",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-476",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-477",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-478",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 4-480",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-506",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-519",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-522",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-532",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-545",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-556",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 381,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-559",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-568",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-569",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Highbury",
  "Name": "High-Floor 5-575",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-10",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-10",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-6A",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-6B",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-7",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-7",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-8",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-8",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-11A",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-12",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-12",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-13",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-13",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-15",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-15",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-16B",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-17",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-17",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-18",
  "Room Type": "Platinum 1 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-18",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 425,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L04-21A",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L04-21A",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L04-21B",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L04-21B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-1A",
  "Room Type": "Silver 2 Bed Duplex",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 310,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-1B",
  "Room Type": "Bronze 2 Bed Duplex",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 300,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-2B",
  "Room Type": "Bronze 2 Bed Duplex",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 300,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-3A",
  "Room Type": "Silver 3 Bed Duplex",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 288,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-3C",
  "Room Type": "Silver 3 Bed Duplex",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 288,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-013",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-023",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-025",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-026",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-027",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-030",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-031",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-032",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-033",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-035",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-036",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-037",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-038",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-039",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-040",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-041",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-043",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-044",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-045",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-046",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-047",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-048",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-049",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-051",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-052",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-053",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-055",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-056",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-057",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-058",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-059",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-060",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-061",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-063",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-064",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-065",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-066",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-067",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-068",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-069",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-070",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-089",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-089A",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-090",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-094",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-096",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-097",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-098",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-099",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-101",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-102",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-103",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-104",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-106",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-107",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-108",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-132",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-133",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-135",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-138",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-140",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-141",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-142",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-143",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 448,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-167",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 487,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-169",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 510,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-171",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 510,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-175",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-179",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 423,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-192",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-193",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-194",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-196",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-197",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-198",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-199",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-200",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-201",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Hoxton",
  "Name": "Hoxt-220",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 430,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K06A",
  "Room Type": "Silver 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 313,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K13A",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K13B",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K14A",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K21A",
  "Room Type": "Gold 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K37B",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K38A",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K38B",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K45A",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K45B",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K46A",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K46B",
  "Room Type": "Platinum 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 339,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K53A",
  "Room Type": "Penthouse 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K54A",
  "Room Type": "Penthouse 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K54B",
  "Room Type": "Penthouse 2 Bed",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-102D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-103B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-103C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-103D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-103E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-103F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-202A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-202B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-202C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-202D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-202F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-203B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-203D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-203E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-203F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-213D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-213F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-213G",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-302A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-302B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-302C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-302D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-303D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-402A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-402B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-402E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 340,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-413D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-413F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-413G",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-503B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-503C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-503D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-503F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-513D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Kingston",
  "Asset": "Kingston",
  "Name": "King-513F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0101",
  "Room Type": "Gold Studio (Accessible)",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 238,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0103D",
  "Room Type": "5-Bed Bronze Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 116,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0103E",
  "Room Type": "5-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0104C",
  "Room Type": "3-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 140,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0105C",
  "Room Type": "3-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 140,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0202A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0202B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0202C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0203C",
  "Room Type": "5-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0203E",
  "Room Type": "5-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0204B",
  "Room Type": "3-Bed Bronze Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0204C",
  "Room Type": "3-Bed Bronze Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0204D",
  "Room Type": "3-Bed Bronze Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0302",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 238,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0304A",
  "Room Type": "5-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0304E",
  "Room Type": "5-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0305B",
  "Room Type": "3-Bed Bronze Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0305C",
  "Room Type": "3-Bed Bronze Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0305D",
  "Room Type": "3-Bed Bronze Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0403A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0403B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0403C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0406A",
  "Room Type": "4-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0503A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0504E",
  "Room Type": "5-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 120,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0506A",
  "Room Type": "4-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0506C",
  "Room Type": "4-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0506D",
  "Room Type": "4-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 131,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0507A",
  "Room Type": "3-Bed Silver Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 140,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0603A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0603B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0603C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0603D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0603E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0603F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0604A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0604B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0604C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0604D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0604E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0604F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 175,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0803A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0803B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0803C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0803D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0803F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0804A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0804B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0804C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0804D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0804E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0804F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0903A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0903B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0903F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0904A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0904B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0904E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-0904F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1003A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1003B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1003E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1003F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1004A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1004B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1004D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1004E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1004F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1103A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1103B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1103F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1104A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1104B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1104F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1203A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1203B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1203D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1203E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1203F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1204A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1204B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1204D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1204E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1204F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1303A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1303B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1303C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1303D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1303E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 208,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 208,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 208,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 208,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 208,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 208,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 208,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 208,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 208,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 208,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 208,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1304F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 208,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1608",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 252,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Knight House",
  "Name": "KniH-1614",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 252,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-001A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-004D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-004D",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-004E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-004E",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-006A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006A",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-006B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006B",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-006C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006C",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-006D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006D",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-006E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006E",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-007A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-007A",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-018B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-018C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-019B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-019B",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-020D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-020D",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-022D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-023A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-023A",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-023C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-023E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-024B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-024C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-024D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-025A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-026A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-026B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-026C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-026E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-026F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-029A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 107,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-029F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-029F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 107,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-035C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-037F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-038C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-039B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-039C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-044B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-044C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-044D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-045C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-047A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-047B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-047D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-047E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-049A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-049B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-051D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-051F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-052B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-052C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-052E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-053D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-055A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-055C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-055D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-057C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-061A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-061C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-061D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-063A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-063D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-065A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-065C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-065D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-065E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 149,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-070A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-070F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-071A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-071D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-073A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-073B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-073D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-074D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-076D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-085A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-085C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-085D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-094A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-094C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-094D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-095A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-095B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-095D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-096A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-103A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-103B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-103C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-111A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-111D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-112A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-112B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Preston",
  "Asset": "Kopa",
  "Name": "Kopa-112D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 116,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-FD105",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 45,
  "Rate": 253,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-LB603",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-LB703",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-LC608",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 235,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-027A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-027D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-029D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-030A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-030C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 159,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-048A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-048B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-048C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-048D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-051B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-052A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-052B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-052C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-052D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-052E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-053A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-053B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-053C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-054A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-054B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-054C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-054D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-057A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-058A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-058B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-058C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-058D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-059C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-059D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-060A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-060B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-060C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-060D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-063A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-063C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-064A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-064B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-064C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-064D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-064E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-067B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-068A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-068C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-068D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-069B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-069C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-069D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-069E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-070A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-070B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-071B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-071C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-071D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-071E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-072C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-072D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-072E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-076E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-077B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-077B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-077C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-077C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-077D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-077D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-078A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-078B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-078C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-078D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-078E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-080A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-080B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-080C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-080D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-086D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-086E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-087A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-087B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-087D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-088B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-088C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-088D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-088E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-089D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-090A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-090B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-090C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-090D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-090E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-091A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-092A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-092B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-092C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-092D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-092E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-093A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-093B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-093C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-093D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-093E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-094A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-094B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-094C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-094D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-094E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-096A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-096B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-096C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-096D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-096E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-097C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-097D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-098A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-098B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-098C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-098D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-099B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-099D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-100E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-101D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-102E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 179,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-103A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-103B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-103C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-103D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-104A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-104C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-104D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-104E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-105A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-105B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-105C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-105D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-105E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-106A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-106B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-106C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-106D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-106E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-108A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-108B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-108C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-108D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-108E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-109A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-109B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-109C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-109D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-110A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-110B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-110C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-110D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-110E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-111A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-111B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-111C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-111D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-111E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-112A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-112B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-112C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-112D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-112E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-113A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-113B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-113C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-113D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-113E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-113F",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-114A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-114B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-114C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-114D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-114E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-114F",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-115A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-115B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-115C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-115D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-116C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-118C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-119A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-119B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-119C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-119D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-119E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-120A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-122A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-122B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-122C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-122E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-122F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-123A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-123B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-123C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-123D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-123E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-123F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-129A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-129D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-130A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-130D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-130E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-131A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-131B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-131C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-131D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-131E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-132A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-132B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-132C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-132D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-133A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-133B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-133C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-133D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-133E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-133F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-134A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-134C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-134D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-137A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-137B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-137C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-137D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-138A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-138B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-138C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-138D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-138E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-138F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-139A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-139D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-139E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-140A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-140B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-140C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-140D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-141A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-141B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-141C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-141D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-141E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-141F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-142A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-142B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-142D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-143B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-145A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-145C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-145D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-146B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-146C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-146D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-146E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-146F",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-147A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-147B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-147C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-147D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-147E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-148A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-148B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-148C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-148D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-149A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-149B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-149C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-149D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-149E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-149F",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-150A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-150B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-150C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-150D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-151A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-151B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-151C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-153A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-153B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-153C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-153D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-154A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-154B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-154C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-154D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-154E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-154F",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-155A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-155B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-155D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-155E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-156A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-156B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-156C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-156D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-157A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-157B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-157C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-157D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-157E",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-157F",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-158A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-158C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-158D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-159A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-159B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-159C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR03",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 278,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR04",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 278,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR05",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR06",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR07",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR08",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR09",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR10",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR13",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 278,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR14",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR18",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Leeds",
  "Name": "Leed-GR19",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 278,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-B04D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 121,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-C13E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-C13E",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E01D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E01D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02D",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E03B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E03B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F04B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F04B",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 107,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F06E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F06E",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F09E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F09E",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F12E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F12E",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F13A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F13A",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F15E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F15E",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G03A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G03A",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G06D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G06D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G07A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G07A",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Huddersfield",
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G08D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G08D",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BA-11-C",
  "Term Session": "2024-25 44wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BA-11-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BA-12-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BA-12-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-02-D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 218,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-07-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-08-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-08-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-07-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-07-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-08-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-08-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 254,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KC-12-E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-05-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-07-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-07-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 237,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-02-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-02-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-02-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-05--",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 265,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-07-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-11-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-11-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-11-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-22-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-23-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-25-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-25-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-33-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-38-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-38-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-38-F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-40-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-40-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-40-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-47-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-47-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-47-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-48-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-48-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-48-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-49-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 197.17,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-A",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 197.17,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-B",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 197.17,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-C",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 197.17,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-D",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 197.17,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-E",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 182.75,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 182.75,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 182.75,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 182.75,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 182.75,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-F",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 MARS MetFilm 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 182.75,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-55-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-55-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-56-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-56-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-56-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-60-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-60-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-60-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-65-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-65-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-65-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-71-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-71-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-77-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-77-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-77-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-79-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-79-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leeds",
  "Asset": "Marsden House",
  "Name": "Mars-BB-79-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 198,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-101",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-116",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 210,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-117",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 283,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-118",
  "Room Type": "2 Bed Apartment Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 227,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-119",
  "Room Type": "2 Bed Apartment Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 227,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-121",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-122-E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-124-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-124-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-124-F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-125",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-126",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-201",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-222-A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-222-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-222-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-222-E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-223-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-223-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-223-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-223-F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 196,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-224-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-224-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-224-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-225",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-226",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-316",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-322-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-325",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-326",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 205,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-327-A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-327-B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-327-C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-401",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-404",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-414",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-417",
  "Room Type": "2 Bed Apartment Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 215,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-420-B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-420-D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-421-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-422",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-424",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-426",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-429-A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-429-B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-429-C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-502",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-504",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-505",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-510",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-511-B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 201,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-512",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 251,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-513",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-515",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G01",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 210,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G02",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G03",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G04",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 210,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G05",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 210,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G06",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G07",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 219,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G08",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 210,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G09",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 210,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G10",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G11",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 210,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Nelson Court",
  "Name": "Nels-G13",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 230,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-1117",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-1210",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-1216",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-312",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-313",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-314",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-315",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-316",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-317",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-318",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-319",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-320",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-321",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-411",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-412",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-414",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-415",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-417",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-418",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-419",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-710",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 263,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-719",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 242,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-821",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-822",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newland House",
  "Name": "Newl-912",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newtown House",
  "Name": "Newt-301",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 249,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Newtown House",
  "Name": "Newt-406",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 262,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-12.b",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.a",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.b",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.c",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.d",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.a",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.b",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.c",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.d",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.e",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.f",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-15.c",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-15.d",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.a",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.b",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.c",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.d",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.e",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.a",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.b",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.c",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.d",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.e",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.f",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-18.c",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-18.d",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-18.e",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-19.a",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-19.b",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-19.c",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.a",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.b",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.c",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.d",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 161,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.e",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.f",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-21.a",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-21.e",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-22.a",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-22.b",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-22.c",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.a",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.b",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.c",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.d",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.e",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 158,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-24.a",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BA-24.c",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 167,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BB-13.c",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 42wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BB-57.c",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 42wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 42,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BC-01.b",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.a",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 157,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.b",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 157,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.d",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 157,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.e",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 157,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.f",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 157,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.b",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.c",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.d",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Leicester",
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.e",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 168,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG71-A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 491,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG73-A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 491,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG74-B",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 491,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG75-A",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 491,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG75-B",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 491,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 1-PG06-B",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 1-PG06-C",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 2-H01-A",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 2-H01-B",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 2-H01-C",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 3-H17-B",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 3-PG20-B",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 3-PG22-C",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 4-H43-A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 4-H43-B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-H51-A",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-H51-B",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-PG33-A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-PG33-B",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-PG38-C",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-H68-B",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-H72-72",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 615,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG41-A",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 471,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG46-A",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG46-B",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 442,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG46-C",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG47-A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG50-B",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG51-B",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG54-C",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG55-A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG58-A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG58-B",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG60-B",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG62-C",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 458,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG63-A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG63-B",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG69-A",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG69-B",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 478,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Mezzanine-PG00-A",
  "Room Type": "Bronze 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 439,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Mezzanine-PG00-B",
  "Room Type": "Bronze 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 439,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Paris Gardens",
  "Name": "ParG-Mezzanine-PG00-C",
  "Room Type": "Bronze 3 Bed Apt Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 439,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05A",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B05A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05B",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B05B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05C",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B05C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05D",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B05D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05E",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 201.14,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B05E",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 201.14,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06A",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 201.14,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B06A",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 201.14,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06B",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B06B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06C",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B06C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06D",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B06D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06E",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 155.76,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B06E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 155.76,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-B10A",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-C11E",
  "Room Type": "Gold En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 180.75,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-C12A",
  "Room Type": "Gold En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 180.75,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-C13E",
  "Room Type": "Gold En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 180.75,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-C14A",
  "Room Type": "Gold En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 180.75,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D07G",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D09A",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D11A",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D12G",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D16F",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 148.34,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D16G",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D17A",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D17B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 148.34,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D17C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 148.34,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D17D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 148.34,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D17E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 148.34,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D17G",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 148.34,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D18G",
  "Room Type": "Platinum En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 191.56,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D21F",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 49wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 49,
  "Rate": 139.26,
  "Start Date": "07/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-D22E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 148.34,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E13E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 49wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 49,
  "Rate": 161.8,
  "Start Date": "07/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E13F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 49wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 49,
  "Rate": 155.04,
  "Start Date": "07/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E13G",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 49wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 49,
  "Rate": 155.04,
  "Start Date": "07/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E14B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E14C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E14E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15A",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 212.48,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E15A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 212.48,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15B",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 180.97,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E15B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 180.97,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15C",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 180.97,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E15C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 180.97,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15D",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 180.97,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E15D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 180.97,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15E",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 180.97,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E15E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 180.97,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15F",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 173.41,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E15F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 173.41,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15G",
  "Term Session": "*2024-25 NOMS PARK AU 18wks - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 18,
  "Rate": 173.41,
  "Start Date": "07/09/24",
  "End Date": "11/01/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E15G",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 24wks - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 24,
  "Rate": 173.41,
  "Start Date": "18/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E18E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E18E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-E19A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 213,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F01A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 198.76,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F01B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F01C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F01D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F02D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F02E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F03A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 198.76,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F03B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F03C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F04A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 198.76,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F04B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F04C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F04D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F05A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 198.76,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F05B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Dundee",
  "Asset": "Parker House",
  "Name": "Park-F05C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS PARK AU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 172.35,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-08-F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-10-B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-10-E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-10-F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-15-B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-18-E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-35-B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA2-01",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA2-32-D",
  "Room Type": "Copper En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 130,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA2-51",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-02-B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-02-C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-06-A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 114,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-07",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 51,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-15-E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-20-E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-20-F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-45-F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BB-03",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 182,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BB-14-F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BB-38-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-02-D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-05-A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-05-D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-05-E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-06-C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-10-B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-10-C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-10-E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-11-A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 179,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-13-D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-15-E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-18-F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC2-02-A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 184,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC2-11-A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 184,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC3-02-A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 184,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BC3-09-F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 170,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-02-B",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-04-B",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-07-F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-31-A",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-31-B",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-34-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-01-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-01-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-01-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-04-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-04-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-08-A",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-09-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-09-F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-10-A",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-11",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-21-A",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-21-B",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-23-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-29-A",
  "Room Type": "2 Bed Apartment Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-31-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-57-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 142,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-59-B",
  "Room Type": "Gold Studio Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 187,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Lincoln",
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-62-B",
  "Room Type": "1 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 260,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-02-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 191,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-02-4",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 45,
  "Rate": 191,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-08-4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 191,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-08-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 191,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-09",
  "Room Type": "Copper Studio",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 228,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-10-1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-16-1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-18-1",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 209,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-18-2",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 209,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-18-3",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 209,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-18-4",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 209,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-19-1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 222,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-19-3",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-21-2",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 180,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-21-5",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 180,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-22-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 180,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-24-1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 194,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-27-1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-27-2",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-27-3",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-27-4",
  "Room Type": "Gold En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 199,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-30-1",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 180,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-32-1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 194,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-32-2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 179,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-33-4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 179,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-34-4",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-43-1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 236,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-43-2",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 209,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-43-3",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 209,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-43-4",
  "Room Type": "Gold En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 225,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-45-1",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-45-5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-46-1",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-46-2",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-46-3",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-46-4",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-46-5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-48-1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-48-5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-50-1",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 213,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-50-2",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 213,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-50-3",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 213,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-50-4",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 213,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-51-2",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-51-3",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-53-2",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-53-5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-54-5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-56-1",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-56-2",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-56-4",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-57-1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 222,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-57-2",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-57-3",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-59-2",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-59-4",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-60-1",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-60-2",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-60-3",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-60-5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-61",
  "Room Type": "Copper Studio Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 233,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-62-1",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-62-2",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-62-3",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-62-4",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-63-1",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 222,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-63-2",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-63-3",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-63-4",
  "Room Type": "Gold En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-65-2",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-65-3",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-65-5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-66-2",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-66-3",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-66-5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-67",
  "Room Type": "Copper Studio Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 233,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-68-1",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-68-2",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-68-3",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-68-4",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 200,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-70-1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-70-2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-70-3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-70-4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-70-5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-71-1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-71-2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-71-3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-71-4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-71-5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 215,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-73-1",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 220,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-73-2",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 220,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-73-3",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 45wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 220,
  "Start Date": "07/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-75-1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 202,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-75-2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 202,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-75-3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 202,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-75-4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 202,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-75-5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 202,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-76-1",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-76-2",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-76-3",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-76-4",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-78-1",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-78-2",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Penworks House",
  "Name": "Penw-78-3",
  "Room Type": "Platinum En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 206,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-001",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-005.1",
  "Room Type": "Bronze 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 285,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-006",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-007",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-201.1",
  "Room Type": "Gold 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-201.2",
  "Room Type": "Gold 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-202.1",
  "Room Type": "Gold 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-202.2",
  "Room Type": "Gold 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 308,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L01",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L02",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L03.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L03.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L04",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L05.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L05.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L06",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-C-L07",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-002.3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-002.4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-002.5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-102.1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-302.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-302.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-L01",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-I-L02",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 326,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-002",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-003",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-005",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-007",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-008",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-009",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-011",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-014",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-015",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-016",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-108",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-110",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-111",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-113",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-117",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-212",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-302.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-302.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-L01",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-S-L04",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-001.1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-301.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-301.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-302.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-302.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-305.1",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-305.2",
  "Room Type": "Silver 2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 288,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-L01",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 397,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "Podium",
  "Name": "Podm-W-L04",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 397,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-10",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-11",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-12",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-13",
  "Room Type": "Accessible Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-14",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-2",
  "Room Type": "Accessible Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-3",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 338,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-4",
  "Room Type": "Bronze Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 334,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-44",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-45",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-6",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-7",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-8",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-9",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-15A",
  "Room Type": "Gold En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 321,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-15C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-15E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 307,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-16",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-17",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-18",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-19",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-20",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-22",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-23",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 353,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-24",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-25",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-26",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 338,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-27",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-28",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-29",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-30",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-31",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-32",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-33",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-36",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 346,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-37",
  "Room Type": "Gold Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 353,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-38",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-39",
  "Room Type": "Silver Studio Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-1A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-1B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 316,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-1C",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 312,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-40",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-41",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-42",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Brighton",
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-43",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 354,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0105",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 533,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0107",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 533,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0108",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0109",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0114",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 541,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0116",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 541,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0118",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 617,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0125",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0128",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0148",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0150",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0152",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0153",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0201",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 560,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0202",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 639,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0205",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0206",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0207",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0208",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0209",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0211",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0213",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0215",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0217",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0219",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 486,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0225",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0228",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0248",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0249",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0250",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0253",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0301",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 560,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0325",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0328",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0348",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0349",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0350",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0353",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0421",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0425",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0448",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0450",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0453",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0501",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0518",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0546",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0548",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0549",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0550",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0553",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0601",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0602",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 586,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0604",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 586,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0610",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 586,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0618",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0628",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0634",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0635",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0648",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0650",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0652",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0680A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 414,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0701",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0710",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 586,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0712",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0718",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0728",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0748",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0749",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0750",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0752",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0753",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0791",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0801",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0802",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0803",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0804",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 586,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0805",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0806",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0807",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0808",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0809",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0810",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 586,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0813",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0815",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0817",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0819",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0825",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0835",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0846",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0847",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0850",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0852",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0890",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0901",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0903",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0905",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0906",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0907",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0908",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0909",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0910",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 586,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0911",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0913",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0915",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0917",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0918",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0919",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0921",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0922",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0923",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-0925",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1001",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1002",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1003",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1005",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1006",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1007",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1008",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1009",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1011",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1013",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1015",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1017",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1019",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1021",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1022",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1023",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1025",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1034",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1035",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1046",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1047",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1048",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1049",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1101",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1102",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1103",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1104",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1105",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1106",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1107",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1108",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1109",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1110",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1111",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1112",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1113",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1116",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1118",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1121",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1125",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1126",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1128",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1136",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1137",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1138",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1139",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1201",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1202",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1203",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1204",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1206",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1207",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1209",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1214",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1221",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1224",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1226",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1231",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1234",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1235",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1236",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1238",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1301",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1303",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1304",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1305",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1306",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1307",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1308",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1309",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1311",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1312",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1313",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1314",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1316",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1317",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1318",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1319",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1321",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1323",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1325",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1328",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1333",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1335",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 511,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1336",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1337",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1338",
  "Room Type": "Platinum Penthouse",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 655,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1340",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 496,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1402",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1404",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 613,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1405",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1407",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1408",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1409",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1411",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1412",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1414",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1416",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1502",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 565,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1504",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1505",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1506",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 545,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1510",
  "Room Type": "Platinum Penthouse Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 686,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1511",
  "Room Type": "Platinum Penthouse Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 686,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Shoreditch",
  "Name": "Shor-1513",
  "Room Type": "Platinum Penthouse Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 686,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C002A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C002B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C002C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C002D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C002E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C003A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C003B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C003D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C003E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C004A",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C004B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C004C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C004D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C004E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C004F",
  "Room Type": "Silver En Suite Premium",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C005",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C006",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C007",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C009A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 197,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C009B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 197,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C009C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 197,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C009D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 197,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C009E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 197,
  "Start Date": "14/09/24",
  "End Date": "21/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C103A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C103B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C103C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C103D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C103E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C105",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C105",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C106",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C106",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C107",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C107",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C108A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C108B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C108C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C108D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C108E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C108F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C109A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C109B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C109C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C109D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C109E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C109F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C110",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C110",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C111",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C111",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C112",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C112",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 248,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C113A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C113B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C113C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C113D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C113E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C113F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C114A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C114A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C114B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C114C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C114D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C114E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 185,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C115A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C115B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C115C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201A",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C201A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 176,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C201B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C201B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 176,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201C",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C201C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 176,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201D",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C201D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 176,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201E",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C201E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 176,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201F",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C201F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 176,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C202B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C202D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C202E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C203A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C203B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C203C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C203D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C203E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 194,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C204E",
  "Term Session": "2024-25 19wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 19,
  "Rate": 176,
  "Start Date": "14/09/24",
  "End Date": "25/01/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C204E",
  "Term Session": "2024-25 21wks Starting 01/02/2025 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 21,
  "Rate": 176,
  "Start Date": "01/02/25",
  "End Date": "28/06/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C204E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C205",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 261,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C205",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 261,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C208B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C208E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C210",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 261,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C210",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 261,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C211",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 261,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C211",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 261,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C213A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C213B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C213E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C215A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C215B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C215C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C215D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C215E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C215F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C216B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C216F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 164,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C301A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C301B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C301C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C301D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C301E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C301F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C302A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C302B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C302C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C302D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C302E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C302F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C303A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 192,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C303C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 192,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C303D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 192,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C303E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 192,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C304A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C304B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C304C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C304D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C304E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C304F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C305",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C306",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C307",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C308A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C308B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C308C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C308F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C309B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C309C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C309D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C309E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C309F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C310",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C311",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C312",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C313A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C313C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C313D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C313E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C313F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C314A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C314B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C314C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C314D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C314E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315A",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 183,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 183,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 15,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 183,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315D",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 183,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315E",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 183,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315F",
  "Term Session": "2024-25 15wks Starting 14/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 15,
  "Rate": 183,
  "Start Date": "14/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C315F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 35wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 35,
  "Rate": 183,
  "Start Date": "04/01/25",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C316A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C316B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C316D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C316E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C316F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C401A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C401B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C401C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C401D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C401E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C401F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C402A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C402B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C402C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C402D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C402E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C403A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C403B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C403C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C403D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C404A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C404B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C404D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C404E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C404F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C405",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C407",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C408A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C408B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C409C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C409D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C409F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C410",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C411",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C412",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C413A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C413B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C413C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C413D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C413E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C413F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C414B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C414C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C414D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 212,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C415A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C415B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C415C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C415D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C415E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 202,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C416A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C416B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C416C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C416D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C416E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C416F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C501A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C501B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C501C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C501D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C501E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C501F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 180,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C503A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C503B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C503E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C503F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C503G",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C504",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C505",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C512",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C513",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C514",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 258,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C519",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C520",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C521",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C522B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C522C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C522D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C522E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C522F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C522G",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C523",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 233,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C524A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C524B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C524D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C524E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-C524F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M001",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M002",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M003",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M004",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M005",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M006",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M007",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M008",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M009",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M011",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 239,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M012",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M013",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M014",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M015",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M101",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M102",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M104A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M104B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M104D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M104F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 173,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M105A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M105B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M105C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M105D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M106",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 275,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M107",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M108",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M109",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M110",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M112",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M113",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M115",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M116",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M117",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M118",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M119",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M120",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M122",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M123",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M124",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M125",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M127",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M201",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M202",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M205A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M205B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M205C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M205D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M207",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M209",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M211",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M212",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M213",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M215",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M216",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M217",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M218",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M219",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M223",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M224",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M227",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 255,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M228B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M228E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M228G",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 45wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 45,
  "Rate": 178,
  "Start Date": "14/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M301",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M304A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M304B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M304C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M304D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M304E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M304F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 171,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M305D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 203,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M306",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 253,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M309",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M310",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 233,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M313",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M317",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M320A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M320C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M320D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M320E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M320F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M320G",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M401",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M402",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M404A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M404B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M404C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M404D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M404E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M404F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M404G",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 165,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M407",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M409",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M411",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M412",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 233,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M415",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M423",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M501",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M506",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Nottingham",
  "Asset": "Signal Place",
  "Name": "Sign-M511",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 243,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-05A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-06F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-08B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-08C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-08D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-08E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-09A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-09B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-09C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-09D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-09E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-13A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-13B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-13D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-14A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-14B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-14C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-14D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-16A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-16C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-16D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-16E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-17C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-17D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-18A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-18B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-18C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-18D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-18E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-21A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 136,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-21B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 136,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-21C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 136,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-21D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 136,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-22E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-23A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-23E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-27B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-27D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 126,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-30",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-35A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 134,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-35B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 134,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-35C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 134,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-35D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 134,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-35E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 39wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 134,
  "Start Date": "14/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-38",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-43A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-43B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-43C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-43D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-43E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-47",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 191,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-50A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-50C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-50D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-50E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 127,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-53",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 218,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-56D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-56E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Sheffield",
  "Asset": "Steel",
  "Name": "Steel-58",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 221,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-007-4",
  "Room Type": "Gold Room",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-017-1",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-018-1",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-018-2",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-018-3",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-018-4",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-019-1",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-019-4",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-019-5",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-020-1",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-020-3",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-020-4",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-023-1",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-024-2",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-024-3",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-024-4",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-024-5",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-025-1",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-026-3",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-026-4",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-026-6",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-028-6",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-030-5",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-032-3",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-032-5",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-032-6",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-035-1",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-036-1",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-037-2",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-037-4",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-037-5",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-038-1",
  "Room Type": "Silver Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 106.32,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-038-2",
  "Room Type": "Silver Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 106.32,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-038-3",
  "Room Type": "Silver Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 106.32,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-038-4",
  "Room Type": "Silver Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 106.32,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-038-6",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-039-1",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-039-2",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-039-4",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-039-5",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-039-6",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-040-1",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-040-2",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-040-3",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-040-4",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-041-2",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-041-5",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-043-4",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-043-5",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-043-6",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-044-5",
  "Room Type": "Platinum Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 118.18,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-049-4",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-049-5",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-050-6",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 99.71,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-052-1",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-052-2",
  "Room Type": "Gold Room",
  "Term Session": "*2024-25 NOMS STEP NU 43wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 109.72,
  "Start Date": "14/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Newcastle",
  "Asset": "Stephenson House",
  "Name": "Step-057-4",
  "Room Type": "Gold Room",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 138,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E312C",
  "Room Type": "Copper En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E613F",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "*2024-25 STER UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 271,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E613F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E816A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E816B",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E816D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E816E",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E816F",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-E817F",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N504E",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N603E",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N603E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N604B",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N604B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N604C",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N604C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N604D",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N604D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 284,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N702A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N703A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N703C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N704A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N708C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N708E",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N708F",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N803A",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N803C",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N803D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-N808D",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S419A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419A",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S419B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419B",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S419C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419C",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S419D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419D",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S419E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419E",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S419F",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419F",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S421",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 328,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S422A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422A",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S422B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422B",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S422D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422D",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S422E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422E",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S422F",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 278,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422F",
  "Term Session": "2024-25 44wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 44,
  "Rate": 281,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S523A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER CSSD 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 275,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S523C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER CSSD 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 275,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-S523D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 STER CSSD 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 275,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S824B",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Booked",
  "Length (Wks)": 44,
  "Rate": 289,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W319",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W322",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W424",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W427",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W625",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 325,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W825",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W829",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W830",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W831",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W832",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W833",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W955",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W957",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W958",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W959",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W960",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W961",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W962",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W963",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W964",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 432,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W965",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W966",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W967",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 333,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Sterling Court",
  "Name": "Ster-W968",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 332,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Blake-07A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 254,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Blake-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 254,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Blake-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 254,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Blake-07D",
  "Room Type": "Platinum Mini Studio",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 282,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Bronte-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 245,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Browning-04C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Browning-04D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Browning-04E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Browning-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Browning-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 250,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Browning-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 40wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 40,
  "Rate": 250,
  "Start Date": "07/09/24",
  "End Date": "14/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Byron-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Byron-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Byron-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Byron-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 241,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Byron-07A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 245,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Byron-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 245,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Carroll-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-07D",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Chaucer-07E",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Drinkwater-04E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Drinkwater-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Drinkwater-07D",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-01F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-07A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-07D",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Elliot-07E",
  "Room Type": "Platinum Mini Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 249,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-01C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-02D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-02E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-04E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-04F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-07D",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Kipling-07E",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-02C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-02D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-07A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Lear-07D",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-01C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-02B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-02C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-02E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-03A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-03D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-07A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Shelley-07D",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-02B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-02C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-02D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-02E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-04B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-04C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-04F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Tennyson-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-02C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-02D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-02E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-03A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-03E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-04B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-04C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-04D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-04E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-04F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-07A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Thomas-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-04C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06F",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 224,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-07A",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-07B",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Salford",
  "Asset": "Student Quarter",
  "Name": "Wordsworth-07C",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 227,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-004",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-005",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-006",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 250,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-007-A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-007-B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-008",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-009",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-013",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-016",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 226,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-037",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-039",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-040",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-041",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-042",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-045",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-048",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-074",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-076",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 235,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-077",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-078",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-079",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-081",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-084",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-109",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-113",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-115",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-117",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-146",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-147",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-A-148",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-001",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 250,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-017-A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-017-B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-018",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-021",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-024",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 226,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-025",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-026",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 226,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-027",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-029",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-049-A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-049-B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-050",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-051",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 235,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-052",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 235,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-055",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 228,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-056",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-061",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-062",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-063",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-065",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 226,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-085-A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-085-B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-087",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-091",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-096",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 226,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-097",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-099",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-101",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 240,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-121-A",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-121-B",
  "Room Type": "2 Bed Apartment",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 207,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-122",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-127",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-129",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-132",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 226,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-133",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-139",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 226,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-143",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Birmingham",
  "Asset": "Studios 51",
  "Name": "St51-B-144",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 232,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-A",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 238.77,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-B",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 238.77,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-D",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 238.77,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-E",
  "Room Type": "Bronze Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 238.77,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-A",
  "Room Type": "Copper Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 231.76,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-B",
  "Room Type": "Copper Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 231.76,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-C",
  "Room Type": "Copper Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 231.76,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-D",
  "Room Type": "Copper Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 231.76,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-E",
  "Room Type": "Copper Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 231.76,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-F",
  "Room Type": "Copper Room",
  "Term Session": "*2024-25 ARCA UCL 51wks 14/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 231.76,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-F",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-22-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 236,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-31-C",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-35-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-35-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-35-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-A",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-38-E",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-39-A",
  "Room Type": "Platinum Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-39-C",
  "Room Type": "Platinum Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-40-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-43-D",
  "Room Type": "Silver Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 280,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-44-A",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-44-B",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-44-C",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-45-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-45-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-45-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-46-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-49-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-49-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-A",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-B",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-C",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-D",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-E",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-52-C",
  "Room Type": "Platinum Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-53-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-A",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-A",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-A",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-A",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-B",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-C",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-E",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-61-A",
  "Room Type": "Platinum Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-61-B",
  "Room Type": "Platinum Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-61-C",
  "Room Type": "Platinum Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-63-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-63-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-63-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-A",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-B",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-65-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-66-C",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-66-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-66-F",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-67-D",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-67-E",
  "Room Type": "Copper Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 266,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-68-B",
  "Room Type": "Gold Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-69-E",
  "Room Type": "Bronze Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 274,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-70-A",
  "Room Type": "Platinum Room",
  "Term Session": "2024-25 41wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 41,
  "Rate": 308,
  "Start Date": "14/09/24",
  "End Date": "28/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-12",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-14",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-5",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-7",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-17",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-73",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-74A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-74D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-25",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-31A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-31D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-31E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-32",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-34",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-79C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-79F",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-80",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 311,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-81B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-81E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-84D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-84E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-85A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36F",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-39A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-39B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-39C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-43",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 341,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-46A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-46B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-46C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "York",
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS BRIC YSJ 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 190,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-001",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-002.1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-002.2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-003",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-004",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-005.1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-005.2",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-005.3",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-005.4",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-005.5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-007",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 419,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-008",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-009",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-010",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-011",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-012",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-013",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-014",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-015.1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-015.3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-016",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-017.1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-017.3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-017.5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-017.6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-019",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-023",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-025",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-026.1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-026.5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-028",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 419,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-029",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-032",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-033",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-034.5",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-035",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-036",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-037",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-038",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-039",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-040",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-041",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-043.1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-043.2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-043.3",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-043.4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-043.5",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-043.6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-043.7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-044.1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-044.2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-052",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 362,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-055.1",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 290,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-056",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 419,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-057",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 419,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-058",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-059",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-060",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-062",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-065",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-066",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-067",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-068",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-069",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-070",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-071",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-073.2",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-073.4",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-073.6",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-073.7",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-086",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 419,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-087",
  "Room Type": "Diamond Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 419,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-091",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-092",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-093.1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-099",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 389,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-103.1",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 304,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-121",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 373,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-128",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-133",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-140",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-141",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-143",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-144",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-145",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-146",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 436,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-147",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-148",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-149",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-150",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-151",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-154",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-155",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-156",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-158",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-159",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-160",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-161",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-164",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-165",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-166",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-167",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 378,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-169",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-171",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-174",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-175",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-178",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-179",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-180",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-181",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-182",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 403,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-183",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-184",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Edinburgh ",
  "Asset": "The Mont",
  "Name": "Mont-185",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-A24",
  "Room Type": "Silver Studio Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 314,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-A25-E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-A25-F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 263,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B11",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 387,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B12",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 360,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B21",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 398,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B22",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 370,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B23-B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B23-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B24-A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B24-C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-B24-D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-BG1-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-BG2",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 387,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-BL1-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-BL1-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-BL1-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-BL1-D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-C12-C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 276,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-CG1-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-CG1-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-CG1-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-CL1-A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-CL1-B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-CL1-C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-CL1-E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 270,
  "Start Date": "21/09/24",
  "End Date": "26/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D1-12",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 305,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D1-23",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 305,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D2-02",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D2-04",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 345,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D2-06",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D2-09",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D2-10",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-D2-11",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 289,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-01",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-02",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-03",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-05",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 305,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-06",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-07",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-09",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-11",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-14",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-18",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-21",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-22",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-23",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-24",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DG-25",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 305,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DL-01",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DL-04",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 305,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DL-07",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DL-12",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 305,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-DL-15",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-EL-02",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Egham",
  "Asset": "The Pad",
  "Name": "PadE-EL-04",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 21/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 274,
  "Start Date": "21/09/24",
  "End Date": "13/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A004A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A016F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 382,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A024A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 TUFN UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349.99,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A024B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 TUFN UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349.99,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A024C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 TUFN UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349.99,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A024D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 TUFN UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349.99,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A024E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "*2024-25 TUFN UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 349.99,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A024F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 TUFN UCL 51wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 374.97,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A030D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A032E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A032F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 382,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A038B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A038D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A039A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A039B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A039D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A039E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A040A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A040B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A040D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A040E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A042A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A046E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A047A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A047B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A047D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A047E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A051A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A051C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A051E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A055B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A055C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A055D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A055E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A055F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A056A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A056B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A056C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A056D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A056F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 382,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A058A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A058B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A058C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A058D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A058E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A058F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A062E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A062F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A063A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A063B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A063C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A063E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A063F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A064A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A064B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A064C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A064D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A064E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A067C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A067D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A067E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A068A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A068B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A068D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A068E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-A068F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 382,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-B107",
  "Room Type": "Bronze Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 421,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C002B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C002D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C002E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 382,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C004A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C004B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C004C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 382,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C004E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 382,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C004F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C005B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-C005E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-D003A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-D003B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-E002A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-E002B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-E002C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 390,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-E002E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-E004C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-F001A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 424,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-F001B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 424,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-F002A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 424,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-F002B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 424,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G001A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G001B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G003B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G003C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G004A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G004B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G004C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G004D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G004E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G004F",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G005B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G005C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G006A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G006B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G006C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G006E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G006F",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 TUFN UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 356.55,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G007B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G007C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-G009D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 365,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-Gatehouse-A",
  "Room Type": "Gatehouse Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 469,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-Gatehouse-B",
  "Room Type": "Gatehouse Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 469,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-Gatehouse-C",
  "Room Type": "Gatehouse Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 469,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-H005A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 365,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-H009B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 404,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I001A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I001B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I001C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I001E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I003B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I003C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I003D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I003E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I003F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I005A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I005B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I005C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I005D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I005E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I005F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I007A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I007C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I007D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I007F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I009A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 393,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I009B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I009C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I009D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I009E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I009F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I011B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I011C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Tufnell House",
  "Name": "Tufn-I013C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 358,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bangor",
  "Asset": "Ty Willis House",
  "Name": "TyWi-A18e",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 155,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bangor",
  "Asset": "Ty Willis House",
  "Name": "TyWi-E03e",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 143,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bangor",
  "Asset": "Ty Willis House",
  "Name": "TyWi-F14c",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 133,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bangor",
  "Asset": "Ty Willis House",
  "Name": "TyWi-F30e",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 135,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-006.4",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 412,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-077",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 510,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-152",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-157",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-158",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-159",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-165",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-170",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-172",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-173",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-174",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-175",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-176",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-182.1",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 415,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-182.5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 415,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-183",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-186",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-187",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-188",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-196",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-197",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-198",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-199",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-200",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-201",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-202",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-204",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-209",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-211",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-217",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-220.3",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 415,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-220.4",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 415,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-220.5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 415,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-222",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-225",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-227",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-228",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-230",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-247.5",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 415,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-251",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 521,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-273.4",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 415,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-284.1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-284.2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-284.3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-284.4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-285.2",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-285.3",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-285.4",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-285.5",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-286",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 537,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-289",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 595,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-297.1",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-297.2",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-297.5",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-308.1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-308.2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-308.3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-308.4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-308.6",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-308.7",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-318",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 595,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-320.1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-320.2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-320.3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-320.4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-320.5",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-320.6",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-320.7",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-321.1",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-321.2",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-321.3",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-321.4",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-321.5",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-324",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 595,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-325",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 595,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-330",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 595,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-332.1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-332.2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-332.3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-332.4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-332.6",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-332.7",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-333.1",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-333.2",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-333.3",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-333.4",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-333.5",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-334",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 537,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-342",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 595,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-344.1",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-344.2",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-344.3",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-344.4",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-344.5",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 452,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-345.4",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-345.5",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 457,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-346",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 537,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-354",
  "Room Type": "Gold Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 595,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-356.1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-356.2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-356.3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-356.4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-356.5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-356.6",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-356.7",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-357.1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-357.2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-357.3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-357.4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-357.5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-360",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-361",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-364",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-366",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-368.1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-368.2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-368.3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-368.4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-368.5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-368.6",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-368.7",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-369.1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-369.2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-369.3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-369.4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-369.5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-370",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 552,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-371",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-372",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-373",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-374",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-375",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-378",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-380.1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-380.2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-380.3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-380.4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-380.5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-380.6",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-380.7",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-381.1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-381.2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-381.3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-381.4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-381.5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-382",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 552,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-383",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-384",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-385",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-388",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-389",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-390",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-392.1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-392.2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-392.3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-392.4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-392.5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-392.6",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-392.7",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-393.1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-393.2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-393.3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-393.4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-393.5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-394",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 552,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-395",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-396",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-397",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-399",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-400",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-402",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-404.1",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-404.2",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-404.3",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-404.4",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-404.5",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-404.6",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-404.7",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 462,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-405.1",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-405.2",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-405.3",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-405.4",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-405.5",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 467,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-406",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 552,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-408",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-409",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-411",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-413",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-414",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 603,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-416.1",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-416.2",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-416.3",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-416.4",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-416.5",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-416.6",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-416.7",
  "Room Type": "Penthouse En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 472,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-417.2",
  "Room Type": "Penthouse En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 477,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-417.3",
  "Room Type": "Penthouse En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 477,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-417.4",
  "Room Type": "Penthouse En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 477,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-417.5",
  "Room Type": "Penthouse En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 477,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-418",
  "Room Type": "Penthouse Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 567,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-419",
  "Room Type": "Penthouse Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 628,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-420",
  "Room Type": "Penthouse Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 628,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-421",
  "Room Type": "Penthouse Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 628,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-422",
  "Room Type": "Penthouse Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 628,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-423",
  "Room Type": "Penthouse Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 628,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-424",
  "Room Type": "Penthouse Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 628,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Vega",
  "Name": "Vega-425",
  "Room Type": "Penthouse Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 628,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-007",
  "Room Type": "Bronze Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 371,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-020",
  "Room Type": "Gold Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 425,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-030",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 425,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-031",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 425,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Closed",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-102",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-104",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-106",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-108",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-114",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-116",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-118",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-120",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-124",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-126",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-128",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-131",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 398,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-132",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-134",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-142",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-146",
  "Room Type": "Platinum Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 441,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-218",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-220",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-222",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-226",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-228",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-241",
  "Room Type": "Diamond Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 484,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-242",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-244",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-246",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-306",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-312",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-314",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-316",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-318",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-322",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-324",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-326",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-328",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-334",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-340",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-342",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-344",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Bristol",
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-346",
  "Room Type": "Platinum Studio Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 445,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-101A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-101B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-102A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-102B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-102C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-103A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-104B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-105B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-105D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-201A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-203A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-203B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-203C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-204A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-204B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-204C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-206A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-206B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-206C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-403C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-404A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-404B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-404C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-501A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-501B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-601A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-602A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-603A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-603B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-603C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-604A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-604C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-803B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B1-803C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-204A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-204B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-204C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-205D",
  "Room Type": "Diamond En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 216,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-208",
  "Room Type": "Silver Studio",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 216,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-212B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 208,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-312A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-312A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-312B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-312B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 174,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 174,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 174,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 174,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 174,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 174,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405D",
  "Room Type": "Diamond En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 204,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 181,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-412A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-412A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-412B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-412B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 190,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-504A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-504B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-504C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-505A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 189,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-505B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 189,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-505D",
  "Room Type": "Diamond En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 216,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-603A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-603B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-603C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-604A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-604B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-604C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 186,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-705A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 189,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-705C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 189,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-705D",
  "Room Type": "Diamond En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 216,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-803A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-803B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-803C",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 203,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-812A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 195,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-901B",
  "Room Type": "2 Bed Apartment Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 173,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-906A",
  "Room Type": "Diamond En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 204,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B02A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B02B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B02C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B03A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B03B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B03C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B05A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B05B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B05C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 197,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1004E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 151,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-101A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-101B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-101C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-101D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-101E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-102A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-102B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-102C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-102D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 168,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1104F",
  "Room Type": "Bronze En Suite Premium",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 142,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 39wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 39,
  "Rate": 158,
  "Start Date": "07/09/24",
  "End Date": "07/06/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404F",
  "Room Type": "Bronze En Suite Deluxe",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 145,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 163,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 163,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 163,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 163,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 163,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 163,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 163,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 163,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 163,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 163,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 163,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 163,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1604A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1704C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1704D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1704E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 143,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802A",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802B",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1902D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1902E",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-1902F",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-2002C",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-2002D",
  "Room Type": "Bronze En Suite Plus",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-202B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-202C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-202F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-301A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-301B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-301C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-301D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-301E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-301F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-303A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-303B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-303D",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-303E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-303F",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-304A",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-403C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 141,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-501B",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-603C",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-703E",
  "Room Type": "Copper En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 129,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-801C",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801C",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Available",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-801F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-802E",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-802E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-802E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803A",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803B",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803B",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803D",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803D",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803E",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803E",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803F",
  "Term Session": "2024-25 16wks Starting 07/09/2024 - Semester 1",
  "Session Booked Status": "Booked",
  "Length (Wks)": 16,
  "Rate": 155,
  "Start Date": "07/09/24",
  "End Date": "28/12/24",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 26wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 26,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-803F",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 34wks Starting 04/01/2025 - Semester 2",
  "Session Booked Status": "Available",
  "Length (Wks)": 34,
  "Rate": 155,
  "Start Date": "04/01/25",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Coventry",
  "Asset": "Weaver Place",
  "Name": "Weav-C-804A",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 43wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 43,
  "Rate": 151,
  "Start Date": "07/09/24",
  "End Date": "05/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A05E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A05H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A13E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A14D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A14D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15H",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15H",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02G",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 334,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 334,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02H",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 334,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02H",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 334,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02K",
  "Room Type": "Bronze En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 334,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02K",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 334,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 372,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19C",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19D",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19F",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19G",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19H",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19H",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 405,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 405,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 405,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 405,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 405,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06F",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06G",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C10A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C10D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14H",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15A",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15B",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15C",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15D",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15E",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15F",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15G",
  "Term Session": "2024-25 51wks Starting 07/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C16A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C16D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C17F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C17G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C17H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C18A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 364,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C19B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C19E",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C19G",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 370,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20B",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20D",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20E",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20F",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 379,
  "Start Date": "07/09/24",
  "End Date": "30/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 342.37,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 342.37,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04C",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 342.37,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04D",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 342.37,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04F",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04G",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D08G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D09A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D09H",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D10A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D10C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D10G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D11A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D11C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D11E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13F",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13G",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 44wks Starting 07/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 395,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15F",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15G",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "London",
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15H",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 WILL UCL 44wks 07/09/2024 (BESP)",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 380.5,
  "Start Date": "07/09/24",
  "End Date": "12/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B04B",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 189.66,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B05E",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 189.66,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B09A",
  "Room Type": "Bronze En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 44wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 189.66,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B15A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B15A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B15E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B15E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 272,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19A",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19E",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B20B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B21B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B21C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B21D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 272,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B23A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B23C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B24C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B24C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B24D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B24D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B25D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B26C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B26D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B26E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B27A",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B27D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B27E",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B28A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 272,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C01C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C01D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C02A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C02B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C02D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C05A",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C08C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C08D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C10D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13A",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13B",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13E",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C21D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C21E",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23B",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23B",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23C",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23D",
  "Room Type": "Silver En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 264,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24A",
  "Room Type": "Silver En Suite Plus",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 227.42,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24B",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24C",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24D",
  "Room Type": "Silver En Suite",
  "Term Session": "*2024-25 NOMS WILM MMU 48wks",
  "Session Booked Status": "Available",
  "Length (Wks)": 48,
  "Rate": 223.29,
  "Start Date": "14/09/24",
  "End Date": "16/08/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D02D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 312,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 302,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E06A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E06B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E06C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E08A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E08B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E08C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E10A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E10B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E10C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E14A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E14B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E14C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E18A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E18B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E18C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E22A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 294,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 289,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 289,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 289,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 272,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 294,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 289,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 289,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 44wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 44,
  "Rate": 289,
  "Start Date": "14/09/24",
  "End Date": "19/07/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 272,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04B",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F22C",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F22C",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F22D",
  "Room Type": "Gold En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F22D",
  "Term Session": "2024-25 51wks Starting 14/09/2024 (T4-DL)",
  "Session Booked Status": "Unavailable",
  "Length (Wks)": 51,
  "Rate": 268,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Inactive - Rate Not Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F27A",
  "Room Type": "Gold En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 272,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G11B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G14A",
  "Room Type": "Diamond En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 295,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G14C",
  "Room Type": "Diamond En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 295,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H09A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H09C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H09D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10E",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11C",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11D",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I03A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I03B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I03C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I04A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I04B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I04C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I07A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I07B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I07C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I11A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I11B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I11C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I15A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I19A",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I19B",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I19C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20A",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20B",
  "Room Type": "Platinum En Suite Plus",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 291,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20C",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 {
  "City": "Manchester",
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J20D",
  "Room Type": "Platinum En Suite",
  "Term Session": "2024-25 51wks Starting 14/09/2024",
  "Session Booked Status": "Available",
  "Length (Wks)": 51,
  "Rate": 282,
  "Start Date": "14/09/24",
  "End Date": "06/09/25",
  "Term Active Status": "Active",
  "Closed?": "Open",
  "Current Room Status": "Available"
 },
 null,
//  {
//   "Asset": "Applied filters:\nIs Current is 1\nLeasing Cycle is 2024-25\nIncluded (1) Available (Room Status)\nOcc Cycle Week Number is less than or equal to 54\nIs Current is 1\nAsset is not (Blank) or Not Known\nDebtPortfolio is not Not Assigned\nAgent (Merger Groups) is University Living or University Living Referral\nCurrent Status is not (Blank), Can, NA, or \nLeasing Cycle is not (Blank)\nRoom Booking Type is not (Blank)"
//  }
],
"Sheet1":[
 {
  "Property": "Ty Willis House",
  "City": "Bangor"
 },
 {
  "Property": "Broderick House",
  "City": "Birmingham"
 },
 {
  "Property": "Penworks House",
  "City": "Birmingham"
 },
 {
  "Property": "Studios 51",
  "City": "Birmingham"
 },
 {
  "Property": "Arkwright House",
  "City": "Bradford"
 },
 {
  "Property": "Abacus House",
  "City": "Brighton"
 },
 {
  "Property": "Brighton",
  "City": "Brighton"
 },
 {
  "Property": "Sawmills",
  "City": "Brighton"
 },
 {
  "Property": "Bristol",
  "City": "Bristol"
 },
 {
  "Property": "Water Lane House",
  "City": "Bristol"
 },
 {
  "Property": "Weaver Place",
  "City": "Coventry"
 },
 {
  "Property": "Parker House",
  "City": "Dundee"
 },
 {
  "Property": "Elliott House",
  "City": "Edinburgh"
 },
 {
  "Property": "Grove",
  "City": "Edinburgh"
 },
 {
  "Property": "Fountainbridge",
  "City": "Edinburgh "
 },
 {
  "Property": "The Mont",
  "City": "Edinburgh "
 },
 {
  "Property": "Podium",
  "City": "Egham"
 },
 {
  "Property": "The Pad",
  "City": "Egham"
 },
 {
  "Property": "Elgin Place",
  "City": "Glasgow"
 },
 {
  "Property": "Aspley House",
  "City": "Huddersfield"
 },
 {
  "Property": "Castings",
  "City": "Huddersfield"
 },
 {
  "Property": "Little Aspley House",
  "City": "Huddersfield"
 },
 {
  "Property": "Kingston",
  "City": "Kingston"
 },
 {
  "Property": "Altus House",
  "City": "Leeds"
 },
 {
  "Property": "Leeds",
  "City": "Leeds"
 },
 {
  "Property": "Marsden House",
  "City": "Leeds"
 },
 {
  "Property": "Grosvenor House",
  "City": "Leicester"
 },
 {
  "Property": "Opal Court",
  "City": "Leicester"
 },
 {
  "Property": "Hayes Wharf",
  "City": "Lincoln"
 },
 {
  "Property": "Pavilions",
  "City": "Lincoln"
 },
 {
  "Property": "Great Newton House",
  "City": "Liverpool"
 },
 {
  "Property": "Aldgate",
  "City": "London"
 },
 {
  "Property": "Bankside",
  "City": "London"
 },
 {
  "Property": "Bloomsbury",
  "City": "London"
 },
 {
  "Property": "City",
  "City": "London"
 },
 {
  "Property": "East Court",
  "City": "London"
 },
 {
  "Property": "Flinders House",
  "City": "London"
 },
 {
  "Property": "Hammersmith",
  "City": "London"
 },
 {
  "Property": "Haywood House",
  "City": "London"
 },
 {
  "Property": "Highbury",
  "City": "London"
 },
 {
  "Property": "Hoxton",
  "City": "London"
 },
 {
  "Property": "Magenta House",
  "City": "London"
 },
 {
  "Property": "Paris Gardens",
  "City": "London"
 },
 {
  "Property": "Shoreditch",
  "City": "London"
 },
 {
  "Property": "Sterling Court",
  "City": "London"
 },
 {
  "Property": "The Arcade",
  "City": "London"
 },
 {
  "Property": "Tufnell House",
  "City": "London"
 },
 {
  "Property": "Vega",
  "City": "London"
 },
 {
  "Property": "Will Wyatt Court",
  "City": "London"
 },
 {
  "Property": "Fletcher House",
  "City": "Manchester"
 },
 {
  "Property": "Hollingworth House",
  "City": "Manchester"
 },
 {
  "Property": "Kerria Apartments",
  "City": "Manchester"
 },
 {
  "Property": "Lambert & Fairfield House",
  "City": "Manchester"
 },
 {
  "Property": "Manchester Gardens",
  "City": "Manchester"
 },
 {
  "Property": "Wilmslow Park",
  "City": "Manchester"
 },
 {
  "Property": "Collingwood",
  "City": "Newcastle"
 },
 {
  "Property": "Stephenson House",
  "City": "Newcastle"
 },
 {
  "Property": "Exchange",
  "City": "Nottingham"
 },
 {
  "Property": "Nelson Court",
  "City": "Nottingham"
 },
 {
  "Property": "Newland House",
  "City": "Nottingham"
 },
 {
  "Property": "Newtown House",
  "City": "Nottingham"
 },
 {
  "Property": "Signal Place",
  "City": "Nottingham"
 },
 {
  "Property": "Alice House",
  "City": "Oxford"
 },
 {
  "Property": "Astor House",
  "City": "Plymouth"
 },
 {
  "Property": "Kopa",
  "City": "Preston"
 },
 {
  "Property": "Student Quarter",
  "City": "Salford"
 },
 {
  "Property": "Brocco",
  "City": "Sheffield"
 },
 {
  "Property": "Century Square",
  "City": "Sheffield"
 },
 {
  "Property": "Fenton House",
  "City": "Sheffield"
 },
 {
  "Property": "Knight House",
  "City": "Sheffield"
 },
 {
  "Property": "Steel",
  "City": "Sheffield"
 },
 {
  "Property": "Fiveways House",
  "City": "Wolverhampton"
 },
 {
  "Property": "The Brickworks",
  "City": "York"
 }
],
"Sheet2":[
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-001-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-003-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-003-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-003-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-003-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-004-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-004-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-004-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-004-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-004-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-005-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-005-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-008-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-009-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-009-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-010-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-010-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-010-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-010-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-010-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-011-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-011-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-011-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-011-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-011-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-012-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-012-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-015-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-015-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-015-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-015-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-016-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-018-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-018-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-018-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-019-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-019-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-020-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-020-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-022",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-023-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-023-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-023-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-023-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-024-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-024-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-025",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-026-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-027",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-028",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-029",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-031-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-032-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-034-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-034-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-034-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-034-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-035",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-036-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-036-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-036-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-036-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-038",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-039-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-039-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-039-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-039-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-041",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-042",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-043-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-043-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-043-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-044-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-044-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-044-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-047-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-047-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-047-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-047-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-051-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-051-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-052",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-053",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-054-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-054-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-054-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-056-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-056-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-057-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-057-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-057-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-057-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-063-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-063-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-063-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-064-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-065-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-065-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-065-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-065-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-066",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-068-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-068-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-068-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-068-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-068-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-073-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-073-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-074-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-074-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-074-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-074-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-077-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-077-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-077-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-077-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-081-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-081-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-081-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-081-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-081-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-084",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-086-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-087",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-088",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-090-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-090-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-092-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-092-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-093-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-093-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-093-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-093-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-094-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-094-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-094-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-094-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-094-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-095-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-095-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-096-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-096-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Abacus House",
  "Name": "Abac-096-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1012",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1018",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1020",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 10-1021",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1103",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1119",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 11-1120",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 1-114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1218",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1219",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 12-1220",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1302",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1303",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1304",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1311",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1319",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 13-1320",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1417",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1418",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 14-1420",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 15-1504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 15-1505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 15-1519",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 16-1610",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 16-1618",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 4-415",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 9-916",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aldgate",
  "Name": "Aldg-Floor 9-919",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Alice House"
 },
 {
  "Asset": "Allen Court"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1001C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1001D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1006A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1006B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1006C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1006D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1006E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1007A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1007B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1007C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1007D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1101F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1105E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1201A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1201B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1201C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1201E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1201F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1301C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1301F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1305E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1306A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1306B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1306C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1306D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1306E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1307A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1307B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1307C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1401B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1401C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1401E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1401F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1406A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1406B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1406C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1406D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1406E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1407A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1407B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1407C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1501C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1501F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1505E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1506A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1506B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1506C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1506D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1506E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1507A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1507B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1507C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1604",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1606A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1606B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1606C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1606D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1606E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1607A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1607B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1607C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1706A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1706B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1706C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1706D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1706E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1707A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1806A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1806B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1806C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1806D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1806E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1807A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1807C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1901F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1906A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1906B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1906C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1906D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-1906E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2006A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2006B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2006C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2006D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2006E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2007B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-201C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-201D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-201E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-201F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-202A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-202B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-203B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-203C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-203D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-203E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-204A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-204B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2106A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2106B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2106C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2106D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2106E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2107A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2107B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2107C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2205A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2206A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2206B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2206C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2206D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2206E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2403A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2403D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2403E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2501F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2505A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2506A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2506B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2506C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2506D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2507A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2507B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2507C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2507D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2601A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Access",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2601F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2606D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2607A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2607B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2607C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2707A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2907A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2907B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-2907C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3006A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3006B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3006D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3103",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3105D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3106A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3106B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3106C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3106D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3107A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3107B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3107C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3107D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3201B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3201C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3201D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3201E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3201F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3206D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3207A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3207B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3207C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3207D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3301B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3301C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3301D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3301E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3301F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3307C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3401B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3401C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3401D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3401E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3401F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3406A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3406B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3406C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3406D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3407A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3407B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3407C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3501B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3501C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3501D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3501E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3501F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3601C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3601D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3601E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3601F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3605A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-3605B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-406B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-406D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-406E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-501C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-501D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-501E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-501F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-502",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-503",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-505A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-505B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-505D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-505E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-506A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-506B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-506C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-506D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-506E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-507A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-507B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-507C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-507D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-601B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-601C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-601D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-601E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-601F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-605A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-605E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-606A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-606B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-606C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-606D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-606E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-607A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-607B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-607C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-607D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-701A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-701B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-701C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-701D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-701E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-701F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-705A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-705B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-705E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-706A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-706B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-706C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-706D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-706E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-707A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-707B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-707C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-707D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-801A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-801E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-801F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-805A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-805B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-805C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-805D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-805E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-806A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-806B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-806C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-806D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-806E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-807A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-807B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-807C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-807D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-901F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-905A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-905E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-906A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-906B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-906C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-906D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-906E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-907A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-907B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-907C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Altus House",
  "Name": "AltH-907D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Very Light Touch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A17B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A18B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A18C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A19B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A19D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A20A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A24C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A26A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A27D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A30A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A32A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-A32D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B10E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B17B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B17C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B17D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B17E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B21B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B21C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B21D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B23A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B23B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B24C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-B24D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C08E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C11E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C16E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C20A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C20B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C20C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C20D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C20E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C22A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C22B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C22C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C22D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C23A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C23B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C23C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C23D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C23E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C24D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C24E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C25B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C25C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C25D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C26C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C26D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C28A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C28B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C28C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C28D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-C28E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D05F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D11E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D12F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D13E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D14E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D14F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-D16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-E16E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-F08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G02A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G05F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G07F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G08F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G10F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G11E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G13E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G13F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G16F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G17B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G17D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G18B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G18C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-G20D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Arkwright House",
  "Name": "Arkw-H13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-A15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-B08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-C06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-C07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-D07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-D12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-G13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-G14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-H01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-H02A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-H03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-H05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-H10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-H12E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Aspley House",
  "Name": "Aspl-A-H14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Astor House"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-103",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 1-117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-214",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 2-220",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-301",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-302",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-303",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-304",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-307",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-308",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-310",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-313",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-314",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-315",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 3-319",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-401",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-403",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-408",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-410",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-415",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 4-419",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 5-504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 5-507",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 5-512",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-601",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-603",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-604",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-605",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-610",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-613",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-614",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-615",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 6-616",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-701",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-702",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-705",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-706",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-707",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-708",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-710",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-715",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-716",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-718",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Floor 7-720",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bankside",
  "Name": "Bank-Ground-G10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-010",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-012",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-013A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-013B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-016A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-016B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-017",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-019",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-020",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-118B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-118D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-118E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-118F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-119",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-120",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-218D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-218E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-218F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-218G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-414G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-502",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-511",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bloomsbury",
  "Name": "Bloom-LG12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Briarfields"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1201.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1201.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1307",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1309",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1311",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1404.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1404.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1406",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1407.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-1407.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio (Dda)",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-302",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-304",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-307",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-313",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-314",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-315",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-515",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-615",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-702",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-704",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-705",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-706",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-707",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-710",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-711",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-712",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-713",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-715",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-716",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-804",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-805",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-806",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-811",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-813",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-815",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-816",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-902",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-904",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-905",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-906",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-907",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-912",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-22-913",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1006.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1006.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1009.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1009.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-109.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-109.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-111.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-111.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1110.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1110.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1205.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1205.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1210.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-1210.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-209.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-211.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-211.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-214",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-307",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-308",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-309.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-309.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-315",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-406",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-407",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-408",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-409.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-409.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-410",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-502.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-508",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-509.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-509.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-511.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-511.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-513",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-609.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-609.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-611.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-611.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-613",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-703",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus (Dda)",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-704",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-707",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-708.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-708.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-710.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-710.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-711",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-712",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-713",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-803",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-804",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-805",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-807",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-808.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-808.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-906.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-906.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Brighton",
  "Name": "Brgt-7-909.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-018A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-018B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-018C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-018D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-018E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-018F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-020A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-020B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-020D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-040B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-040D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-041A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-042A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-042C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-043B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-043D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-051",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-065B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-066B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-066C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-066D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-067A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-067B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-078",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-092A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-092B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-092C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-092D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-095",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-164",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-191A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-217D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-229",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-232",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-233",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Bristol",
  "Name": "Bris-239D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A08E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A10E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12aA",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12aD",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12aE",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A16E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A17B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A18D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A18E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A22A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A23D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A24E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A25E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A26B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A26C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A26D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A26E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A27A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A28C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A28D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A29A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A29B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A29D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A29E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A31B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A31C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A31D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-A31E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B12E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Brocco",
  "Name": "Brocco-B19D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-024-",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-025-",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-026A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-053E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-098",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-110A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-123A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-162B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-162D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-162E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-170A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-171C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Broderick House",
  "Name": "Brod-171D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-013-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-017-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-017-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-018-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-020-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-020-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-021-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-022-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-026-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-027-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-027-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-030-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-032-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-032-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-041-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-041-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-041-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-042-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-044-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-044-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-044-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-045-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-045-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-045-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-052-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Castings",
  "Name": "Cast-058-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-001B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-001C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-001D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-001E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-002B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-002C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-003A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-003B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-003C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-003D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-101A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-101B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-101C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-101D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-101E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-102A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-102B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-102C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-102D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-102E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-207A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-207B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-207C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-207D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-207E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-218",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-219A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-219B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-219C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-221",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-222",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-224",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-225",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-301",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-302",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-303",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-304",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-307A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-307B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-307C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-307D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-307E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-308",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-309",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-310",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-311",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-319A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-319B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-319C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-321",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-322",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-325",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-328E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-329",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-401",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-403",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-407E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-408",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-410",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-418",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-421",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-422",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-423",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-424",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-425",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-429A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-429C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-429E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-429F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-501",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-502",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-503",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-507A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-507B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-507C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-507D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-507E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-508",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-509",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-510",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-521",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-525",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-526A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-526C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-526D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-528A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-528B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-529B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-529C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-529D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-529F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-530",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-601",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-602",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-603",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-604",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-605",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-607A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-607B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-607C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-607D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-607E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-608",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-609",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-610",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-614",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-615",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-616",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-617",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-618",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-621",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-622",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-623",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-624",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-625",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-629A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-629B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-629C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-629E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-629F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-630",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-701",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-702",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-704",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-705",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-707",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-708",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-711",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-712",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-713",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-715",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-716A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-716D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-717A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-717B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-719B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-719D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-719E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-719F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-801",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-803A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-804A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-804B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-804C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-804D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-805A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment Upper Flr",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-805B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment Upper Flr",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-901B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-902",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-903",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-904",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Century Square",
  "Name": "Cent-905",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1001",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1010",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1011",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1012",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 10-1016",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1115",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 11-1116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-129A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-129B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-130A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-130B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-131",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-132A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-132B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-133",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-135A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-135B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-136",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-137",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-140",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-144",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-147",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-149",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-150",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-151",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-152",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-153",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-154",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-158",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-159",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-160",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-161",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-162",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-164",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-166",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-167",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-168",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-169",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-174",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-176",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-178",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-180",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-182",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-184",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-185",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-186",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-187",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-188",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 1-189",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze 1 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-220",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-221",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-222",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-224",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-225",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-226",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze 1 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-227",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-228",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze 1 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-230A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-230B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-231A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-231B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-232",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-233A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-233B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-236A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-236B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-239",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-244A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-244B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-245",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-250",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-251",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-252",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-260",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-268",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-271",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-272",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-274",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-278",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-279",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-280",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-281",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-282",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-283",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-285",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-287",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-289",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-290",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-291",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-293",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 2-294",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 3-317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver 1 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 3-358",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 3-389",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-403",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-406",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-407",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-408",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-410",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-415",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-432",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-433",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-434A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-435",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-436",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-437",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-439",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-440",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-441",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-443",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-445",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-459",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-468",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-470",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-471",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-472",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-475",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-476",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-477",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-481",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 4-483",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-502",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-508",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-510",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-511",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-512",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-513",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-514",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-516",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-529",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-530",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-532",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-534",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-535",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-536A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-537A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-539",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-545",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-549",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-550",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-551",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-552",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 5-572",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-601",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-602",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-604",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-606",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-610",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-612",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-613",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-614",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-617",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold 1 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-618A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-618B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-619A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-619B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-621A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-621B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-622",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-623",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-624A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-624B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-626",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-627",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-629",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-630",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-631A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-631B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-634",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-635",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-636",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-637",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-638",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-639",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-640",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-643",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-644",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-645",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-646",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-647",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 6-648",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-701",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-714",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-722",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-728",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-729",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-734",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-735",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-736",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-737",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-740",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 7-741",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-801",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-803",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-804",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-805",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-806",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-808",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-810",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-813",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-816",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-822",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-823",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-825",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-826",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-827",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-828",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 8-829",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 9-901",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 9-902",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Floor 9-912",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G13",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G15",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G18B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G19",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G24",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G61",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G63",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G65",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Ground-G69",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L13",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L15",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L16",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L17",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L20",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L36",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L52",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L53",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L54",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L55",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L56",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "City",
  "Name": "City-Lower Ground-L57",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-006C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-202D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-207B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-306D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-306F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Collingwood",
  "Name": "Coll-308F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Daisy Bank"
 },
 {
  "Asset": "Dorchester House"
 },
 {
  "Asset": "East Court",
  "Name": "East-E002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E010",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E011",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E012",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E016",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E017",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E018",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E019",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E020",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E021",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E103",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E119",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E218",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E219",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E220",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E221",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E301",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E302",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E303",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E304",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E308",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E309",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E311",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E401",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-E411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N115",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N119",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N120",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N121",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N122",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N123",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N124",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N125",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N126",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N127",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N214",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N218",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N219",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N221",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N222",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N223",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N224",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N225",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N226",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N228",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N231",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N232",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N233",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N234",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N235",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N236",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N237",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N238",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N239",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N240",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N241",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N242",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N243",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N309",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N322",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N324",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N325",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N326",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N329",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N330",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N332",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N334",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N337",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N403",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N408",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N410",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N415",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N416",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N417",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N419",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N422",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N423",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N425",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N426",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N428",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N429",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N430",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N431",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N433",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N434",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N436",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N437",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N438",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N506",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N507",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N508",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N509",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N512",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N514",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N516",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N518",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N522",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N526",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N528",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N529",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N530",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N602",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N604",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N608",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N614",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N622",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N628",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N629",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-N630",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-W005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-W006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-W007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-W008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-W009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "East Court",
  "Name": "East-W013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W103",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W105A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W105B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W105C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W105D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W120",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W126",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W214",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W220",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W221",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W222",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W223",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W224",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W225",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W226",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W227",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W228",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W229",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W230",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W232",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W233",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W234",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W235",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W236",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W237",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W238",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W302",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W303",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W307",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W310",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W311",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W313",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W315",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W320",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W321",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W323",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W324",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W325",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W327",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W328",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W329",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W330",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W332",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W333",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W334",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W335",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W336",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W337",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W339",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "East Court",
  "Name": "East-W401",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W403",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W406",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W407",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W415",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W416",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W417",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W421",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W423",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W424",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W425",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W426",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W427",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W428",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W429",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W430",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W432",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W433",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W434",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W435",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W436",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W437",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W438",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W439",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W502",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W503",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W506",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W507",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W508",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W511",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W513",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W514",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W516",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W517",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W521",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W522",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W523",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W525",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W526",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W528",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W529",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W530",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W532",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W533",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W535",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W536",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W537",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W539",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W604",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W606",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W608",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W609",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W611",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W613",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W627",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W629",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W633",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W634",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W635",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W637",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W639",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W704",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W706",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W707",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W708",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W710",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W711",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W714",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W715",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W721",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W723",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W724",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W725",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W727",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W728",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W729",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W739",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W803",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W804",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W805",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W809",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W811",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W815",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W816",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "East Court",
  "Name": "East-W818",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0102F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0416",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0617",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0716",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-0905",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elgin Place",
  "Name": "Elgin-1019",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-35",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-38",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-40",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-42",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-43",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 1-53",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-56",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "0",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-57",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-64",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-71",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 2-75",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 3-79",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 3-81",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Floor 3-88",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "#N/A"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-138A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-21",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "0",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-23",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-24",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-25",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "0",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-26",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-28",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "0",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-29",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-31",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-32",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-33",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-34-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-34-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Ground-34-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-13",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-137B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-14",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-15",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-16",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-17",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-18",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-19",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Elliott House",
  "Name": "EllH-Lower Ground-9",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-13R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-13R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-13R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-13R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-24R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver Duplex Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-31R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver Duplex Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-32R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-32R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-32R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-32R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-33R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver Duplex Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-33R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver Duplex Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-33R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver Duplex Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-34R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-34R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-34R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-34R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-34R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-41R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-41R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-41R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-41R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-42R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-42R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-42R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-42R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-42R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-44R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-44R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-44R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-44R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-44R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-51R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-51R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-51R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-52R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-52R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-52R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-52R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-52R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-53R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-53R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-53R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-53R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-53R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-54R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-54R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-54R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-54R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-61R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-61R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-61R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-61R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-61R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-62R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-62R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-62R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-62R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-62R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-63R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-63R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-63R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-63R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-64R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-64R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-64R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-64R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-65R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-65R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-71R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-71R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-71R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-71R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-71R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-72R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-72R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-72R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-72R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-73R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-73R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-73R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-73R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-74R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-75R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-75R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-75R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A01R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A01R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A01R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A02R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A02R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A02R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A02R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A02R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-A02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B01R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B01R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B01R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B01R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-B02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-C01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-C02R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-C02R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-C02R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-C02R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-C02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D01R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D01R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D01R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D01R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D01R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D02R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D02R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D02R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-D02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E01R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E01R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E01R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E01R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E01R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-E02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F01R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F01R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F01R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F01R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F01R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F01R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-F02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G01R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G01R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G02R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G02R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G02R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G02R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G02R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-G02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H01R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Exchange",
  "Name": "Exch-H02R8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A18D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A22A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-A22C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B10B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B17A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B19B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-B20B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-C08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-C11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-C13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-D02A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-D03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-D07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-D08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-D08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-D11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-D14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-E03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-E03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-E04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-F16",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-G09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-G09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-G11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-H03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-H08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-H12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-H12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-I04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-I04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-I04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-I10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-I13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-I17B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J14",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J16",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J19",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J20",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J21",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J22",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J23",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J24",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J25",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J26",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J27",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J30",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J31",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J32",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J33",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J34",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fenton House",
  "Name": "Fent-J35",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-A02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-A06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-B08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-C01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-C01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-C01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-C08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-D03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-D05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-D07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-D15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-D15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-F01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-F03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-F06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-F07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-F08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fiveways House",
  "Name": "Five-G06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-D1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum Duplex 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-D2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum Duplex 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-F1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-F2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L01-G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-D2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum Duplex 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-F1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-F2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L02-G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L03-D1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum Duplex 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L03-F1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L03-F2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Fletcher House",
  "Name": "Flet-L0G-A2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-023",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-031",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-035",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-043",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-045",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-050",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-051",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-053",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-054",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-061",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-062",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-063",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-064",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-065",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-070",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-071",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-072",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-078",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-080",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-081",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-083",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-084",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-092",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-099",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-100",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-102",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-103",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-108",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-109",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-110",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-118",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-119",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-120",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-126",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-127",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-128",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-131",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-136",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-138",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "Studio",
  "Room Type": "Medium Studio Rave M",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-162",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-195",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-196",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-198",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "Flinders House",
  "Name": "Flin-238",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Accretive"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-1-001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-1-001C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-1-001E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-001",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-010",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-011E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-012E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-013C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-016",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-017",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-019",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-022",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-023",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-031B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-035",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-040",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-042",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-043",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-044",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-046E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-048E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-054",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-064E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-065E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-066B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-066E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-067D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-068",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-069",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-070",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-071",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-072",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-081",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-082E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-083E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-084E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-085D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-086",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-089",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-091",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-097",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-100E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-101A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-101D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-101E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-118E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-125C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-114-LFA",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-001D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-002C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-002D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-004A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-004B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-004D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-005E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-007A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-007B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-007C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-009B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-009C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-009E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-010B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-011E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-012D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-012E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-014E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-015B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-015C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-015E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-016A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-018E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-019A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-020E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-021C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Fountainbridge",
  "Name": "Fountain-3-021D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-A04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-A05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-A10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-A10B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-A17A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-A17E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B19B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B19D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B19E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B20A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B20B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B20C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B20D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B20E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B22B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B22C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B22D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B22E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B23A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B23B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B23E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B24A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B24B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B24C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B24D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B24E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B25B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B26A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B26C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B26D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B26E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B27A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B27B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B27C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B27D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B27E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B31D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B31E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-B32E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C37A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C37C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C37D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C37E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C38A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C38B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C38C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C38D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C38E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C43A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C43C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C43D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C43E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C44E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C45A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C45B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C45D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C45E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C46B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C4848",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold 1 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C49A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C49B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C49C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C49D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C49E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C50A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C51A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C51B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C51C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C51D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-C51E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D53B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D54A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D54B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D54C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D54D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D54E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D55A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D55B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D55C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D55D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D55E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D65A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D65B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D65C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D65D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D65E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Great Newton House",
  "Name": "Grea-D66E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-C01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-C05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E07F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E12F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E14E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E18B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E18C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E18D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grosvenor House",
  "Name": "Gros-E18F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-69-201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-69-407",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-71-001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-71-001D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-71-001E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-71-002B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-71-002C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-71-002D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-71-002E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-001B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-001C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-001D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-001E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-001F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-002A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-002B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-002C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-002D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-002E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-003A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-003B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-003C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-003D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-003E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-003F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-004D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-005A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-005B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-005D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-005E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-005F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-007A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-75-007B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-001B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-001C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-001D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-004A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-004B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-004C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-004D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-004E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-101B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-305A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Grove",
  "Name": "Grove-77-405E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-B403",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C128",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C214",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C334",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C336",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C338",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C403",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C435",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C520",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C602",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C606",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C607",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C608",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C609",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C611",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C615",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C616",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C620",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C621",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C622",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C623",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C624",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C625",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C627",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C628",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C629",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C631",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C633",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C634",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C635",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C636",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C639",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C640",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C702",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C706",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C719",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C722",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C724",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C725",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C726",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C727",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C728",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C729",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C730",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C732",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C733",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C734",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C736",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C737",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C738",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C739",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C817",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Crtyrd Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C823",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C825",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C826",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C827",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C828",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C833",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C834",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C835",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C837",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C839",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C901",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Cityview Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C902",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Cityview Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C904",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C905",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus Large",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C908",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Cityview Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C910",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C911",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C913",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C915",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C916",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C917",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C918",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C919",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C920",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C921",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Cityview Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C922",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Cityview Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C923",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Cityview Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C924",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Cityview Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C927",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Hammersmith",
  "Name": "Hamm-C928",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25"
 },
 {
  "Asset": "Havannah House"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-A11-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-A11-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-A18-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-A18-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-B02-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C01-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C03-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C04-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C05-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C06-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-C07-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-D08-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-D08-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E01-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E02-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E03-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E03-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E04-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E05-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E05-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E06-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E06-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E06-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E07-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Hayes Wharf",
  "Name": "Haye-E08-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Haywood House",
  "Name": "Hayw-310",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Haywood House",
  "Name": "Hayw-318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Haywood House",
  "Name": "Hayw-417",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Haywood House",
  "Name": "Hayw-605C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Haywood House",
  "Name": "Hayw-606A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Haywood House",
  "Name": "Hayw-608A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Haywood House",
  "Name": "Hayw-703",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-115",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-119",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-156",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-157",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-160",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-161",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-162",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-163",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-164",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-165",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-168",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-169",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-170",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-171",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-173",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-174",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-175",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-176",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-178",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-179",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 1-180",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-219",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-222",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-231",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-232",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-234",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-256",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-257",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-258",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 2-264",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 3-315",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 3-317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 3-319",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 3-332",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 3-342",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 3-357",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-422",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-432",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-435",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-440",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-443",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-455",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-469",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-470",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-472",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-476",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-477",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-478",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 4-480",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-506",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-519",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-522",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-532",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-545",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-556",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-559",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-568",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-569",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Highbury",
  "Name": "High-Floor 5-575",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-6A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-6B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L01-8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-13",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L02-15",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-17",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L03-18",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum 1 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L04-21A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L04-21B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-1A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Duplex",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-1B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Duplex",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-2B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Duplex",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-3A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Duplex",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hollingworth House",
  "Name": "Holl-L0G-3C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Duplex",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-023",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-025",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-026",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-027",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-030",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-031",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-032",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-033",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-035",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-036",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-037",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-038",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-039",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-040",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-041",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-043",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-044",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-045",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-046",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-047",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-048",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-049",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-051",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-052",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-053",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-055",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-056",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-057",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-058",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-059",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-060",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-061",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-063",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-064",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-065",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-066",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-067",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-068",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-069",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-070",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-089",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-089A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-090",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-094",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-096",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-097",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-098",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-099",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-103",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-132",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-133",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-135",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-138",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-140",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-141",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-142",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-143",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-167",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-169",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-171",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-175",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-179",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-192",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-193",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-194",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-196",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-197",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-198",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-199",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-200",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Hoxton",
  "Name": "Hoxt-220",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K21A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K37B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K38A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K38B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K45A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K45B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K46A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K46B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K53A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Penthouse 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K54A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Penthouse 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kerria Apartments",
  "Name": "Kerr-K54B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Penthouse 2 Bed",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-102D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-103B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-103C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-103D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-103E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-103F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-202A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-202B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-202C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-202D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-202F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-203B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-203D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-203E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-203F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-213D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-213F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-213G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-302A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-302B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-302C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-302D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-303D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-402A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-402B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-402E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-413D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-413F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-413G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-503B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-503C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-503D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-503F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-513D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Kingston",
  "Name": "King-513F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio (Accessible)",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0103D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "5-Bed Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0103E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "5-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0104C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "3-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0105C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "3-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0202A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0202B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0202C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0203C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "5-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0203E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "5-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0204B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "3-Bed Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0204C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "3-Bed Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0204D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "3-Bed Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0302",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0304A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "5-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0304E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "5-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0305B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "3-Bed Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0305C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "3-Bed Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0305D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "3-Bed Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0403A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0403B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0403C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0406A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "4-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0503A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0504E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "5-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0506A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "4-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0506C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "4-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0506D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "4-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0507A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "3-Bed Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0603A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0603B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0603C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0603D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0603E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0603F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0604A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0604B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0604C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0604D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0604E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0604F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0803A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0803B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0803C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0803D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0803F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0804A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0804B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0804C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0804D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0804E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0804F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0903A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0903B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0903F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0904A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0904B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0904E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-0904F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1003A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1003B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1003E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1003F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1004A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1004B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1004D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1004E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1004F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1103A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1103B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1103F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1104A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1104B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1104F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1203A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1203B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1203D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1203E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1203F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1204A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1204B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1204D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1204E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1204F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1303A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1303B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1303C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1303D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1303E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1304A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1304B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1304C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1304D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1304E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1304F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1608",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Knight House",
  "Name": "KniH-1614",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-004D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-004E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-006E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-007A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-018B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-018C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-019B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-020D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-022D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-023A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-023C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-023E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-024B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-024C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-024D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-025A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-026A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-026B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-026C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-026E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-026F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-029A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-029F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-035C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-037F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-038C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-039B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-039C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-044B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-044C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-044D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-045C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-047A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-047B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-047D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-047E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-049A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-049B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-051D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-051F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-052B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-052C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-052E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-053D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-055A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-055C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-055D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-057C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-061A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-061C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-061D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-063A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-063D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-065A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-065C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-065D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-065E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-070A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-070F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-071A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-071D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-073A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-073B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-073D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-074D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-076D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-085A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-085C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-085D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-094A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-094C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-094D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-095A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-095B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-095D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-096A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-103A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-103B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-103C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-111A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-111D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-112A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-112B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Kopa",
  "Name": "Kopa-112D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-FD105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-LB603",
  "Room Booking Type": "Direct Let",
  "Room Profile": "9 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-LB703",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Lambert & Fairfield House",
  "Name": "Lamb-LC608",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-027A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-027D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-029D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-030A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-030C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-048A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-048B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-048C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-048D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-051B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-052A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-052B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-052C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-052D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-052E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-053A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-053B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-053C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-054A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-054B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-054C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-054D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-057A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-058A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-058B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-058C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-058D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-059C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-059D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-060A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-060B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-060C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-060D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-063A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-063C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-064A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-064B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-064C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-064D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-064E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-067B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-068A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-068C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-068D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-069B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-069C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-069D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-069E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-070A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-070B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-071B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-071C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-071D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-071E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-072C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-072D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-072E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-076E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-077B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-077C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-077D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-078A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-078B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-078C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-078D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-078E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-080A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-080B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-080C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-080D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-086D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-086E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-087A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-087B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-087D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-088B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-088C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-088D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-088E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-089D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-090A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-090B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-090C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-090D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-090E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-091A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-092A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-092B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-092C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-092D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-092E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-093A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-093B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-093C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-093D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-093E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-094A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-094B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-094C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-094D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-094E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-096A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-096B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-096C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-096D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-096E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-097C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-097D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-098A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-098B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-098C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-098D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-099B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-099D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-100A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-100B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-100C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-100D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-100E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-101A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-101B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-101C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-101D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-102A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-102B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-102C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-102D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-102E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-103A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-103B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-103C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-103D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-104A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-104C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-104D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-104E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-105A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-105B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-105C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-105D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-105E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-106A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-106B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-106C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-106D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-106E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-108A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-108B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-108C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-108D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-108E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-109A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-109B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-109C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-109D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-110A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-110B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-110C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-110D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-110E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-111A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-111B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-111C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-111D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-111E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-112A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-112B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-112C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-112D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-112E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-113A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-113B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-113C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-113D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-113E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-113F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-114A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-114B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-114C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-114D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-114E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-114F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-115A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-115B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-115C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-115D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-116C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-118C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-119A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-119B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-119C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-119D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-119E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-120A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-122A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-122B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-122C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-122E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-122F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-123A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-123B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-123C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-123D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-123E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-123F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-129A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-129D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-130A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-130D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-130E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-131A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-131B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-131C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-131D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-131E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-132A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-132B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-132C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-132D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-133A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-133B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-133C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-133D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-133E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-133F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-134A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-134C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-134D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-137A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-137B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-137C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-137D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-138A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-138B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-138C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-138D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-138E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-138F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-139A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-139D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-139E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-140A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-140B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-140C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-140D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-141A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-141B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-141C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-141D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-141E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-141F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-142A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-142B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-142D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-143B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-145A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-145C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-145D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-146B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-146C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-146D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-146E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-146F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-147A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-147B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-147C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-147D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-147E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-148A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-148B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-148C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-148D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-149A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-149B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-149C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-149D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-149E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-149F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-150A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-150B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-150C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-150D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-151A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-151B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-151C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-153A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-153B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-153C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-153D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-154A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-154B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-154C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-154D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-154E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-154F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-155A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-155B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-155D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-155E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-156A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-156B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-156C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-156D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-157A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-157B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-157C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-157D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-157E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-157F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-158A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-158C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-158D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-159A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-159B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-159C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR13",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR14",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR18",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Leeds",
  "Name": "Leed-GR19",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-B04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-C13E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-E03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F12E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-F15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Little Aspley House",
  "Name": "Aspl-LA-G08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Magenta House"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BA-11-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BA-12-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BA-12-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-02-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-07-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-08-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Brotherton-BB-08-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-07-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-07-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-08-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KB-08-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Kilburn-KC-12-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PA-07-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Phillips-PB-10-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-05-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-07-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-10-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-11-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Manchester Gardens",
  "Name": "ManG-Williams-WA-12-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-01-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-02-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-02-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-02-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-05--",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-06-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-07-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-08-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-09-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-10-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-11-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-11-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-11-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-12-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-13-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-16-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-17-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-18-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-19-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-20-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-21-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-22-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-23-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-24-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-25-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-25-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-26-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-27-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-28-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-29-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-31-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-32-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-33-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-34-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-36-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-37-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-38-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-38-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-38-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-39-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-40-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-40-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-40-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-41-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-42-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-43-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-44-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-45-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-46-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-47-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-47-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-47-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-48-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-48-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-48-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-49-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-50-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-51-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-52-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-53-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-54-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-55-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-55-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-56-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-56-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-56-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-57-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-58-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-59-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-60-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-60-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-60-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-61-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-62-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-63-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-64-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-65-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-65-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-65-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-66-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-67-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-68-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-69-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-70-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-71-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-71-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-72-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-73-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-74-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-75-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-76-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-77-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-77-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-77-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-78-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-79-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-79-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Marsden House",
  "Name": "Mars-BB-79-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Needham Court"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-119",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-121",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-122-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-124-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-124-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-124-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-125",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-126",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-222-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-222-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-222-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-222-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-223-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-223-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-223-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-223-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-224-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-224-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-224-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-225",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-226",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-322-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-325",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-326",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-327-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-327-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-327-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-401",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-417",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-420-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-420-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-421-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-422",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-424",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-426",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-429-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-429-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-429-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-502",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-510",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-511-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-512",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-513",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-515",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G08",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Nelson Court",
  "Name": "Nels-G13",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "0"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-1117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-1210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-1216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-313",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-314",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-315",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-319",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-320",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-321",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-415",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-417",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-418",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-419",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-710",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-719",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-821",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-822",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newland House",
  "Name": "Newl-912",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newtown House",
  "Name": "Newt-301",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Newtown House",
  "Name": "Newt-406",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-12.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-13.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-14.f",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-15.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-15.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-16.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-17.f",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-18.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-18.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-18.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-19.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-19.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-19.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-20.f",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-21.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-21.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-22.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-22.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-22.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-23.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-24.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BA-24.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BB-13.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BB-57.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BC-01.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.a",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-03.f",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.b",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.d",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Opal Court",
  "Name": "Opal-BD-07.e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Oxford Court"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG71-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "#N/A"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG73-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "#N/A"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG74-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "#N/A"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG75-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "#N/A"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 10-PG75-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "#N/A"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 1-PG06-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 1-PG06-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 2-H01-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 2-H01-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 2-H01-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 3-H17-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 3-PG20-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 3-PG22-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 4-H43-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 4-H43-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-H51-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-H51-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-PG33-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-PG33-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 5-PG38-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-H68-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-H72-72",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG41-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG46-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG46-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 6-PG46-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG47-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG50-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG51-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 7-PG54-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG55-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG58-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG58-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG60-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 8-PG62-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG63-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG63-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG69-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Level 9-PG69-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Mezzanine-PG00-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Mezzanine-PG00-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Paris Gardens",
  "Name": "ParG-Mezzanine-PG00-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze 3 Bed Apt Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B05E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B06E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-B10A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-C11E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-C12A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-C13E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-C14A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D07G",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D09A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D11A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D12G",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D16F",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D16G",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D17A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D17B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D17C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D17D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D17E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D17G",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D18G",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D21F",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-D22E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E13E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E13F",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E13G",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E14B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E14C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E14E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15F",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E15G",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E18E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-E19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F01A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F01B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F01C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F01D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F02D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F02E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F03A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F03B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F03C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F04A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F04B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F04C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F04D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F05A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F05B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Parker House",
  "Name": "Park-F05C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-08-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-10-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-10-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-10-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-15-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-18-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA1-35-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA2-01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA2-32-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Copper En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA2-51",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-02-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-02-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-06-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-15-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-20-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-20-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BA3-45-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BB-03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BB-14-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BB-38-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-02-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-05-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-05-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-05-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-06-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-10-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-10-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-10-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-11-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-13-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-15-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC1-18-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC2-02-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC2-11-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC3-02-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BC3-09-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-02-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-04-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-07-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-31-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-31-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD1-34-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-01-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-01-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-01-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-04-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-04-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-08-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-09-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-09-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-10-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-21-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-21-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-23-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-29-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-31-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-57-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-59-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold Studio Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Pavilions",
  "Name": "Pavi-BD2-62-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "1 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-02-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-02-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-08-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-08-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Copper Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-10-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-16-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-18-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-18-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-18-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-18-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-19-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-19-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-21-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-21-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-22-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-24-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-27-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-27-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-27-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-27-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-30-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-32-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-32-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-33-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-34-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-43-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-43-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-43-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-43-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-45-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-45-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-46-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-46-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-46-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-46-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-46-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-48-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-48-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-50-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-50-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-50-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-50-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-51-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-51-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-53-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-53-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-54-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-56-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-56-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-56-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-57-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-57-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-57-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-59-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-59-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-60-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-60-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-60-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-60-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-61",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Copper Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-62-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-62-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-62-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-62-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-63-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-63-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-63-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-63-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-65-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-65-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-65-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-66-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-66-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-66-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-67",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Copper Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-68-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-68-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-68-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-68-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-70-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-70-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-70-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-70-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-70-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-71-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-71-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-71-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-71-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-71-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-73-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-73-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-73-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-75-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-75-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-75-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-75-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-75-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-76-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-76-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-76-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-76-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-78-1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-78-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Penworks House",
  "Name": "Penw-78-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-001",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-005.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Bronze 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-201.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-201.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-202.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-202.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Gold 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L03.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L03.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L05.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L05.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-C-L07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-002.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-002.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-002.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-102.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-302.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-302.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-L01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-I-L02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-011",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-016",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-302.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-302.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-L01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-S-L04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-001.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-301.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-301.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-302.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-302.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-305.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-305.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Silver 2 Bed Apartment",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-L01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Podium",
  "Name": "Podm-W-L04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-13",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Accessible Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-14",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Accessible Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-44",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-45",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-8",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 1-9",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-16",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-17",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-18",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-19",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-20",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-22",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-23",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-24",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 2-25",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-26",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-27",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-28",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-29",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-30",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-31",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-32",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-33",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-36",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-37",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-38",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Floor 3-39",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-1A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-1B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-1C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-40",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-41",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-42",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Sawmills",
  "Name": "Sawm-Ground-43",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Light Touch"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0125",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0128",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0148",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0150",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0152",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0153",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0219",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0225",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0228",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0248",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0249",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0250",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0253",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0301",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0325",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0328",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0348",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0349",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0350",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0353",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0421",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0425",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0448",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0450",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0453",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0501",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0518",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0546",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0548",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0549",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0550",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0553",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0601",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0602",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0604",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0610",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0618",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0628",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0634",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0635",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0648",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0650",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0652",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0680A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0701",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0710",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0712",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0718",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0728",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0748",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0749",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0750",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0752",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0753",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0791",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0801",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0802",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0803",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0804",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0805",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0806",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0807",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0808",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0809",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0810",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0813",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0815",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0817",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0819",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0825",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0835",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0846",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0847",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0850",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0852",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0890",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0901",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0903",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0905",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0906",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0907",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0908",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0909",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0910",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0911",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0913",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0915",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0917",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0918",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0919",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0921",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0922",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0923",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-0925",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1001",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1011",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1017",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1019",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1021",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1022",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1023",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1025",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1034",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1035",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1046",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1047",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1048",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1049",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1103",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1121",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1125",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1126",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1128",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1136",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1137",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1138",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1139",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1203",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1206",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1214",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1221",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1224",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1226",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1231",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1234",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1235",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1236",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1238",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1301",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1303",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1304",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1307",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1308",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1309",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1311",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1313",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1314",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1319",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1321",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1323",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1325",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1328",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1333",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1335",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1336",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1337",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1338",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1340",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2020",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1404",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1407",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1408",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1416",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1502",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1506",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2019",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1510",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1511",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Shoreditch",
  "Name": "Shor-1513",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Penthouse Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2018",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C002A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C002B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C002C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C002D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C002E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C003A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C003B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C003D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C003E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C004A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C004B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C004C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C004D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C004E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C004F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C009A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C009B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C009C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C009D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C009E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C103A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C103B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C103C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C103D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C103E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C105",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C108F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C109F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C111",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C113F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C114A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C114B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C114C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C114D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C114E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C115A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C115B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C115C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C201F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C202B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C202D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C202E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C203A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C203B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C203C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C203D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C203E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C204E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C205",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C208B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C208E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C210",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C213A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C213B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C213E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C215A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C215B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C215C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C215D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C215E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C215F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C216B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C216F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C301A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C301B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C301C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C301D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C301E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C301F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C302A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C302B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C302C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C302D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C302E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C302F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C303A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C303C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C303D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C303E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C304A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C304B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C304C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C304D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C304E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C304F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C305",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C307",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C308A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C308B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C308C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C308F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C309B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C309C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C309D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C309E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C309F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C310",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C311",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C313A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C313C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C313D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C313E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C313F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C314A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C314B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C314C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C314D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C314E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C315F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C316A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C316B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C316D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C316E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C316F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C401A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C401B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C401C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C401D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C401E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C401F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C402A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C402B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C402C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C402D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C402E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C403A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C403B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C403C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C403D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C404A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C404B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C404D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C404E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C404F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C405",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C407",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C408A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C408B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C409C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C409D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C409F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C410",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C413A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C413B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C413C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C413D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C413E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C413F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C414B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C414C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C414D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C415A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C415B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C415C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C415D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C415E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C416A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C416B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C416C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C416D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C416E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C416F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C501A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C501B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C501C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C501D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C501E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C501F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C503A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C503B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C503E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C503F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C503G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C504",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C505",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C512",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C513",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C514",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C519",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C520",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C521",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C522B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C522C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C522D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C522E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C522F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C522G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C523",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C524A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C524B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C524D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C524E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-C524F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M001",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M002",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M011",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M012",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M015",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M104A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M104B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M104D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M104F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M105A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M105B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M105C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M105D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M110",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M112",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M115",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M119",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M120",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M122",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M123",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M124",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M125",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M127",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M205A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M205B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M205C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M205D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M207",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M212",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M213",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M215",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M216",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M218",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M219",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M223",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M224",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M227",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M228B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M228E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M228G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M301",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M304A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M304B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M304C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M304D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M304E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M304F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M305D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M309",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M310",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M313",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M317",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M320A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M320C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M320D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M320E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M320F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M320G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M401",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M404A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M404B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M404C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M404D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M404E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M404F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M404G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M407",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M412",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M415",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M423",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M501",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M506",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Signal Place",
  "Name": "Sign-M511",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-08E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-16E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-17C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-17D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-18B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-18C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-18D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-18E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-21A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-21B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-21C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-21D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-22E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-23A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-23E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-27B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-27D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-30",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-35A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-35B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-35C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-35D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-35E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-38",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-43A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-43B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-43C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-43D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-43E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-47",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-50A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-50C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-50D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-50E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-53",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-56D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-56E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Steel",
  "Name": "Steel-58",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-007-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-017-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-018-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-018-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-018-3",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-018-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-019-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-019-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-019-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-020-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-020-3",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-020-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-023-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-024-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-024-3",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-024-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-024-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-025-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-026-3",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-026-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-026-6",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-028-6",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-030-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-032-3",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-032-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-032-6",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-035-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-036-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-037-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-037-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-037-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-038-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-038-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-038-3",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-038-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-038-6",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-039-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-039-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-039-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-039-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-039-6",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-040-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-040-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-040-3",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-040-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-041-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-041-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-043-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-043-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-043-6",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-044-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-049-4",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-049-5",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-050-6",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-052-1",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-052-2",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Stephenson House",
  "Name": "Step-057-4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E312C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E613F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E816A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E816B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E816D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E816E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E816F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-E817F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N504E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N603E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N604B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N604C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N604D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N702A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N703A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N703C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N704A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N708C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N708E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N708F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N803A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N803C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N803D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-N808D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S419F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S421",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S422F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S523A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S523C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S523D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-S824B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W319",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W322",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W424",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W427",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W625",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W825",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W829",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W830",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W831",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W832",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W833",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W955",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W957",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W958",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W959",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W960",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W961",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W962",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W963",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W964",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W965",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W966",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W967",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Sterling Court",
  "Name": "Ster-W968",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Defensive"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Blake-07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Blake-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Blake-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Blake-07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum Mini Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Bronte-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Browning-04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Browning-04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Browning-04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Browning-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Browning-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Browning-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Byron-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Byron-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Byron-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Byron-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Byron-07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Byron-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Carroll-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2021",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Chaucer-07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Drinkwater-04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Drinkwater-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Drinkwater-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Drinkwater-07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-01F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Elliot-07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum Mini Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-04F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Kipling-07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Lear-07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Shelley-07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-04F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Tennyson-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-04F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Thomas-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Student Quarter",
  "Name": "Wordsworth-07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-005",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-006",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-007-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-007-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-016",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-037",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-039",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-040",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-041",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-042",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-045",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-048",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-074",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-076",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-077",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-078",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-079",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-081",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-084",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-109",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-113",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-115",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-117",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-146",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-147",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-A-148",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-001",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-017-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-017-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-018",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-021",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-024",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-025",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-026",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-027",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-029",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-049-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-049-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-050",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-051",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-052",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-055",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-056",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-061",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-062",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-063",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-065",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-085-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-085-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-087",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-091",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-096",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-097",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-099",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-101",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-121-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-121-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-122",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-127",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-129",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-132",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-133",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-139",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-143",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Studios 51",
  "Name": "St51-B-144",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-12-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-1-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 1-3-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-16-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-20-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 2-22-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-31-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-35-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-35-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-35-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-36-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-38-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-39-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 3-39-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-40-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-41-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-43-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-44-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-44-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-44-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-45-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-45-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-45-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-46-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-49-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-49-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-51-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 4-52-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-53-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-54-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-55-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-56-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-57-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-58-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-60-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-61-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-61-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 5-61-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-63-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-63-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-63-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-64-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-65-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-66-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-66-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-66-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-67-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-67-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-68-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-69-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Arcade",
  "Name": "Arca-Floor 6-70-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "1 Room Flat",
  "Room Type": "Platinum Room",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard +"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-14",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 1-7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-17",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-73",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-74A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 2-74D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-25",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-31A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-31D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-31E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-32",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-34",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-79C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-79F",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-80",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-81B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-81E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-84D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-84E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Floor 3-85A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-1E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-35D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-36F",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-38E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-39A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-39B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-39C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-40D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-41E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-43",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-44E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-46A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-46B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-46C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-47E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-49E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Brickworks",
  "Name": "Bric-Ground-4E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "1 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "The Costume Store"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-001",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-002.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-002.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-003",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-004",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-005.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-005.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-005.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-005.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-005.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-008",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-009",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-010",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-011",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-012",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-013",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-014",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-015.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-015.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-016",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-017.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-017.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-017.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-017.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-019",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-023",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-025",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-026.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-026.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-028",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-029",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-032",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-033",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-034.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-035",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-036",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-037",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-038",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-039",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-040",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-041",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-043.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-043.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-043.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-043.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-043.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-043.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-043.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-044.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-044.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-052",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-055.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-056",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-057",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-058",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-059",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-060",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-062",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-065",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-066",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-067",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-068",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-069",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-070",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-071",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-073.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-073.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-073.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-073.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-086",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-087",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-091",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-092",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-093.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-099",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-103.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-121",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-128",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-133",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-140",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-141",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-143",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-144",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-145",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-146",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-147",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-148",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-149",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-150",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-151",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-154",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-155",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-156",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-158",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-159",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-160",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-161",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-164",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-165",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-166",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-167",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-169",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-171",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-174",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-175",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-178",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-179",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-180",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-181",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-182",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-183",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-184",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Mont",
  "Name": "Mont-185",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "30/08/25"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-A24",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-A25-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-A25-F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B21",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B22",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B23-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B23-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B24-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B24-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-B24-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-BG1-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-BG2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-BL1-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-BL1-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-BL1-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-BL1-D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-C12-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-CG1-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-CG1-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-CG1-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-CL1-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-CL1-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-CL1-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-CL1-E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D1-12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D1-23",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D2-02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D2-04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D2-06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D2-09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D2-10",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-D2-11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-03",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-05",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-06",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-09",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-11",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-14",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-18",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-21",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-22",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-23",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-24",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DG-25",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DL-01",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DL-04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DL-07",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DL-12",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-DL-15",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-EL-02",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "The Pad",
  "Name": "PadE-EL-04",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A004A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A016F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A024A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A024B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A024C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A024D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A024E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A024F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A030D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A032E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A032F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A038B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A038D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A039A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A039B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A039D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A039E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A040A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A040B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A040D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A040E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A042A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A046E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A047A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A047B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A047D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A047E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A051A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A051C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A051E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A055B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A055C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A055D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A055E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A055F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A056A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A056B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A056C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A056D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A056F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A058A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A058B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A058C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A058D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A058E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A058F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A062E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A062F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A063A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A063B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A063C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A063E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A063F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A064A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A064B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A064C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A064D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A064E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A067C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A067D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A067E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A068A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A068B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A068D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A068E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-A068F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-B107",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C002B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C002D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C002E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C004A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C004B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C004C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C004E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C004F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C005B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-C005E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-D003A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-D003B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-E002A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-E002B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-E002C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-E002E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-E004C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-F001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-F001B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-F002A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-F002B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2023",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G001B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G003B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G003C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G004A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G004B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G004C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G004D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G004E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G004F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G005B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G005C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G006A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G006B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G006C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G006E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G006F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G007B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G007C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-G009D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-Gatehouse-A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gatehouse Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-Gatehouse-B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gatehouse Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-Gatehouse-C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gatehouse Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-H005A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-H009B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I001A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I001B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I001C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I001E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I003B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I003C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I003D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I003E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I003F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I005A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I005B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I005C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I005D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I005E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I005F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I007A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I007C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I007D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I007F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I009A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I009B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I009C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I009D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I009E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I009F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I011B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I011C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Tufnell House",
  "Name": "Tufn-I013C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A101-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A101-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A101-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A101-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A101-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A101-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A102-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A102-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A102-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A102-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A102-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-A102-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B101-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B101-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B101-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B101-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B101-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B102-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B102-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B102-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B102-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B102-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B103-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B103-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B103-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B103-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B103-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B104",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B105",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B106-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B106-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B106-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B106-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B106-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B107-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B107-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B107-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B107-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B107-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B108",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B109",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B110",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B111",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B112",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B113",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B114",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B115",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B116",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-B117",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C101",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C102",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C103-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C103-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C103-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C103-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C103-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C104-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C104-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C104-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C104-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C104-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C104-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-C104-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH10-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH10-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH10-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH1-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH1-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH1-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH2-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH2-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH2-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH3-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH3-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH3-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH4-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH4-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH4-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH5-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH5-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH5-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH6-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH6-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH6-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH7-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH7-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH7-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH7-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH8-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH8-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH8-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH9-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH9-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 1-TH9-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A201-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A201-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A201-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A201-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A201-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A201-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A202-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A202-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A202-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A202-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A202-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A202-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-A203",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B201-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B201-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B201-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B201-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B201-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B202-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B202-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B202-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B202-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B202-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B203-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B203-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B203-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B203-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B203-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B204",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B205",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B206-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B206-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B206-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B206-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B206-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B207-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B207-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B207-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B207-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B207-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B208",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B209",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B210",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B211",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B212",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B213",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B214",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B215",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B216",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-B217",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C201",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C202",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C203-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C203-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C203-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C203-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C203-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C204-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C204-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C204-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C204-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C204-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C204-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-C204-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH10-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH10-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH10-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH1-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH1-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH1-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH2-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH2-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH2-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH3-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH3-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH3-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH4-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH4-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH4-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH5-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH5-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH5-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH6-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH6-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 2-TH6-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "Townhouse Room",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A301-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A301-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A301-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A301-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A301-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A301-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A302-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A302-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A302-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A302-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A302-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A302-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-A303",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B301-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B301-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B301-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B301-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B301-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B302-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B302-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B302-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B302-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B302-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B303-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B303-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B303-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B303-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B303-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B304",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B305",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B306-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B306-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B306-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B306-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B306-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B307-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B307-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B307-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B307-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B307-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B308",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B309",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B310",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B311",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B312",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B313",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B314",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B315",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B316",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-B317",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C301",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C302",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C303-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C303-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C303-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C303-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C303-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C304-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C304-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C304-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C304-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C304-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C304-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 3-C304-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B401-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B401-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B401-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B401-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B401-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B402-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B402-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B402-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B402-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B402-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B403-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B403-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B403-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B403-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B403-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B404",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B405",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B406",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B407-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B407-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B407-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B407-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B407-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B408-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B408-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B408-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B408-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-B408-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C401",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C402",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C403-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C403-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C403-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C403-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C403-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "5 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C404-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C404-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C404-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C404-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C404-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C404-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Floor 4-C404-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "7 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B001-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B001-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B001-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B001-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B001-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B002-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B002-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B002-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B002-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B002-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B003",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B004",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B005",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B006-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B006-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B006-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B006-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B006-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B007-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B007-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B007-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B007-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-B007-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C001-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C001-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C001-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C001-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C002",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "Studio",
  "Room Type": "Studio",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C003-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C003-B",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C003-C",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C003-D",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C003-E",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C003-F",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-C003-G",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH10-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH1-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH2-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH3-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH4-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH5-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH6-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH7-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH8-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Twerton Mill",
  "Name": "Twer-Ground-TH9-A",
  "Room Booking Type": "Nom Type 2",
  "Room Profile": "1 Room Flat",
  "Room Type": "En Suite",
  "Start Date": "21/09/24",
  "End Date": "20/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Ty Willis House",
  "Name": "TyWi-A18e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2025",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Ty Willis House",
  "Name": "TyWi-E03e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Ty Willis House",
  "Name": "TyWi-F14c",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Ty Willis House",
  "Name": "TyWi-F30e",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-006.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-077",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-152",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-157",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-158",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-159",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-165",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-170",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-172",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-173",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-174",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-175",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-176",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-182.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-182.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-183",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-186",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-187",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-188",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-196",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-197",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-198",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-199",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-200",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-201",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-202",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-204",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-209",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-211",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-217",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-220.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-220.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-220.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-222",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-225",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-227",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-228",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-230",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-247.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-251",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-273.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-284.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-284.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-284.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-284.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-285.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-285.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-285.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-285.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-286",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-289",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-297.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-297.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-297.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-308.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-308.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-308.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-308.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-308.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-308.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-320.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-320.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-320.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-320.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-320.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-320.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-320.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-321.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-321.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-321.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-321.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-321.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-324",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-325",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-330",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-332.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-332.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-332.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-332.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-332.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-332.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-333.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-333.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-333.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-333.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-333.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-334",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-342",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-344.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-344.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-344.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-344.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-344.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-345.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-345.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-346",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-354",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-356.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-356.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-356.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-356.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-356.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-356.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-356.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-357.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-357.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-357.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-357.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-357.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-360",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-361",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-364",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-366",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-368.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-368.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-368.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-368.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-368.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-368.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-368.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-369.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-369.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-369.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-369.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-369.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-370",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-371",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-372",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-373",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-374",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-375",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-378",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-380.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-380.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-380.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-380.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-380.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-380.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-380.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-381.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-381.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-381.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-381.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-381.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-382",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-383",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-384",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-385",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-388",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-389",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-390",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-392.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-392.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-392.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-392.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-392.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-392.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-392.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-393.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-393.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-393.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-393.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-393.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-394",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-395",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-396",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-397",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-399",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-400",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-402",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-404.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-404.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-404.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-404.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-404.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-404.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-404.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-405.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-405.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-405.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-405.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-405.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-406",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-408",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-409",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-411",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-413",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-414",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-416.1",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-416.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-416.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-416.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-416.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-416.6",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-416.7",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Penthouse En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-417.2",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-417.3",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-417.4",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-417.5",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Penthouse En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-418",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-419",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-420",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-421",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-422",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-423",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-424",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Vega",
  "Name": "Vega-425",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Penthouse Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-007",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Bronze Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-020",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-030",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-031",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Gold Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2024",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-102",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-104",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-106",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-108",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-114",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-116",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-118",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-120",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-124",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-126",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-128",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-131",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-132",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-134",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-142",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-146",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-218",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-220",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-222",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-226",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-228",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-241",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Diamond Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-242",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-244",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-246",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-306",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-312",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-314",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-316",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-318",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-322",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-324",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-326",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-328",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-334",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-340",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-342",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-344",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Water Lane House",
  "Name": "Wlan-Bed-346",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Platinum Studio Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "0",
  "Next Refurb Type": "n/a"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-101A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-101B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-102A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-102B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-102C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-103A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-104B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-105B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-105D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-201A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-203A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-203B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-203C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-204A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-204B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-204C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-206A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-206B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-206C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-403C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-404A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-404B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-404C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-501A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-501B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-601A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-602A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-603A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-603B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-603C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-604A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-604C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-803B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B1-803C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-101D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-204A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-204B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-204C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-205D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-208",
  "Room Booking Type": "Direct Let",
  "Room Profile": "Studio",
  "Room Type": "Silver Studio",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-212B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-303C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-304C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-312A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-312B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-403C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-404C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-405D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-406C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-412A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-412B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-503C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-504A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-504B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-504C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-505A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-505B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-505D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-506C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-603A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-603B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-603C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-604A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-604B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-604C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-705A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-705C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-705D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-803A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-803B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-803C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-812A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-901B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "2 Bed Apartment Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-906A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "2 Room Flat",
  "Room Type": "Diamond En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B02A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-B2-B05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1004E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-101A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-101B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-101C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-101D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-101E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-102A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-102B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-102C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-102D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1104F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Premium",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1301F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1302F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1404F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Deluxe",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1502F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1503F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1601F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1604A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1702F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1704C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1704D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1704E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1802E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1902D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1902E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-1902F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-2002C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-2002D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-202B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-202C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-202F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-301A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-301B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-301C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-301D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-301E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-301F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-303A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-303B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-303D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-303E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-303F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-304A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-403C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-501B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-603C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-703E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Copper En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-801A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-801B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-801C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-801D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-801E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-801F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-802E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-803F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weaver Place",
  "Name": "Weav-C-804A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2031",
  "Next Refurb Type": "Paint & Patch"
 },
 {
  "Asset": "Weston Hall"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A05H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A08H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A11H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A13E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-A15H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B01F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "12 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "12 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B02K",
  "Room Booking Type": "Direct Let",
  "Room Profile": "12 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B05H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B07G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B08H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B09G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B10G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B11H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B12G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B13G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B14H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B15G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-B19H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C01G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C03E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C04G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C05H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C06G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C07G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C08H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C09G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C11H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C12G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C13G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C14H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C15G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C17F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C17G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C17H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C19B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C19E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C19G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-C20F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "6 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D02G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D04G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D05E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D06H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D07G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D08G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D09H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D10G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D11E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D13G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "7 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15F",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15G",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Will Wyatt Court",
  "Name": "Will-D15H",
  "Room Booking Type": "Direct Let",
  "Room Profile": "8 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "07/09/24",
  "End Date": "06/09/25",
  "Previous Refurb Year": "2022",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B04B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B05E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B09A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Bronze En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B15E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B18E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B19E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B20B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B21B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B21C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B21D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B22E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B23A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B23C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B24C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B24D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B25D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B26C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B26D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B26E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B27A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B27D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B27E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-B28A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C01C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C01D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C02A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C02B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C02D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C03E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C04D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C09E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C11D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C13E",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C14D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C15D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C16D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C18D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C19D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C20D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C21D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C21E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C22D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C23D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-C24D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2030",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-D04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E10B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E15D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E17D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E18A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E18B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E18C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E19D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E20D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E21D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E22A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E23D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-E24D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2026",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F03D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F04D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F22C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F22D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-F27A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Gold En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-G14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Diamond En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2027",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H07D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H10E",
  "Room Booking Type": "Direct Let",
  "Room Profile": "5 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-H11D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I01D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I02D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I03A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I03B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I03C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I04A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I04B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I04C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I05D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I06D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I07A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I07B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I07C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I08D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I09D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I10D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I11A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I11B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I11C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I12D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I13D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I14D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I15A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I16D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I17D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I19A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I19B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I19C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "3 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20A",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20B",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite Plus",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20C",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-I20D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "07/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2028",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J05A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J05B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J05C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J05D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J06A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J06B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J06C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J06D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J07A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J07B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J07C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J08A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J08B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J08C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J08D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J09A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J09B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J09C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J09D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J10A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J10B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J10C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J10D",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "4 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J11A",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite Plus",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J11B",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J11C",
  "Room Booking Type": "Nom Type 3",
  "Room Profile": "3 Room Flat",
  "Room Type": "Silver En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 {
  "Asset": "Wilmslow Park",
  "Name": "Wilm-J20D",
  "Room Booking Type": "Direct Let",
  "Room Profile": "4 Room Flat",
  "Room Type": "Platinum En Suite",
  "Start Date": "14/09/24",
  "End Date": "13/09/25",
  "Previous Refurb Year": "0",
  "Next Refurb": "2029",
  "Next Refurb Type": "Standard"
 },
 null,
 {
  "Asset": "Applied filters:\nIs Current is 1\nLeasing Cycle is 2024-25\nIncluded (1) Available (Room Status)\nOcc Cycle Week Number is less than or equal to 54\nIs Current is 1\nAsset is not (Blank) or Not Known\nDebtPortfolio is not Not Assigned\nAgent (Merger Groups) is University Living or University Living Referral\nCurrent Status is not (Blank), Can, NA, or \nLeasing Cycle is not (Blank)\nRoom Booking Type is not (Blank)"
 }
]
};

export default HotelData;