import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/Admin/admin-dashboard/adminDashboard";
import Overview from "./components/Admin/overview/Overview";
import LoginPage from "./components/Home/pages/LoginPage";
import FilterEventsPage from './components/Student/Events/FilterEventsPage'
import { gsap } from "gsap";
import CollegeUpload from "./components/Admin/universities/CollgeUpload";
import UserDashboard from "./components/Student/userdashboard/UserDashboard";
import EventsPage from "./components/Student/Events/EventsPage";
import University from "./components/Student/Universities/University";
import StudentProfile from "./components/Student/userProfile/StudentProfile";
import UniversityDetails from "./components/Student/Universities/UniversityDetails";
import Explore from "./components/Student/Explore/Explore";
import AddJobPost from "./components/Admin/jobs/AddJobPost";
import PrivateRoute from "./auth/PrivateRoute/PrivateRoute";
import PageNotFound from "./components/Home/pages/PageNotFound";
import NewHome from "./components/Home/pages/NewHome"
import Join from "./components/Home/pages/Join";
import SmeForm from './components/Home/pages/SmeForm';
import Jobs from "./components/Student/Jobs/Jobs";
import AboutUsPage from "./components/Home/pages/AboutUsPage";
import IQAgentMiddleware from "./components/Student/Accomodation/Accomodation";
// import AuthComponent from "./components/Testing";
import DownloadExcel from "./components/Admin/FetchData";
import AddRestaurant from "./components/Admin/restaurants/AddRestaurant";
import Restaurant from "./components/Student/Explore/RestaurantTabs";
import SwipeCarousel from "./components/Student/Explore/SwipeCarousel";
import Mentorship from "./components/Home/pages/Mentorship";
import UploadEventForm from "./components/Admin/events/UploadEvents";
import EventPage from "./components/Student/Events/EventPage";
import Success from "./components/CheckOutForm/Success";
import Failure from "./components/CheckOutForm/Failure";
import Maps from "./components/Student/Maps/Maps";
import MyBookings from "./components/Student/Bookings/MyBookings";
import Ambassador from "./components/Home/pages/Ambassador";
import GoogleMap from "./components/Student/Maps/GoogleMaps";
import CheckoutDetails from "./components/Admin/events/Bookings";
import MapComponent from "./components/Student/Maps/GoogleMaps";
import Home from "./components/Home/pages/Home";
import QRScanner from "./components/Admin/events/TicketVerifiction";
import VerificationLogs from "./components/Admin/events/VerificationLogs";
import MapWithGeocoding from "./components/Student/Maps/GeoCode";
import CityRestaurants from "./components/Student/Explore/CityRestaurants";
import Layout from "./components/Home/layout/layout";
import TicektPreview from "./components/Student/Bookings/TicketPreview";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import RefundRequests from "./components/Admin/events/RefundRequests";
import BusinessHome from "./components/Home/pages/BusinessHome";
import BusinessLoginPage from "./components/Home/pages/BusinessLoginPage";
import OrgPage from "./components/Admin/events/BusinessEventCompanyPage";
import EditPageSettings from "./components/Admin/events/EditPageSetting";
import BusinessEventCompanyPage from "./components/Admin/events/BusinessEventCompanyPage";
import PublicEventPage from "./components/Home/pages/PublicEventPage";

gsap.registerPlugin();
function App() {
  return (
    <Router>

      <div className="App">
        <ToastContainer />

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/ambassador" element={<Ambassador />} />
            <Route path="/" element={<NewHome />} />
            <Route path="/home" element={<Home />} />

            <Route path="/geo" element={<MapWithGeocoding />} />
            {/* <Route path="/form" element={<QuizApp />} /> */}
            {/* <Route path="/details" element={<DetailsPage />} /> */}
            <Route path="/login" element={<LoginPage />} />
            {/* <Route path="/testing" element={<AuthComponent />} /> */}
            {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}

            <Route path="/blank" element={<PageNotFound />} />
            <Route path="/join-waiting-list" element={<Join />} />
            <Route path="/mentorship" element={<Mentorship />} />
            <Route path="/write-to-us" element={<SmeForm />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/maps" element={<Maps />} />
            <Route path="/google" element={<MapComponent />} />

            <Route path="/events" element={<EventsPage />} />
            <Route path="/events/p/:companyPage" element={<PublicEventPage />} />

            <Route path="/events/eventDetails/:eventId" element={<EventPage />} />
            <Route
              path="/events/all-events/:eventCategory"
              role="student"
              element={<FilterEventsPage />}
            />
            <Route
              path="/events/all-events"
              role="student"
              element={<FilterEventsPage />}
            />
          </Route>

          <Route path="/business" element={<BusinessHome />} />
          <Route path="/business/signup" element={<BusinessLoginPage />} />

          <Route exact path="/" element={<PrivateRoute role="admin" />}>
            <Route path="/business" role="admin" element={<Dashboard />}>
              <Route
                path="/business/events/overview"
                role="admin"
                element={<Overview />}
              />
              <Route
                path="/business/events"
                role="admin"
                element={<BusinessEventCompanyPage />}
              />
              <Route
                path="/business/events/new"
                role="admin"
                element={<UploadEventForm />}
              />
                  <Route
                path="/business/events/edit/:eventId"
                role="admin"
                element={<UploadEventForm />}
              />
              <Route
                path="/business/events/company-profile"
                role="admin"
                element={<EditPageSettings />}
              />
              <Route
                path="/business/events/verify"
                role="admin"
                element={<QRScanner />}
              />
              <Route
                path="/business/events/verificaton-logs"
                role="admin"
                element={<VerificationLogs />}
              />
              <Route
                path="/business/events/orders"
                role="admin"
                element={<CheckoutDetails />}
              />
              <Route
                path="/business/events/requests"
                role="admin"
                element={<RefundRequests />}
              />
              <Route
                path="/business/add-college"
                role="admin"
                element={<CollegeUpload />}
              />
              <Route
                path="/business/add-job-post"
                role="admin"
                element={<AddJobPost />}
              />
              <Route
                path="/business/restaurants"
                role="admin"
                element={<AddRestaurant />}
              /></Route>
          </Route>

          <Route path="/" element={<PrivateRoute role="student" />}>
            <Route
              path="/user-dashboard"
              role="student"
              element={<UserDashboard />}
            >
              <Route
                path="/user-dashboard/events"
                role="student"
                element={<EventsPage />}
              />

              <Route
                path="/user-dashboard/success?session_id={CHECKOUT_SESSION_ID}"
                role="student"
                element={<Success />}
              />
              <Route
                path="/user-dashboard/success"
                role="student"
                element={<Success />}
              />
              <Route
                path="/user-dashboard/failure"
                role="student"
                element={<Failure />}
              />
              <Route
                path="/user-dashboard/fetchData"
                role="student"
                element={<DownloadExcel />}
              />
              <Route
                path="/user-dashboard/swipe"
                role="student"
                element={<SwipeCarousel />}
              />
              <Route
                path="/user-dashboard/bookings/tickets/:ticketId"
                role="student"
                element={<TicektPreview />}
              />


              <Route
                path="/user-dashboard/events/all-events/:eventCategory"
                role="student"
                element={<FilterEventsPage />}
              />
              <Route
                path="/user-dashboard/events/all-events"
                role="student"
                element={<FilterEventsPage />}
              />
              {/* <Route
                path="/user-dashboard/events/all-events/:eventCategory/:eventId"
                role="student"
                element={<EventDetails />}
              /> */}

              <Route
                path="/user-dashboard/eventDetails/:eventId"
                element={<EventPage />}
              />
              <Route
                path="/user-dashboard/stay"
                role="student"
                element={<IQAgentMiddleware />}
              />
              <Route
                path="/user-dashboard/university"
                role="student"
                element={<University />}
              />
              <Route
                path="/user-dashboard/jobs"
                role="student"
                element={<Jobs />}
              />
              <Route
                path="/user-dashboard/university/:universityId"
                role="student"
                element={<UniversityDetails />}
              />
              <Route
                path="/user-dashboard/explore"
                role="student"
                element={<Explore />}
              />

              {/* <Route
                path="/user-dashboard/explore/:restaurantId"
                role="student"
                element={<Restaurant />}
              /> */}
              <Route
                path="/user-dashboard/explore/:city"
                role="student"
                element={<CityRestaurants />}
              />
              <Route
                path="/user-dashboard/:city/:restaurantId"
                role="student"
                element={<Restaurant />}
              />
              <Route
                path="/user-dashboard/bookings"
                role="student"
                element={<MyBookings />}
              />
              <Route
                path="/user-dashboard/settings/:userId"
                role="student"
                element={<StudentProfile />}
              />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
