import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Camera } from 'lucide-react';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { firestore, storage } from '../../../firebase';
import { collection, addDoc, serverTimestamp, doc, updateDoc, getDoc, query, where, getDocs } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const EditPageSettings = () => {
  const navigate = useNavigate();
  const { userData } = useAuth();
  const [formData, setFormData] = useState({
    profileImage: '', // Placeholder refactored
    coverImage: '',   // Placeholder refactored
    twitterHandle: '',
    name: '',
    pageUrl: '',
    pageCategory: '- Event Organiser',
    pageSubcategory: 'Club Night',
    location: '', // New field
    brandBio: '', // New field
    brandDescription: '' // New field
  });
  const [uploading, setUploading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [companyPageId, setCompanyPageId] = useState(null);

  useEffect(() => {
    const checkExistingCompanyPage = async () => {
      if (userData?.userId) {
        const q = query(collection(firestore, "eventCompanyPage"), where("userId", "==", userData.userId));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setFormData(doc.data());
          setIsEditMode(true);
          setCompanyPageId(doc.id);
        }
      }
    };

    checkExistingCompanyPage();
  }, [userData]);

  const handleImageChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setUploading(true);
    try {
      let profileImageUrl = formData.profileImage;
      let coverImageUrl = formData.coverImage;

      if (typeof formData.profileImage === 'string' && formData.profileImage.startsWith('data:')) {
        const profileImageRef = ref(storage, `eventCompanyPage/${formData.name}/profileImage`);
        const profileImageSnapshot = await uploadBytesResumable(profileImageRef, dataURLtoBlob(formData.profileImage));
        profileImageUrl = await getDownloadURL(profileImageSnapshot.ref);
      }

      if (typeof formData.coverImage === 'string' && formData.coverImage.startsWith('data:')) {
        const coverImageRef = ref(storage, `eventCompanyPage/${formData.name}/coverImage`);
        const coverImageSnapshot = await uploadBytesResumable(coverImageRef, dataURLtoBlob(formData.coverImage));
        coverImageUrl = await getDownloadURL(coverImageSnapshot.ref);
      }

      const dataToSave = {
        ...formData,
        profileImage: profileImageUrl,
        coverImage: coverImageUrl,
        userId: userData?.userId,
        updatedAt: serverTimestamp(),
      };

      if (isEditMode) {
        await updateDoc(doc(firestore, "eventCompanyPage", companyPageId), dataToSave);
      } else {
        dataToSave.createdAt = serverTimestamp();
        const docRef = await addDoc(collection(firestore, "eventCompanyPage"), dataToSave);
        setCompanyPageId(docRef.id);

        const userDocRef = doc(firestore, "users", userData?.userId);
        await updateDoc(userDocRef, {
          companyPage: docRef.id,
        });
      }

      alert(isEditMode ? "Page settings updated successfully" : "Page settings saved successfully");
      navigate("/business/events/new");
    } catch (error) {
      console.error("Error saving page settings:", error);
    } finally {
      setUploading(false);
    }
  };

  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const bytes = atob(data);
    const buffer = new ArrayBuffer(bytes.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < bytes.length; i++) {
      view[i] = bytes.charCodeAt(i);
    }
    return new Blob([buffer], { type: mime });
  };

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 flex">
      <div className="flex-grow p-4">
        <div className="max-w-4xl mx-auto">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h1 className="text-2xl font-semibold">{formData.name}</h1>
              <p className="text-gray-400">0 Followers &nbsp; | &nbsp; 0 Reps</p>
            </div>
            <div>
              <button className="border border-gray-600 text-gray-400 px-4 py-2 rounded ml-2 hover:bg-gray-700" onClick={() => navigate('/business/events/new')}>Add Event</button>
              {/* <button className="border border-gray-600 text-gray-400 px-4 py-2 rounded ml-2 hover:bg-gray-700">Edit Page</button>
              <button className="border border-gray-600 text-gray-400 px-4 py-2 rounded ml-2 hover:bg-gray-700">View Page</button> */}
            </div>
          </div>

          <div className="bg-gray-800 mt-6 p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold mb-4">{isEditMode ? 'Edit Page Settings' : 'Create Page Settings'}</h2>

            {/* Name */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full shadow p-2 rounded bg-gray-700 text-gray-200"
              />
            </div>

            {/* Location */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Location</label>
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                className="w-full shadow p-2 rounded bg-gray-700 text-gray-200"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Brand Bio</label>
              <span className='text-gray-300' >A short overview of your brand. This will appear in your brand page header.</span>
              <textarea
                name="brandBio"
                value={formData.brandBio}
                onChange={handleInputChange}
                className="w-full shadow p-2 rounded bg-gray-700 text-gray-200"
                rows={5} // Larger text area
              />
            </div>

            {/* Brand Description */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Brand Description</label>
              <span className='text-gray-300'>You can add an optional, detailed, description of your brand here. This will be shown at the bottom of your brand page.</span>

              <textarea
                name="brandDescription"
                value={formData.brandDescription}
                onChange={handleInputChange}
                className="w-full shadow p-2 rounded bg-gray-700 text-gray-200"
                rows={7} // Larger text area
              />
            </div>

            {/* Twitter Handle */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Twitter Handle</label>
              <div className="flex">
                <span className="inline-flex items-center px-3 text-gray-300 bg-gray-700 rounded-l-md shadow">@</span>
                <input
                  type="text"
                  name="twitterHandle"
                  value={formData.twitterHandle}
                  onChange={handleInputChange}
                  className="flex-1 rounded-r-md shadow p-2 bg-gray-700 text-gray-200"
                />
              </div>
            </div>

            {/* Page URL */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Page URL</label>
              <div className="flex">
                <span className="inline-flex items-center px-3 text-gray-300 bg-gray-700 rounded-l-md shadow">
                  worldlynk.co.uk/business/events/p/
                </span>
                <input
                  type="text"
                  name="pageUrl"
                  value={formData.pageUrl}
                  onChange={handleInputChange}
                  className="flex-1 rounded-r-md shadow p-2 bg-gray-700 text-gray-200"
                />
              </div>
            </div>
            {/* Profile Image Upload */}
            <div className="mb-6">
              <label className="block text-gray-400 mb-2">
                Profile Image (recommended: 312px x 312px)
              </label>
              <div className="relative w-32 h-32 bg-gray-600 rounded-full overflow-hidden shadow">
                {formData.profileImage ? (
                  <img
                    src={formData.profileImage}
                    alt="Profile"
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <svg
                    className="w-full h-full"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 128 128"
                  >
                    <circle cx="64" cy="64" r="64" fill="#4B5563" />
                    <text
                      x="50%"
                      y="50%"
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fontSize="20"
                      fill="white"
                    >
                      Logo
                    </text>
                  </svg>
                )}
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity">
                  <Camera className="text-white" size={24} />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e) => handleImageChange(e, 'profileImage')}
                />
              </div>
            </div>

            {/* Cover Image Upload */}
            <div className="mb-6">
              <label className="block text-gray-400 mb-2">
                Cover Image (recommended: 1600px x 900px)
              </label>
              <label
                htmlFor="dropzone-file4"
                className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                {/* Show the uploaded image if available */}
                {formData.coverImage ? (
                  <img
                    src={formData.coverImage}
                    alt="Uploaded Cover"
                    className="w-full h-48 object-cover rounded-lg"
                  />
                ) : (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      ></path>
                    </svg>
                    <p className="mb-2 text-lg text-gray-500 dark:text-gray-400">Display Cover Image</p>
                    <p className="mb-2 text-lg text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-lg text-gray-500 dark:text-gray-400">
                      SVG, PNG, JPG or GIF (MAX. 1600x900px)
                    </p>
                  </div>
                )}
                <input
                  id="dropzone-file4"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleImageChange(e, 'coverImage')}
                />
              </label>
            </div>
            {/* Page Category */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Page Category</label>
              <select
                name="pageCategory"
                value={formData.pageCategory}
                onChange={handleInputChange}
                className="w-full shadow p-2 rounded bg-gray-700 text-gray-200"
              >
                <option>- Event Organiser</option>
                <option>- Venue</option>
                <option>- Festival</option>
                <option>- Brands, Performers and Public Figures</option>
              </select>
            </div>

            {/* Page Subcategory */}
            <div className="mb-4">
              <label className="block text-gray-400 mb-2">Page Subcategory</label>
              <select
                name="pageSubcategory"
                value={formData.pageSubcategory}
                onChange={handleInputChange}
                className="w-full shadow p-2 rounded bg-gray-700 text-gray-200"
              >
                <option>Award Show</option>
                <option>Class</option>

                <option>Conference</option>
                <option>Club Night</option>
                <option>Culture</option>
                <option>Club Night</option>
                <option>Concert Hall</option>

                <option>Course</option>
                <option>Expo</option>
                <option>Live Music</option>
                <option>Meeting</option>
                <option>Musical</option>
                <option>Networking</option>
                <option>Press Conference</option>
                <option>Race</option>
                <option>Screening</option>
                <option>Seminar</option>
                <option>Stage Show</option>
                <option>Student Night</option>
                <option>Tour</option>
                <option>Trade Show</option>
                <option>others</option>
              </select>
            </div>

            <div className="mt-6">
              <button
                onClick={handleSubmit}
                className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-500 shadow-lg"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPageSettings;
