import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BusinessNav from "../sub-components/BusinessNav";
import { auth, firestore } from "../../../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Timestamp } from "firebase/firestore";
import googleSvg from "../../../assets/img/googleSvg.svg";
import linkedin from "../../../assets/img/linkedin.svg";

const BusinessLoginPage = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    companyName: "",
    organizationType: "",
  });
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const organizationTypes = ["Events", "Restaurants", "Accommodation", "Jobs"];

  const toggleToSignup = () => {
    setIsLogin(false);
    setCurrentStep(1);
    setIsForgotPassword(false);
  };

  const toggleToLogin = () => {
    setIsLogin(true);
    setIsForgotPassword(false);
    setFormData({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      companyName: "",
      organizationType: "",
    });
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      await setDoc(doc(firestore, "users", user.uid), {
        userId: user.uid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        companyName: formData.companyName,
        organizationType: formData.organizationType,
        accountType: "business",
        createdAt: Timestamp.now(),
        role:"admin",
      });

      toast.success("Signup successful! Please log in.", { position: "top-center", autoClose: 3000 });
      toggleToLogin();
    } catch (error) {
      console.error("Signup Error:", error);
      toast.error("Signup failed. Please try again.", { position: "top-center", autoClose: 5000 });
    }
  };

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;

      const userDoc = await getDoc(doc(firestore, "users", user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData.accountType === "business") {
          switch (userData.organizationType) {
            case "Events":
              navigate("/business/events/overview");
              break;
            case "Accommodation":
              navigate("/business/accommodation");
              break;
            case "Restaurants":
              navigate("/business/restaurants");
              break;
            case "Jobs":
              navigate("/business/jobs");
              break;
            default:
              toast.error("Unknown organization type. Please contact support.", { position: "top-center", autoClose: 5000 });
          }
        } else {
          throw new Error("Unauthorized access: Account is not a business account.");
        }
      } else {
        throw new Error("User data not found.");
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Login failed. Please check your email and password.", { position: "top-center", autoClose: 5000 });
    }
  };

  const handleForgotPassword = async () => {
    if (!formData.email) {
      toast.error("Please enter your email address.", { position: "top-center", autoClose: 5000 });
      return;
    }
    try {
      await sendPasswordResetEmail(auth, formData.email);
      toast.success("Password reset email sent. Please check your inbox.", { position: "top-center", autoClose: 5000 });
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Forgot Password Error:", error);
      toast.error("Failed to send password reset email. Please try again.", { position: "top-center", autoClose: 5000 });
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <BusinessNav />
      <div className="flex-grow flex items-center justify-center bg-gray-900 p-4 sm:p-8">
        <div className="relative bg-white w-full max-w-md sm:max-w-xl lg:max-w-4xl overflow-hidden shadow-lg rounded-lg">
          <div className="flex flex-col sm:flex-row">
            {/* Form Section */}
            <div className="w-full sm:w-1/2 p-6 sm:p-10">
              {/* Login Form */}
              <div className={`${isLogin && !isForgotPassword ? 'block' : 'hidden'}`}>
                <h1 className="text-lg font-bold mb-4">Log In</h1>
                <p className="text-sm mb-6">Login to your account to access wordlynk's business dashboard.</p>
                <div className="space-y-4">
                  <input 
                    type="email" 
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter Your Email Address" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                  <input 
                    type="password" 
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Enter Your Password" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                  <div className="flex justify-between items-center">
                    <button onClick={toggleForgotPassword} className="text-sm text-blue-600">Forgot Password?</button>
                    <button
                      onClick={handleLogin}
                      className="bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="mt-6 text-center">
                  <p className="text-sm text-gray-600">Don't have an account?</p>
                  <button onClick={toggleToSignup} className="mt-2 text-blue-600 hover:underline">Sign up</button>
                </div>
              </div>

              {/* Forgot Password Form */}
              <div className={`${isForgotPassword ? 'block' : 'hidden'}`}>
                <h1 className="text-lg font-bold mb-4">Forgot Password</h1>
                <p className="text-sm mb-6">Enter your email address to reset your password.</p>
                <div className="space-y-4">
                  <input 
                    type="email" 
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter Your Email Address" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                  />
                  <div className="flex justify-between items-center">
                    <button onClick={toggleToLogin} className="text-sm text-blue-600">Back to Login</button>
                    <button
                      onClick={handleForgotPassword}
                      className="bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </div>

              {/* Signup Form */}
              <div className={`${!isLogin ? 'block' : 'hidden'}`}>
                <h1 className="text-lg font-bold mb-4">Sign Up</h1>
                <p className="text-sm mb-6">Create your account to access wordlynk's business dashboard.</p>
                {currentStep === 1 && (
                  <div className="space-y-4">
                    <input 
                      type="text" 
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      placeholder="First Name" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    />
                    <input 
                      type="text" 
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      placeholder="Last Name" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    />
                    <input 
                      type="email" 
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter Your Email Address" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    />
                    <input 
                      type="password" 
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Enter Your Password" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    />
                     <button
                        onClick={handleNextStep}
                        className="bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                      >
                        Next Step
                      </button>
                    <div className="flex flex-col items-center mt-4">
                      <p className="text-sm text-gray-600 mb-2">Already have an account?</p>
                      <button onClick={toggleToLogin} className="mt-2 text-blue-600 hover:underline">
                        Login
                      </button>
                    </div>
                  </div>
                )}
                {currentStep === 2 && (
                  <div className="space-y-4">
                    <input 
                      type="text" 
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleInputChange}
                      placeholder="Company Name" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" 
                    />
                    <select
                      name="organizationType"
                      value={formData.organizationType}
                      onChange={handleInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select Organization Type</option>
                      {organizationTypes.map((type) => (
                        <option key={type} value={type}>{type}</option>
                      ))}
                    </select>
                    <div className="flex justify-between items-center">
                      <button onClick={handlePreviousStep} className="text-blue-600 hover:underline">
                        Previous Step
                      </button>
                      <button
                        onClick={handleSignUp}
                        className="bg-blue-800 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Image Section - Only visible on larger screens */}
            <div className="hidden sm:block w-1/2">
              <img
                src="https://img.freepik.com/free-vector/abstract-flat-design-background_23-2148450082.jpg?size=626&ext=jpg&ga=GA1.1.1286474015.1708934801&semt=sph"
                alt="banner"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessLoginPage;
