import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, addDoc, collection, updateDoc, getFirestore } from 'firebase/firestore';
import { FaQuestionCircle, FaTimes, FaDownload } from 'react-icons/fa';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { ClipLoader } from 'react-spinners';
import { firestore } from '../../../firebase';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const request_url = process.env.REACT_APP_REFUND_REQUEST_ENPOINT;

const TicketPreview = () => {
  const { ticketId } = useParams();
  const { currentUser } = useAuth();
  const [ticketData, setTicketData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [customReason, setCustomReason] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [coverImage, setCoverImage] = useState(null);
  const navigate = useNavigate();
  const ticketRef = useRef(null);
  console.log(ticketId);

  useEffect(() => {
    const fetchTicketData = async () => {
      const docRef = doc(firestore, 'payments', ticketId);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTicketData(data);
          console.log('Ticket data:', data);  // Console log for ticket data
          
          // Fetch cover image
          const eventDocRef = doc(firestore, 'events', data.user.eventId);
          const eventDocSnap = await getDoc(eventDocRef);
          if (eventDocSnap.exists()) {
            const eventData = eventDocSnap.data();
            console.log('Event data:', eventData);  // Console log for event data
            setCoverImage(eventData.eventImage);
            console.log('Cover image URL:', eventData.eventImage);  // Console log for cover image URL
          } else {
            console.log('No event document found');  // Console log if event document doesn't exist
          }
        } else {
          setError('No such document!');
          console.log('No ticket document found');  // Console log if ticket document doesn't exist
        }
      } catch (err) {
        setError('Error getting document:', err);
        console.error('Error fetching data:', err);  // Console log for any errors
      }
      setLoading(false);
    };

    fetchTicketData();
  }, [ticketId]);

  const handleHelp = () => {
    navigate('/help');
  };

  const handleCancel = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitCancellation = async () => {
    console.log('handleSubmitCancellation function called');
    
    if (!ticketData) {
      console.error('No ticket data available to submit');
      toast.error('Failed to submit cancellation. No ticket data available.');
      return;
    }
  
    const finalCancellationReason = cancellationReason === 'Other' ? customReason : cancellationReason;
    const db = getFirestore(); // Get Firestore instance
  
    try {
      console.log('Adding refund request to Firestore');
      const refundRef = await addDoc(collection(db, 'refundRequests'), {
        orderId: ticketId,
        userId: ticketData.user.uid,
        reason: finalCancellationReason,
        status: 'pending',
        requestedAt: new Date(),
      });
  
      console.log('Refund request added with ID:', refundRef.id);
  
      // Update the document to include the requestId
      await updateDoc(doc(db, 'refundRequests', refundRef.id), {
        requestId: refundRef.id
      });
  
      console.log('Refund request updated with requestId:', refundRef.id);
  
      toast.success('Form submitted successfully!', {
        style: {
          background: '#333',
          color: '#fff',
        },
      });
      setShowModal(false);
    } catch (error) {
      console.error('Error adding refund request:', error);
      toast.error('Form submission failed. Please try again.', {
        style: {
          minWidth: '360px',
        },
      });
    }
  };

  const handleDownload = async () => {
    const input = ticketRef.current;
    const canvas = await html2canvas(input, { 
      scale: 2,
      useCORS: true,
      allowTaint: true,
      logging: true,
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min((pdfWidth - 40) / imgWidth, (pdfHeight - 40) / imgHeight);
    const imgX = (pdfWidth - imgWidth * ratio) / 2;
    const imgY = (pdfHeight - imgHeight * ratio) / 2;
    
    pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    pdf.save("ticket.pdf");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ClipLoader color="#ffffff" loading={loading} size={50} />
      </div>
    );
  }
  
  if (error) return <div>Error: {error}</div>;
  if (!ticketData) return <div>No ticket data available.</div>;

  return (
    <div className="min-h-screen bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div id="ticket-preview" ref={ticketRef} className="max-w-3xl mx-auto bg-gray-800 shadow-2xl rounded-lg overflow-hidden">
        {/* Header */}
        <div className="relative h-64">
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{
              backgroundImage: `url(${coverImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              filter: 'blur(2px)',
              boxShadow: 'inset 0 0 100px rgba(0,0,0,0.8)',
            }}
          ></div>
          <div className="relative z-10 p-6 flex flex-col justify-center h-full bg-black bg-opacity-50">
            <h1 className="text-4xl font-extrabold text-white text-shadow-3d">{ticketData.user.eventTitle}</h1>
            <p className="text-2xl font-bold text-purple-200 text-shadow-3d mt-2">{ticketData.organizerName}</p>
          </div>
          <div className="absolute bottom-2 right-2 z-20">
            <p className="text-sm font-bold text-white bg-green-500 px-3 py-1 rounded-full">Confirmed</p>
          </div>
        </div>

        {/* Main Content */}
        <div className="p-6">
          {/* Event Details */}
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div>
              <p className="text-sm text-gray-400">Date</p>
              <p className="text-lg font-semibold text-white">{ticketData.user.eventDate}</p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Time</p>
              <p className="text-lg font-semibold text-white">{ticketData.user.eventTime}</p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Location</p>
              <p className="text-lg font-semibold text-white">{ticketData.user.eventLocation}</p>
            </div>
            <div>
              <p className="text-sm text-gray-400">Ticket ID</p>
              <p className="text-lg font-semibold text-white">{(ticketData.orderId || ticketId).slice(-8)}</p>
            </div>
          </div>

          {/* Ticket Details */}
          <div className="mb-6">
            <h2 className="text-xl font-bold text-purple-400 mb-2">Ticket Details</h2>
            {ticketData.tickets.map((ticket, index) => (
              <div key={index} className="flex justify-between text-gray-300">
                <span>{ticket.title} x{ticket.quantity}</span>
                <span>£{ticket.price}</span>
              </div>
            ))}
            <div className="flex justify-between font-bold text-lg mt-2 text-purple-300">
              <span>Total</span>
              <span>£{ticketData.amount_total}</span>
            </div>
          </div>

          {/* QR Code */}
          <div className="flex justify-center mb-6">
            <div className="text-center">
              <p className="text-sm text-gray-400 mb-2">Scan QR Code for Entry</p>
              <img src={ticketData.qrCodeUrl} alt="QR Code" className="mx-auto w-48 h-48" crossOrigin="anonymous" />
              <p className="mt-2 text-sm text-gray-300">{ticketData.user.name}</p>
              <p className="text-sm text-gray-400">{ticketData.user.email}</p>
            </div>
          </div>

          {/* Important Information */}
          <div className="bg-gray-700 p-4 rounded-lg">
            <h3 className="font-semibold text-lg mb-2 text-purple-300">Important Information</h3>
            <ul className="list-disc list-inside text-sm text-gray-300">
              <li>Please arrive 15 minutes before the event starts</li>
              <li>This ticket is non-refundable</li>
              <li>Enjoy the event!</li>
            </ul>
          </div>
        </div>

        {/* Footer */}
        <div className="bg-gray-800 px-6 py-4 flex justify-between items-center border-t border-gray-700">
          <div className="flex space-x-4">
            <button onClick={handleHelp} className="text-purple-400 hover:text-purple-300 flex items-center">
              <FaQuestionCircle className="mr-1" />
              <span>Help</span>
            </button>
            <button 
              onClick={() => {
                if (ticketData.user.refunds === 'disabled') {
                  toast.info('Cancellation is not available for this event.', {
                    style: { background: '#1F2937', color: '#fff' },
                  });
                } else {
                  handleCancel();
                }
              }} 
              className="text-red-400 hover:text-red-300 flex items-center"
            >
              <FaTimes className="mr-1" />
              <span>Cancel</span>
            </button>
          </div>
          <button onClick={handleDownload} className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded flex items-center">
            <FaDownload className="mr-2" />
            <span>Download PDF</span>
          </button>
        </div>
      </div>

      {/* Modal for Cancellation */}
      <Modal isOpen={showModal} onClose={handleCloseModal} onSubmit={handleSubmitCancellation}>
        <h3 className="text-xl font-semibold mb-4 text-white">Why are you canceling?</h3>
        <select
          className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded mb-4"
          onChange={(e) => setCancellationReason(e.target.value)}
        >
          <option value="">Select a reason...</option>
          <option value="Changed my mind">Changed my mind</option>
          <option value="Event timing issues">Event timing issues</option>
          <option value="Other">Other</option>
        </select>
        {cancellationReason === 'Other' && (
          <input
            type="text"
            placeholder="Please specify"
            className="w-full p-2 bg-gray-700 text-white border border-gray-600 rounded mb-4"
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
          />
        )}
      </Modal>
    </div>
  );
};

export default TicketPreview;

const Modal = ({ isOpen, onClose, onSubmit, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="bg-gray-900 bg-opacity-75 absolute inset-0" onClick={onClose}></div>
      <div className="relative z-10 bg-gray-600 p-4 rounded-lg shadow-lg max-w-lg w-full">
        {children}
        <div className="mt-4 flex justify-end space-x-2">
          <button onClick={onClose} className="bg-gray-200 hover:bg-gray-300 text-black px-4 py-2 rounded">
            Cancel
          </button>
          <button onClick={onSubmit} className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};
