import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, getDoc, deleteDoc, doc, query, where } from "firebase/firestore";
import { firestore, storage } from '../../../firebase'; // Adjust the path as necessary
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { format, parse } from 'date-fns';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './UploadEvents.css'

const Loader = () => (
  <div className="loader-container">
    <div className="loader"></div>
  </div>
);

const UploadEventForm = () => {
  const { currentUser, userData } = useAuth();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const location = useLocation();
  const [formData, setFormData] = useState({
    eventTitle: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    eventLocation: '',
    eventPrice: '',
    ageRestriction: '',
    tickets: [
      { title: '', status: '', price: '', bookingFee: '', quantity: '', publishStatus: 'publish_now', publishDate: '', publishTime: '' }
    ],
    organizer: '',
    eventDescription: '',
    details: ['', '', ''],
    notes: ['', '', ''],
    venueName: '',
    venueAddress: '',
    eventImage: null,
    eventImageUrl: null,
    eventImageName: '',
    category: '',
    refunds:'enabled',
    eventId: null
  });

  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showCompanyProfileDialog, setShowCompanyProfileDialog] = useState(false);
  const [showTicketDialog, setShowTicketDialog] = useState(false);
  const [newTicket, setNewTicket] = useState({ title: '', status: 'available', price: '', bookingFee: '', quantity: '', publishStatus: 'publish_now', publishDate: '', publishTime: '' });

  useEffect(() => {
    if (location.pathname.includes('/edit/') && eventId) {
      fetchEventData(eventId);
    }
    fetchEvents();
    if (!userData.companyPage) {
      setShowCompanyProfileDialog(true);
    }
  }, [eventId, location, userData]);

  const fetchEventData = async (id) => {
    try {
      const eventDoc = await getDoc(doc(firestore, 'events', id));
      if (eventDoc.exists()) {
        const eventData = eventDoc.data();
        setFormData({
          ...eventData,
          startDate: parseFormattedDate(eventData.startDate),
          endDate: parseFormattedDate(eventData.endDate),
          eventId: id
        });
      } else {
        console.log("No such event!");
      }
    } catch (error) {
      console.error("Error fetching event data:", error);
    }
  };

  useEffect(() => {
    setFilteredEvents(
      events.filter(event =>
        event.eventTitle.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, events]);

  const fetchEvents = async () => {
    try {
      const eventsQuery = query(
        collection(firestore, 'events'),
        where('organizerId', '==', currentUser.uid)
      );
      
      const querySnapshot = await getDocs(eventsQuery);
      const eventsData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setEvents(eventsData);
      setFilteredEvents(eventsData);
    } catch (error) {
      console.error('Error fetching events: ', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleTicketChange = (index, field, value) => {
    const newTickets = [...formData.tickets];
    if (field === 'price' || field === 'bookingFee') {
      newTickets[index][field] = parseFloat(value);
    } else if (field === 'publishStatus') {
      newTickets[index][field] = value;
      if (value === 'publish_now') {
        newTickets[index].publishDate = '';
        newTickets[index].publishTime = '';
      }
    } else {
      newTickets[index][field] = value;
    }
    setFormData(prevData => ({
      ...prevData,
      tickets: newTickets
    }));
  };

  const addTicket = () => {
    setShowTicketDialog(true);
  };

  const handleNewTicketChange = (field, value) => {
    setNewTicket(prev => ({
      ...prev,
      [field]: field === 'price' || field === 'bookingFee' ? parseFloat(value) : value
    }));
  };

  const handleAddNewTicket = () => {
    setFormData(prevData => ({
      ...prevData,
      tickets: [...prevData.tickets, newTicket]
    }));
    setNewTicket({ title: '', status: 'available', price: '', bookingFee: '', quantity: '', publishStatus: 'publish_now', publishDate: '', publishTime: '' });
    setShowTicketDialog(false);
  };

  const handleDetailsChange = (index, value) => {
    const newDetails = [...formData.details];
    newDetails[index] = value;
    setFormData(prevData => ({
      ...prevData,
      details: newDetails
    }));
  };

  const handleNotesChange = (index, value) => {
    const newNotes = [...formData.notes];
    newNotes[index] = value;
    setFormData(prevData => ({
      ...prevData,
      notes: newNotes
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setFormData(prevData => ({
        ...prevData,
        eventImage: file,
        eventImageUrl: fileUrl,
        eventImageName: file.name
      }));
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'EEE do MMM');
  };

  const parseFormattedDate = (dateString) => {
    const date = parse(dateString, 'EEE do MMM', new Date());
    return format(date, 'yyyy-MM-dd');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let imageUrl = formData.eventImageUrl;
      if (formData.eventImage) {
        const storageRef = ref(storage, `event-images/${formData.eventImage.name}`);
        await uploadBytes(storageRef, formData.eventImage);
        imageUrl = await getDownloadURL(storageRef);
      }
  
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);
  
      let companyPage = null;
      if (userDoc.exists()) {
        companyPage = userDoc.data().companyPage;
      } else {
        console.error("User document not found");
      }
  
      const eventDataToSave = {
        ...formData,
        startDate: formatDate(formData.startDate),
        endDate: formatDate(formData.endDate),
        eventImage: imageUrl,
        organizerId: currentUser.uid,
        companyPage: companyPage || '',
      };
  
      if (formData.eventId) {
        await updateDoc(doc(firestore, 'events', formData.eventId), eventDataToSave);
        alert('Event data updated successfully!');
      } else {
        const docRef = await addDoc(collection(firestore, 'events'), eventDataToSave);
        await updateDoc(doc(firestore, 'events', docRef.id), { eventId: docRef.id });
        alert('Event data uploaded successfully!');
      }
  
      setFormData({
        eventTitle: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        eventLocation: '',
        eventPrice: '',
        ageRestriction: '',
        tickets: [{ title: '', status: '', price: '', bookingFee: '', quantity: '', publishStatus: 'publish_now', publishDate: '', publishTime: '' }],
        organizer: '',
        eventDescription: '',
        details: ['', '', ''],
        notes: ['', '', ''],
        venueName: '',
        venueAddress: '',
        eventImage: null,
        eventImageUrl: null,
        eventImageName: '',
        category: '',
        eventId: null,
        refunds:'enabled'
      });
      setLoading(false);
      fetchEvents();
      navigate('/business/events');
    } catch (error) {
      console.error('Error uploading event data: ', error);
      alert('Failed to upload event data: ' + error.message);
      setLoading(false);
    }
  };

  const handleEditEvent = async (event) => {
    const parsedStartDate = parseFormattedDate(event.startDate);
    const parsedEndDate = parseFormattedDate(event.endDate);
    let imageUrl = event.eventImage;
    let imageName = event.eventImageName || '';

    if (event.eventImage) {
      const imageRef = ref(storage, event.eventImage);
      imageUrl = await getDownloadURL(imageRef);
      const imageNameArray = event.eventImage.split('/');
      imageName = imageNameArray[imageNameArray.length - 1];
    }

    setFormData({
      ...event,
      startDate: parsedStartDate,
      endDate: parsedEndDate,
      eventImageUrl: imageUrl,
      eventImageName: imageName,
      eventId: event.id
    });
  };

  const handleDeleteEvent = async () => {
    if (!formData.eventId) {
      alert('No event selected to delete');
      return;
    }

    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await deleteDoc(doc(firestore, 'events', formData.eventId));
        alert('Event deleted successfully!');
        setFormData({
          eventTitle: '',
          startDate: '',
          endDate: '',
          startTime: '',
          endTime: '',
          eventLocation: '',
          eventPrice: '',
          ageRestriction: '',
          tickets: [{ title: '', status: '', price: '', bookingFee: '', quantity: '', publishStatus: 'publish_now', publishDate: '', publishTime: '' }],
          organizer: '',
          eventDescription: '',
          details: ['', '', ''],
          notes: ['', '', ''],
          venueName: '',
          venueAddress: '',
          eventImage: null,
          eventImageUrl: null,
          eventImageName: '',
          category: '',
          eventId: null,
          refunds:'disabled'
        });
        fetchEvents();
      } catch (error) {
        console.error('Error deleting event: ', error);
        alert('Failed to delete event: ' + error.message);
      }
    }
  };

  return (
    <>
      {showCompanyProfileDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg text-white max-w-md">
            <h2 className="text-lg font-bold mb-3">Complete Company Profile</h2>
            <p className="text-gray-300 text-sm mb-4">Please complete your company profile before creating an event.</p>
            <button
              onClick={() => navigate('/business/events/company-profile')}
              className="w-full bg-teal-500 text-white py-2 px-4 rounded hover:bg-teal-600 transition text-sm"
            >
              Go to Profile
            </button>
          </div>
        </div>
      )}
      {showTicketDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg text-white max-w-sm">
            <h2 className="text-lg font-bold mb-3">Add New Ticket</h2>
            <div className="space-y-3">
              <input
                type="text"
                placeholder="Title"
                value={newTicket.title}
                onChange={(e) => handleNewTicketChange('title', e.target.value)}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
              <select
                value={newTicket.status}
                onChange={(e) => handleNewTicketChange('status', e.target.value)}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
              >
                <option value="available">Available</option>
                <option value="soldout">Sold Out</option>
              </select>
              <input
                type="number"
                step="0.01"
                placeholder="Price"
                value={newTicket.price}
                onChange={(e) => {
                  const price = parseFloat(e.target.value);
                  const bookingFee = price * 0.07;
                  handleNewTicketChange('price', price);
                  handleNewTicketChange('bookingFee', bookingFee.toFixed(2));
                }}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
              <input
                type="number"
                step="0.01"
                placeholder="Booking Fee"
                value={newTicket.bookingFee}
                readOnly
                className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
              <input
                type="number"
                placeholder="Quantity"
                value={newTicket.quantity}
                onChange={(e) => handleNewTicketChange('quantity', e.target.value)}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
              />
              <select
                value={newTicket.publishStatus}
                onChange={(e) => handleNewTicketChange('publishStatus', e.target.value)}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
              >
                <option value="publish_now">Publish Now</option>
                <option value="publish_later">Publish Later</option>
              </select>
              {newTicket.publishStatus === 'publish_later' && (
                <>
                  <input
                    type="date"
                    value={newTicket.publishDate}
                    onChange={(e) => handleNewTicketChange('publishDate', e.target.value)}
                    className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                  <input
                    type="time"
                    value={newTicket.publishTime}
                    onChange={(e) => handleNewTicketChange('publishTime', e.target.value)}
                    className="w-full px-3 py-2 rounded bg-gray-700 text-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                </>
              )}
            </div>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setShowTicketDialog(false)}
                className="bg-gray-500 text-white py-1 px-3 rounded text-sm hover:bg-gray-600 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleAddNewTicket}
                className="bg-teal-500 text-white py-1 px-3 rounded text-sm hover:bg-teal-600 transition"
              >
                Add Ticket
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="min-h-screen flex items-center justify-center px-4 py-4 bg-gray-900 text-gray-100">
        <div className="w-full max-w-2xl bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="mb-6 text-center">
            <h1 className="text-2xl font-bold">{formData.eventId ? 'Edit Event' : 'Upload Event'}</h1>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">Event Title</label>
              <input
                type="text"
                name="eventTitle"
                value={formData.eventTitle}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                  className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  required
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">End Date</label>
                <input
                  type="date"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleChange}
                  className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  required
                  min={formData.startDate || new Date().toISOString().split('T')[0]}
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">Start Time</label>
                <input
                  type="time"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleChange}
                  className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">End Time</label>
                <input
                  type="time"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  required
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Event Location</label>
              <input
                type="text"
                name="eventLocation"
                value={formData.eventLocation}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Event Price</label>
              <input
                type="text"
                name="eventPrice"
                value={formData.eventPrice}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Age Restriction</label>
              <input
                type="text"
                name="ageRestriction"
                value={formData.ageRestriction}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Category</label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              >
                <option value="" disabled>Choose a category...</option>
                <option value="Academic Events">Academic Events</option>
                <option value="Cultural Events">Cultural Events</option>
                <option value="Social Events">Social Events</option>
                <option value="Sports and Recreation">Sports and Recreation</option>
                <option value="Professional Development">Professional Development</option>
                <option value="Health and Wellness">Health and Wellness</option>
                <option value="Volunteer and Community Service">Volunteer and Community Service</option>
                <option value="Orientation and Information Sessions">Orientation and Information Sessions</option>
                <option value="Arts and Entertainment">Arts and Entertainment</option>
                <option value="Technology and Innovation">Technology and Innovation</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Tickets</label>
              {formData.tickets.map((ticket, index) => (
                <div key={index} className="grid grid-cols-1 sm:grid-cols-6 gap-2 mb-2">
                  <input
                    type="text"
                    placeholder="Title"
                    value={ticket.title}
                    onChange={(e) => handleTicketChange(index, 'title', e.target.value)}
                    className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                  <select
                    value={ticket.status}
                    onChange={(e) => handleTicketChange(index, 'status', e.target.value)}
                    className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  >
                    <option value="available">Available</option>
                    <option value="soldout">Sold Out</option>
                  </select>
                  <input
                    type="number"
                    step="0.01"
                    placeholder="Price"
                    value={ticket.price}
                    onChange={(e) => {
                      const price = parseFloat(e.target.value);
                      const bookingFee = price * 0.07; // Calculate 7% of the price
                      handleTicketChange(index, 'price', price);
                      handleTicketChange(index, 'bookingFee', bookingFee.toFixed(2));
                    }}
                    className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                  <input
                    type="number"
                    step="0.01"
                    placeholder="Fee"
                    value={ticket.bookingFee}
                    readOnly
                    className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                  <input
                    type="number"
                    placeholder="Quantity"
                    value={ticket.quantity}
                    onChange={(e) => handleTicketChange(index, 'quantity', e.target.value)}
                    className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  />
                  <select
                    value={ticket.publishStatus}
                    onChange={(e) => handleTicketChange(index, 'publishStatus', e.target.value)}
                    className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                  >
                    <option value="publish_now">Publish Now</option>
                    <option value="publish_later">Publish Later</option>
                  </select>
                  {ticket.publishStatus === 'publish_later' && (
                    <>
                      <input
                        type="date"
                        value={ticket.publishDate}
                        onChange={(e) => handleTicketChange(index, 'publishDate', e.target.value)}
                        className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                      />
                      <input
                        type="time"
                        value={ticket.publishTime}
                        onChange={(e) => handleTicketChange(index, 'publishTime', e.target.value)}
                        className="px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                      />
                    </>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={addTicket}
                className="bg-teal-500 text-white py-2 px-4 rounded mt-2 hover:bg-teal-600 transition"
              >
                Add Ticket
              </button>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Organizer</label>
              <input
                type="text"
                name="organizer"
                value={formData.organizer}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Event Description</label>
              <ReactQuill
                theme="snow"
                value={formData.eventDescription}
                onChange={(content) => setFormData((prevData) => ({
                  ...prevData,
                  eventDescription: content
                }))}
                className="bg-gray-800 text-white border-gray-600"
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Details</label>
              {formData.details.map((detail, index) => (
                <input
                  key={index}
                  type="text"
                  value={detail}
                  onChange={(e) => handleDetailsChange(index, e.target.value)}
                  className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500 mb-2"
                />
              ))}
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Notes</label>
              {formData.notes.map((note, index) => (
                <textarea
                  key={index}
                  value={note}
                  onChange={(e) => handleNotesChange(index, e.target.value)}
                  className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500 mb-2"
                  rows="2"
                ></textarea>
              ))}
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Refunds</label>
              <select
                name="refunds"
                value={formData.refunds}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              >
                <option value="enabled">Enable</option>
                <option value="disabled">Disable</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Venue Name</label>
              <input
                type="text"
                name="venueName"
                value={formData.venueName}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Venue Address</label>
              <input
                type="text"
                name="venueAddress"
                value={formData.venueAddress}
                onChange={handleChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">Event Image</label>
              <input
                type="file"
                onChange={handleFileChange}
                className="w-full px-3 py-2 rounded bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-teal-500"
                accept="image/*"
              />
              {formData.eventImageName && (
                <p className="text-sm text-gray-400 mt-2">{formData.eventImageName}</p>
              )}
              {formData.eventImageUrl && (
                <div>
                  <img src={formData.eventImageUrl} alt="Event" className="w-full h-auto rounded-lg mt-4" />
                </div>
              )}
            </div>
            <div className="flex justify-between mt-4">
              <button
                type="submit"
                className="bg-teal-500 text-white py-2 px-4 rounded hover:bg-teal-600 transition"
              >
                {loading ? <Loader /> : "Upload Event"}
              </button>
              {formData.eventId && (
                <button
                  type="button"
                  onClick={handleDeleteEvent}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition"
                >
                  Delete Event
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
  
};

export default UploadEventForm;
