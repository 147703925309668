import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { collection, getDoc, getDocs } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import ExploreMain from './ExploreMain';

const sampleFoods = [
  {
    foodName: 'Pizza Margherita',
    foodPrice: 12.99,
    foodImage: 'https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=400',
    foodCategory: 'Italian'
  },
  {
    foodName: 'Sushi Platter',
    foodPrice: 24.99,
    foodImage: 'https://images.unsplash.com/photo-1546069901-ba9599a7e63c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=400',
    foodCategory: 'Japanese'
  },
  {
    foodName: 'Burger Deluxe',
    foodPrice: 9.99,
    foodImage: 'https://images.unsplash.com/photo-1550547660-d9450f859349?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=400',
    foodCategory: 'American'
  },
  {
    foodName: 'Caesar Salad',
    foodPrice: 7.49,
    foodImage: 'https://imgmedia.lbb.in/media/2019/08/5d662c8ea84656a7661be92a_1566977166741.jpg',
    foodCategory: 'Salad'
  },
  {
    foodName: 'Spaghetti Carbonara',
    foodPrice: 14.99,
    foodImage: 'https://images.unsplash.com/photo-1589302168068-964664d93dc0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&q=80&w=400',
    foodCategory: 'Italian'
  },
  {
    foodName: 'Chocolate Cake',
    foodPrice: 6.99,
    foodImage: 'https://tse4.mm.bing.net/th?id=OIP.zC35B2ne3dEGYXW4JKu9cgHaEK&pid=Api&P=0&h=220',
    foodCategory: 'Dessert'
  }
];


const Explore = () => {
  const [loading, setLoading] = useState(true);
  const [restaurants, setRestaurants] = useState(sampleFoods)
  const navigate = useNavigate()
  function handleClick(restaurantId) {
    navigate(`/user-dashboard/explore/${restaurantId}`)
  }


  const handleSearchChange = (e) => {
    let query = e.target.value
    if (query.length === 0) {
      setRestaurants(restaurants)
      return;
    }

    const filteredRestaurants = restaurants.filter((ele) =>
      ele.name.toLowerCase().includes(query.toLowerCase())
    );
    setRestaurants(filteredRestaurants)

  };

  const handleCategorySelectChange = (e) => {
    const selectedCategory = e.target.value;
    if (selectedCategory === 'All') {
      setRestaurants(restaurants);
    } else {
      const filteredRestaurants = sampleFoods.filter((restaurant) => restaurant.category === selectedCategory);
      setRestaurants(filteredRestaurants);
    }
  };

  useEffect(() => {
    const fetchRestaurants = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'restaurants'));
        const fetchedRestaurants = querySnapshot.docs.map((doc) => doc.data());
        setRestaurants(fetchedRestaurants);
        console.log(restaurants, "restaurants")
        setLoading(false);
      } catch (error) {
        console.error('Error fetching restaurants: ', error);
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, []);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
   
    <ExploreMain />

  );
};

export default Explore;
