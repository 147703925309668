import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import finalLogo from '../../../assets/img/log.png';
import '../styles/NewNav.css'; // New CSS file for navbar styles

const BusinessNav = () => {
    const [click, setClick] = useState(false);
    const [activeTab, setActiveTab] = useState('/');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Update activeTab based on the current path
        setActiveTab(location.pathname);
    }, [location.pathname]);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const handleTabClick = (path) => {
        navigate(path);
        closeMobileMenu();
    };

    return (
        <nav className="navbar bg-gray-950 px-2">
            <div className="navbar-container container mx-auto px-1 pb-4">
                <div className="navbar-logo pb-4 lg:-ml-20 " onClick={() => handleTabClick('/')}>
                    <img src={finalLogo} alt="Logo" className="h-9 md:h-12 " />
                </div>

                <div className="menu-icon" onClick={handleClick}>
                    {click ? <FaTimes size={28} /> : <FaBars size={28} />}
                </div>

                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    {/* <li className="nav-item">
                        <div
                            onClick={() => handleTabClick('/')}
                            className={`nav-link ${activeTab === '/' ? 'border-b-2 border-white' : ''}`}
                        >
                            Home
                        </div>
                    </li>
                    <li className="nav-item">
                        <div
                            onClick={() => handleTabClick('/mentorship')}
                            className={`nav-link ${activeTab === '/mentorship' ? 'border-b-2 border-white' : ''}`}
                        >
                            Mentorship
                        </div>
                    </li>
                    <li className="nav-item">
                        <div
                            onClick={() => handleTabClick('/ambassador')}
                            className={`nav-link ${activeTab === '/ambassador' ? 'border-b-2 border-white' : ''}`}
                        >
                            Ambassador
                        </div>
                    </li> */}
                    <li className="nav-item">
                        <div
                            onClick={() => handleTabClick('/business/signup')}
                            className={`nav-link ${activeTab === '/join-waiting-list' ? 'border-b-2 border-white' : ''}`}
                        >
                            Get Started
                        </div>
                    </li>
                    <li className="nav-item">
                        <div
                            onClick={() => handleTabClick('/business')}
                            className={`nav-link border border-white ${activeTab === '/business' ? 'border-2 border-white rounded-md' : 'border-2 border-white rounded-md'}`}
                        >
                            Business
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default BusinessNav;
