import React from 'react';

const PageNotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="text-center">
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
          <h1 className="text-9xl font-bold text-white">404</h1>
        </div>
        <div className="mt-8">
          <h3 className="text-2xl md:text-3xl font-semibold text-white mb-4">
            Looks like you're lost
          </h3>
          <p className="text-gray-400 mb-6">
            The page you are looking for is not available!
          </p>
          <a href="/" className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors">
            Go to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
