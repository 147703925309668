import React, { useState, useEffect, lazy, Suspense } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from '../../../firebase'; 
import { useAuth } from '../../../auth/userProvider/AuthProvider';
const Slider1 = lazy(() => import('../../Student/Events/Slider'));

const BusinessEventCompanyPage = () => {
  const { currentUser } = useAuth();
  const [events, setEvents] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventsData, companyData] = await Promise.all([
          fetchEvents(),
          fetchCompanyData()
        ]);
        setEvents(eventsData);
        setCompanyData(companyData);
      } catch (error) {
        console.error('Error fetching data: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser.uid]);

  const fetchEvents = async () => {
    const eventsQuery = query(
      collection(firestore, 'events'),
      where('organizerId', '==', currentUser.uid)
    );
    
    const querySnapshot = await getDocs(eventsQuery);
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  };

  const fetchCompanyData = async () => {
    const companyQuery = query(
      collection(firestore, 'eventCompanyPage'),
      where('userId', '==', currentUser.uid)
    );
    
    const querySnapshot = await getDocs(companyQuery);
    return !querySnapshot.empty ? querySnapshot.docs[0].data() : {};
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }
  const SkeletonPulse = ({ className }) => (
    <div className={`bg-gradient-to-r from-gray-700 via-gray-600 to-gray-700 ${className} animate-pulse-mirror`}></div>
  );
  return (
    <div className="bg-gray-900 min-h-screen text-white">
 <div className="relative h-96">
        {loading ? (
          <SkeletonPulse className="w-full h-full" />
        ) : companyData.coverImage ? (
          <img 
            src={companyData.coverImage} 
            alt="Cover" 
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-r from-blue-500 to-purple-600"></div>
        )}
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-end">
          <div className="container mx-auto px-4 py-6">
            <div className="flex items-center">
              <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-white mr-6">
                {loading ? (
                  <SkeletonPulse className="w-full h-full" />
                ) : companyData.profileImage ? (
                  <img 
                    src={companyData.profileImage} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full bg-gray-600"></div>
                )}
              </div>
              <div>
                <h1 className="text-4xl font-bold">{companyData.name || 'Organization Name'}</h1>
                <p className="text-xl mt-2">{companyData.brandBio || 'Company Tagline'}</p>
                                <p className="text-xl mt-2">{companyData.brandDescription || 'Company Description'}</p>

                <p className="text-xl mt-2 flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  {companyData.location || 'Company Location'}
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tabs */}
      <div className="container mx-auto mt-10">
        <div className="flex justify-center space-x-12 border-b border-gray-600 pb-3">
          <button className="text-white border-b-4 border-teal-400 px-6 py-2 text-lg font-semibold transition">All</button>
          <button className="text-gray-400 hover:text-white transition text-lg">Events</button>
          <button className="text-gray-400 hover:text-white transition text-lg">Posts</button>
        </div>

        {/* Current Events Section */}
        <div className="mt-12">
          <h3 className="text-3xl font-bold text-center mb-10">Current Events</h3>
          <Suspense fallback={
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          }>
            <Slider1 events={events} />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default BusinessEventCompanyPage;
