import React from 'react';
import Slider from 'react-slick';
import EventCard from './EventCard';
import EditableCard from '../../Admin/events/EditableCard';
import { useAuth } from '../../../auth/userProvider/AuthProvider';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Slider.css';

const Slider1 = ({ events }) => {
  const { userData } = useAuth();

  const settings = {
    dots: events.length > 1,
    infinite: events.length > 1,
    speed: 600,
    slidesToShow: Math.min(events.length, 3),
    slidesToScroll: 1,
    arrows: events.length > 1, // Hide arrows if only one event
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(events.length, 2),
          slidesToScroll: 1,
          infinite: events.length > 1,
          dots: events.length > 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: events.length > 1,
          dots: events.length > 1,
          arrows: events.length > 1,
        },
      },
    ],
  };

  const renderCard = (event) => {
    if (!userData) {
      return <EventCard eventData={event} />;
    }
    return userData.accountType === 'business' ? (
      <EditableCard eventData={event} />
    ) : (
      <EventCard eventData={event} />
    );
  };

  return (
    <div className="relative">
      {events.length === 1 ? (
        // Custom UI when only one event is present
        <div className="flex justify-center">
          <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 px-2">
            {renderCard(events[0])}
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          {events.map((event) => (
            <div key={event.id} className="px-2">
              {renderCard(event)}
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default Slider1;
